import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import CommonAlert from '../CommonAlert';
import CommonBorderBox from '../CommonBorderBox';
import { scrollToTop } from '../Helper';
import Loader from '../Loader';
import Pagination from '../Pagination';
import CarePlanForm from './CarePlanForm';
import CarePlanList from './CarePlanList';

function CarePlanCreation() {
  const [isEditMode, setEditMode] = useState(false);
  const initialValuesTouchPoint = {
    name: '',
    summary: '',
    owner: '',
    frequency: '',
    start_date_offset: '',
    care_plan_actions: [{ actiontitle: 'task' }],
  };

  const corePlanData = {
    title: '',
    duration: '',
    status: 'inactive',
    touch_points: [],
  };

  const [actionNestedData, setActionNestedData] = useState({ ...corePlanData });
  const [editCarePlanTemplate, setEditCarePlanTemplate] = useState({});
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [error, setError] = useState(corePlanData);
  const [editError, setEditError] = useState(corePlanData);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const ApiClient = useApiManager();
  useEffect(() => {
    handleGetCarePlanList();
  }, [currentPage]);
  let timeOut;
  useEffect(() => {
    timeOut = setTimeout(() => {
      if (searchInput?.trim()?.length > 2) {
        getCarePlanSearch();
      } else {
        handleGetCarePlanList();
      }
    }, 300);
  }, [searchInput]);

  const getCarePlanSearch = () => {
    ApiClient.getCareSearch(searchInput.trim())
      .then((res) => {
        setTotalPages(1);
        setTableData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetCarePlanList = async () => {
    let page = { page: currentPage };
    await ApiClient.getCarePlan(page)
      .then((res) => {
        setTableData(res.data);
        setTotalPages(res.headers['total-pages']);
        setEditMode(false);
      })
      .catch((err) => {
        console.warn('dsd', err);
        setIsLoading(false);
        scrollToTop();
        setAlertType('alert-danger');
        setIsShowAlert(err.response?.data?.detail);
      });
    setIsLoading(false);
  };

  const getCarePlanTemplate = (item) => {
    setIsLoading(true);
    ApiClient.getCarePlanTemplate(item.id)
      .then((res) => {
        setEditMode(true);
        setEditError('');
        setError({ ...corePlanData });
        let updateRegimeData = res.data;
        setEditCarePlanTemplate(updateRegimeData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        scrollToTop();
        setAlertType('alert-danger');
        setError(err.response.data.errors);
        setIsShowAlert(err.response.data.errors.details);
      });
  };

  const handleSaveAction = async () => {
    setIsLoading(true);
    await ApiClient.createCarePlan(actionNestedData)
      .then((res) => {
        handleGetCarePlanList();
        setError({ ...corePlanData });
        setCurrentPage(1);
        setActionNestedData({ ...corePlanData });
        setAlertType('alert-success');
        setIsShowAlert('Care Plan Created successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        scrollToTop();
        setAlertType('alert-danger');
        setError(err.response.data.errors);
        setIsShowAlert(err.response?.data?.detail);
      });
  };

  const handleEditAction = async () => {
    setIsLoading(true);
    await ApiClient.updateCarePlan(editCarePlanTemplate.id, editCarePlanTemplate)
      .then((res) => {
        handleGetCarePlanList();
        setEditError({ ...corePlanData });
        setActionNestedData({ ...corePlanData });
        setAlertType('alert-success');
        setIsShowAlert('Care Plan Updated successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setEditError(err.response.data.errors || err.response.data);
        setIsShowAlert(err.response?.data?.detail);
      });
  };

  const deleteData = async (id) => {
    setIsLoading(true);
    await ApiClient.deleteCarePlan(id)
      .then((res) => {
        handleGetCarePlanList();
        setAlertType('alert-success');
        setIsShowAlert('Care Plan Deleted successfully');
      })
      .catch((err) => {
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail);
        setError(err.response.data.errors);
      });
    setIsLoading(false);
  };

  return (
    <div className=''>
      <div className='mt-3'>
        {!isEditMode && <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />}
      </div>
      <CommonBorderBox className='settingFormOutline '>
        <div className='formTitle'>Add Workflow</div>
        <CarePlanForm
          error={error}
          actionNestedData={actionNestedData}
          setActionNestedData={setActionNestedData}
          initialValuesTouchPoint={initialValuesTouchPoint}
          handleSaveAction={handleSaveAction}
        />
      </CommonBorderBox>
      <div className='table-responsive'>
        <CarePlanList
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          deleteData={deleteData}
          tableData={tableData}
          updateDataModalOpen={getCarePlanTemplate}
        />
      </div>
      <Loader show={isLoading} />
      <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Modal
        backdrop='static'
        size='md'
        open={isEditMode}
        onClose={() => {
          setEditMode(false);
        }}
      >
        <Modal.Header />
        <CarePlanForm
          error={editError}
          setActionNestedData={setEditCarePlanTemplate}
          initialValuesTouchPoint={initialValuesTouchPoint}
          actionNestedData={editCarePlanTemplate}
          handleSaveAction={handleEditAction}
        />
      </Modal>
    </div>
  );
}

export default CarePlanCreation;
