import React, {useState} from 'react';
import CoreDropDown from '../CoreDropDown';
import CoreTextArea from '../CoreTextArea';
import CoreInputBox from '../CoreInputBox';
import CoreButton from '../CoreButton';
import { useRecordOrderPaymentMutation, useGetOperatorListQuery } from 'redux/dashboardApis';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; 

function RecordOrderPayment({ orderSummary, manualOrderId }) {
  const [formData, setFormData] = useState({
    mode: '',
    form: '',
    amount: orderSummary.total,
    paymentWith: '',
    notes: ''
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState("")
  const operatorListDetails = useGetOperatorListQuery();
  const [recordPayment, recordPaymentResponse] = useRecordOrderPaymentMutation();
  const paymentMode = [
    { title: 'Online', id: 'online' },
    { title: 'Offline', id: 'offline' },
  ];

  const paymentForm = [
    { title: 'UPI', id: 'upi' },
    { title: 'Card', id: 'card' },
    { title: 'Net Banking', id: 'net_banking' },
  ];

  const paymentFormOffline = [
    { title: 'Cash', id: 'cash' }
  ];

  const operatorList = operatorListDetails.data?.data || [];
  const paymentWith = operatorList.map((item) => ({
    title: item.full_name,
    id: item.id
  }));

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const validateFields = () => {
    let hasErrors = false;
    let newErrors = { ...errors };

    if (!formData.mode) {
      newErrors.mode = 'Mode is required';
      hasErrors = true;
    } else {
      newErrors.mode = '';
    }

    if (!formData.form) {
      newErrors.form = 'Form is required';
      hasErrors = true;
    } else {
      newErrors.form = '';
    }

    if (!formData.paymentWith) {
      newErrors.paymentWith = 'Payment with is required';
      hasErrors = true;
    } else {
      newErrors.paymentWith = '';
    }

    setErrors(newErrors);
    return !hasErrors;
  };

  const handleRecordPayment = async () => {
    if (!validateFields()) {
      return;
    }
    const operatorId = Number(formData.paymentWith)
    const data = {
      "amount": Number(orderSummary.total),
      "payment_mode": formData.mode,
      "payment_form": formData.form,
      "operator": operatorId,
      "order_id": manualOrderId,
      "notes": formData.notes
    }

    try {
      const response = await recordPayment({ data })
      if (response.error) {
        throw new Error(response.error);
      }
      // if(response.data.status === 200) {
      //   toast.success(`Payment recorded for Order: ${manualOrderId || response.data.data.order}`, {position: 'top-right' , autoClose: 1000, onClose: () => navigate('/orders') });
      // }
      if (response.data.status === 200) {
        navigate('/orders', { state: { paymentSuccess: true, recordedOrderId : manualOrderId || response.data.data.order } });
      }
    } catch(e) {
      console.error('Payment recording failed:', e);
      //toast.error('Payment recording failed. Please try again.', { position: 'top-right', autoClose: 2000 });
      navigate('/orders', { state: { paymentSuccess: false } });
    }
  }

  const paymentFields = () => {
    return (
      <>
        <div className='d-flex justify-content-center'>
          <div style={{ width: '50%' }}>
            <div className='bg-light p-3 rounded mb-4 text-center'>
              <div className='mb-2'>{`Order ID: ${manualOrderId}`}</div>
              <div className='fw-bold'>{`Amount: ₹ ${orderSummary.total}`}</div>
            </div>

            <div className='mb-4'>
              <h6 className='fw-bold mb-2' style={{ color: '#243B86' }}>
                Details
              </h6>
              <div className='mb-3'>
                <CoreDropDown
                  placeholder='Mode'
                  label='Mode'
                  retuired
                  data={paymentMode}
                  showMSG={errors.mode}
                  // showMSG={errorMsg?.status}
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  setValue={(value) => handleChange('mode', value)}
                  value={formData.mode}
                  msgStyle={{ color: 'red' }}
                />
              </div>
              <div className='mb-3'>
                <CoreDropDown
                  placeholder='Form'
                  label='Form'
                  retuired
                  //data={paymentForm}
                  data={formData.mode === 'online' ? paymentForm : (formData.mode === 'offline' ? paymentFormOffline : [])}
                  showMSG={errors.form}
                  // showMSG={errorMsg?.status}
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  setValue={(value) => handleChange('form', value)}
                  value={formData.form}
                  msgStyle={{ color: 'red' }}
                />
              </div>
              <div className='mb-3'>
                <CoreInputBox
                  validateNumbers
                  required
                  labelStyle={{ fontWeight: 'var(--lightWeight)' }}
                  placeholder='Enter Amount'
                  label='Amount'
                  value={orderSummary.total}
                  disabled={true}
                  msgStyle={{ color: 'red' }}
                />
              </div>
              {
                <div className='mb-3'>
                  <CoreDropDown
                    placeholder='Payment with'
                    label='Payment with'
                    retuired
                    data={paymentWith}
                    showMSG={errors.paymentWith}
                    // showMSG={errorMsg?.status}
                    labelStyle={{ fontWeight: 'var(--lightFont)' }}
                    setValue={(value) => handleChange('paymentWith', value)}
                    value={formData.paymentWith}
                    msgStyle={{ color: 'red' }}
                  />
                </div>
              }
              <div className='mb-3'>
                <CoreTextArea
                  // showMSG={(error?.diet_plans && error?.diet_plans && error?.diet_plans?.regime) || ''}

                  labelStyle={{ fontWeight: 'var(--lightWeight)', color: '#243B86', fontWeight: '600' }}
                  label={'Notes'}
                  placeholder={'Write notes here'}
                  rows={5}
                  setValue={(value) => handleChange('notes', value)}
                  value={formData.notes}
                />
              </div>
              <div className='text-center'>
                <CoreButton
                  onClick={handleRecordPayment}
                  title={'Record Payment'}
                />
                {/* <ToastContainer /> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return <div>{paymentFields()}</div>;
}

export default RecordOrderPayment;