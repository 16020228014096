import React, { useEffect, useState } from 'react';
import Select2DropDown from '../common/Select2DropDown';
import CommonBorderBox from '../CommonBorderBox';
import CoreButton from '../CoreButton';
import CoreCheckBox from '../CoreCheckBox';
import CoreInputBox from '../CoreInputBox';
import useApiManager from 'networking/ApiManager';
import CommonAlert from '../CommonAlert';
import CoreTextArea from '../CoreTextArea';
import { useParams } from 'react-router-dom';

function TaskTemplateForm(props) {
  const ApiClient = useApiManager();
  const {
    isEditMode,
    setEditMode,
    questionsList,
    getTaskTemplatesList,
    taskTemplate,
    setTaskTemplate,
    setQuestionsList,
    setIsUpdateAlert,
    setIsUpdateAlertType,
    setIsShowAlert,
    setAlertType,
    onClick,
    data,
    error,
    categoryListDrop,
  } = props;

  const [errors, setErrors] = useState({});

  const onsucccess = () => {
    getTaskTemplatesList();
    setTaskTemplate({
      template_name: '',
      title: '',
      content: '',
    });
    setErrors({});
  };

  const setAlert = (alertMsg, alertType) => {
    setAlertType(alertType);
    setIsShowAlert(alertMsg);
  };

  const createTaskTemplate = () => {
    const requestBody = {
      id: taskTemplate.id,
      template_name: taskTemplate.template_name,
      title: taskTemplate.title,
      content: taskTemplate.content,
    };
    setErrors({});
    ApiClient.createTaskTemplate(requestBody)
      .then((res) => {
        onsucccess();
        setAlert('Task Template Added Successfully', 'alert-success');
      })
      .catch((err) => {
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsShowAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        }
      });
  };

  const updateTaskTemplate = () => {
    const modalBody = document.querySelector('.rs-modal-body');
    if (modalBody) {
      modalBody.scrollTop = 0;
    }
    const requestBody = {
      id: taskTemplate.id,
      template_name: taskTemplate.template_name,
      title: taskTemplate.title,
      content: taskTemplate.content,
    };
    ApiClient.updateTaskTemplate(taskTemplate.id, requestBody)
      .then((res) => {
        onsucccess();
        setEditMode(false);
        setAlert('Task Template Updated Successfully', 'alert-success');
      })
      .catch((err) => {
        console.warn('err?.response', err?.response);
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsUpdateAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setIsUpdateAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        } else {
          setErrors({});
        }
      });
  };

  return (
    <div className='measuringForm'>
      <div className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100 '>
          <Select2DropDown
            className='capitalize'
            retuired
            data={categoryListDrop}
            placeholder={'Select Category'}
            value={taskTemplate?.template_name || ''}
            label={'Task Category'}
            onSelect={(data) => setTaskTemplate({ ...taskTemplate, template_name: data })}
            msgStyle={{ color: 'red' }}
            maxLength={255}
            labelKey='title'
            valueKey='title'
          />
          <div className='inputMsg'>{errors.template_name}</div>
        </div>
        <div className='w-100'>
          <CoreInputBox
            retuired
            showMSG={errors?.title}
            placeholder={'Task Name'}
            value={taskTemplate?.title || ''}
            label={'Task Title'}
            setValue={(data) => setTaskTemplate({ ...taskTemplate, title: data })}
            msgStyle={{ color: 'red' }}
            maxLength={255}
          />
        </div>
      </div>

      <div className='mt-3'>
        <CoreTextArea
          retuired
          showMSG={errors?.content || ''}
          labelStyle={{ fontWeight: 'var(--lightWeight)' }}
          label={'Task Content'}
          placeholder={'Add Task Content'}
          rows={5}
          value={taskTemplate?.content || ''}
          setValue={(data) => {
            setTaskTemplate((prevTaskTemplate) => ({
              ...prevTaskTemplate,
              content: data,
            }));
          }}
        />
      </div>

      <CoreButton
        onClick={isEditMode ? updateTaskTemplate : createTaskTemplate}
        title={isEditMode ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default TaskTemplateForm;
