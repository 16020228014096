import React, { useEffect, useState } from 'react';
import './OrderDetails.css';
import { location, noteSave } from 'res/images';
import Select2DropDown from '../common/Select2DropDown';
import SearchBox from '../common/SearchBox';
import CoreInputBox from '../CoreInputBox';
import CoreTextArea from '../CoreTextArea';
import { Modal } from 'rsuite';
import PatientPrescription from './PatientPrescription';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetPatientPrescriptionItemsMutation,
  useGetPrescriptionListQuery,
  useAddGenericNotesMutation,
} from 'redux/dashboardApis';
import { setManualOrderItems, setManualOrderSummary, setLatestPrescription, setReduxNotesData } from 'redux/Slices';
import { resetWarned } from 'react-bootstrap-typeahead/types/utils/warn';
import { profileImage } from '../Helper';
import useApiManager from 'networking/ApiManager';
import { useParams } from 'react-router-dom';

const OrderDetails = ({
  isNewMedicineAdded,
  setIsNewMedicineAdded,
  medicineErrors,
  publishedPrescription,
  orderSummaryShipping,
}) => {
  const dispatch = useDispatch();
  const ApiClient = useApiManager();
  const [medicines, setMedicines] = useState([]);
  const [medicinesList, setMedicinesList] = useState([]);
  const [orderDetail, setOrderDetail] = useState();
  const [manualOrderDetails, setManualOrderDetails] = useState();
  const [notes, setNotes] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [venderOptions, setVendorOptions] = useState([]);
  const [patientApiDetails, setPatientApiDetails] = useState({});
  const [getPrescriptionItems, prescriptionItemsData] = useGetPatientPrescriptionItemsMutation();
  const manualOrderItems = useSelector((state) => state?.sliceReducer?.manualOrderItems);
  const manualOrderSummary = useSelector((state) => state?.sliceReducer?.manualOrderSummary);
  const [orderSummary, setOrderSummary] = useState({
    orderTotal: 0,
    discount: 0,
    shipping: 0,
    total: 0,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const patientDetails = useSelector((state) => state?.sliceReducer?.patientManualDetails);
  const patientAddress = useSelector((state) => state?.sliceReducer?.patientAddress);
  const userData = useSelector((state) => state?.sliceReducer?.userData);
  const manualOrderId = useSelector((state) => state?.sliceReducer?.manualOrderId);
  const reduxNotesData = useSelector((state) => state?.sliceReducer?.reduxNotesData);
  const { orderId, patientId, orderStatus } = useParams();
  const [notesArr, setNotesArr] = useState([]);
  const [genericNotes, genericNotesResponse] = useAddGenericNotesMutation();
  const [isNotesLoading, setIsNotesLoading] = useState(false);

  useEffect(() => {
    if (patientDetails?.id) {
      getPatientPrescriptionItems(patientDetails?.id);
      getPatientDetails(patientDetails?.id);
      getSelectedOrderDetail(patientId, orderId);
    } else if (patientId) {
      getPatientPrescriptionItems(patientId);
      getPatientDetails(patientId);
      getSelectedOrderDetail(patientId, orderId);
      getManualOrderDetail(orderId);
    }
  }, []);

  useEffect(() => {
    if (orderId) {
      setIsNotesLoading(true);
      getManualOrderDetail(orderId);
      getAllNotes(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    if (patientDetails?.id) {
      getPatientPrescriptionItems(patientDetails?.id);
      getPatientDetails(patientDetails?.id);
    }
  }, [patientDetails.id]);

  const getManualOrderDetail = (oid) => {
    ApiClient.getManualOrder(oid)
      .then((res) => {
        setManualOrderDetails(res?.data);
      })
      .catch((err) => {
        console.log('getManualOrder err: ', err);
      });
  };

  const getSelectedOrderDetail = (id, oid) => {
    ApiClient.getSelectedOrderDetail(id, oid)
      .then((res) => {
        setOrderDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllNotes = (id) => {
    ApiClient.getOrderNotes(id)
      .then((res) => {
        setNotesArr(res.data.results);
        setIsNotesLoading(false);
      })
      .catch((err) => {
        console.log('getNotes error: ', err);
        setIsNotesLoading(false);
      });
  };

  const getPatientDetails = (id) => {
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setPatientApiDetails(res.data);
      })
      .catch((err) => {
        console.log('getNotes error: ', err);
      });
  };

  const userImage = (item) => {
    const imgSource = profileImage(item.gender, item['profile_url']);
    return <img src={imgSource} style={{ height: 24 }} className='rounded-circle' />;
  };

  const getPatientPrescriptionItems = async (pId) => {
    if (manualOrderItems?.length === 0 || Object.keys(manualOrderItems).length === 0) {
      const prescriptionItems = await getPrescriptionItems({
        id: pId,
      }).unwrap();
      setMedicines([]);
      dispatch(setManualOrderItems([]));
      const result = Object.values(prescriptionItems?.data)
        .map((items) => {
          const grouped = items.reduce((acc, item) => {
            const { variation, ...rest } = item;
            const variationWithLabelKey = {
              ...variation,
              medicineId: item.id,
              labelKey: `${variation.name}, ${variation.variation}, ${variation.type}`,
              mrp: item.mrp,
              price: item.mrp * 0.85,
              sku: item.sku,
            };
            if (!acc[rest.product.id]) {
              acc[rest.product.id] = {
                ...rest,
                product_name: rest.product.product_name,
                quantity: 1,
                selectedVariation: '',
                discount: 15,
                medicineId: rest.id,
                price: (parseFloat(rest.mrp) * 0.85)?.toFixed(2),
                mrp: parseFloat(rest.mrp)?.toFixed(2),
                variations: [],
                sku: '',
              };
            }
            acc[rest.product.id].variations.push(variationWithLabelKey);
            return acc;
          }, {});
          return Object.values(grouped);
        })
        .flat();
      setMedicinesList(result);
      setMedicines(result);
      dispatch(setManualOrderItems(result));
    } else {
      const prescriptionItems = await getPrescriptionItems({
        id: patientId || patientDetails?.id,
      }).unwrap();
      setMedicines([]);
      dispatch(setManualOrderItems([]));
      const result = Object.values(prescriptionItems?.data)
        .map((items) => {
          const grouped = items.reduce((acc, item) => {
            const { variation, ...rest } = item;
            const variationWithLabelKey = {
              ...variation,
              medicineId: item.id,
              labelKey: `${variation.name}, ${variation.variation}, ${variation.type}`,
              mrp: item.mrp,
              price: item.mrp * 0.85,
              sku: item.sku,
            };
            if (!acc[rest.product.id]) {
              acc[rest.product.id] = {
                ...rest,
                product_name: rest.product.product_name,
                quantity: 1,
                selectedVariation: '',
                discount: 15,
                medicineId: rest.id,
                price: (parseFloat(rest.mrp) * 0.85)?.toFixed(2),
                mrp: parseFloat(rest.mrp)?.toFixed(2),
                variations: [],
              };
            }
            acc[rest.product.id].variations.push(variationWithLabelKey);
            return acc;
          }, {});
          return Object.values(grouped);
        })
        .flat();
      setMedicinesList(result);
      setMedicines(result);
    }
  };

  useEffect(() => {
    calculateOrderSummary(medicines);
  }, [medicines, manualOrderItems]);

  const prescriptionListQuery = useGetPrescriptionListQuery({
    id: patientId || patientDetails?.id,
  });
  const prescriptionList = prescriptionListQuery?.data?.data || [];

  useEffect(() => {
    if (prescriptionList.length > 0) {
      const prescription = prescriptionList.find((item) => item?.status === 'published');
      if (prescription) {
        dispatch(setLatestPrescription(prescription));
      }
    }
  }, [prescriptionList, dispatch]);

  const formatDate = (dateString) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    // Add leading zero to minutes if needed
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const formattedDate = `${day} ${months[monthIndex]} ${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  };

  const handleQuantityChange = (id, amount) => {
    setIsNewMedicineAdded(true);
    const updatedMedicines = medicines
      .map((medicine) => {
        if (medicine.id === id) {
          const newQuantity = medicine.quantity + amount;
          if (newQuantity > 60) {
            setErrorMessage('Quantity cannot be more than 60');
            return { ...medicine, quantity: 60 };
          }
          setErrorMessage('');
          return { ...medicine, quantity: newQuantity };
        }
        return medicine;
      })
      .filter((medicine) => medicine.quantity > 0); // Remove medicines with quantity 0 or less

    setMedicines(updatedMedicines);
    dispatch(setManualOrderItems(updatedMedicines));
  };

  const calculateOrderSummary = (medicines) => {
    let mrpTotal = 0;
    let discountTotal = 0;
    if (medicines.length > 0) {
      medicines.forEach((medicine) => {
        const medicineMRP = parseFloat(medicine.mrp) || 0;
        const medicineDiscount = parseFloat(medicine.discount) || 0;
        const medicineQuantity = parseInt(medicine.quantity, 10) || 0;
        discountTotal += ((medicineMRP * medicineDiscount) / 100) * medicineQuantity;
        mrpTotal += medicineMRP * medicineQuantity;
      });
    }
    const total = mrpTotal - discountTotal;
    setOrderSummary((prevOrderSummary) => {
      let newManualOrderSummary = {
        orderTotal: mrpTotal,
        discount: discountTotal,
        discount_percentage: manualOrderDetails?.discount_percentage,
        shipping: orderSummaryShipping.shipping || prevOrderSummary.shipping,
        total: total + orderSummaryShipping.shipping || prevOrderSummary.shipping,
      };
      dispatch(setManualOrderSummary(newManualOrderSummary));
      return newManualOrderSummary;
    });
  };

  const handleNoteChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSaveNotes = async () => {
    if (!notes.trim()) {
      return;
    }
    const data = {
      content: notes,
      model_name: 'order',
      model_id: manualOrderId | orderId,
    };
    try {
      const response = await genericNotes({ data });
      setNotesArr([...notesArr, response.data.data]);

      if (response.error) {
        throw new Error(response.error);
      }
      setNotes('');
    } catch (e) {
      console.error('Payment recording failed:', e);
    }
  };

  const handleShippingChange = (value) => {
    setOrderSummary((prevOrderSummary) => {
      let newOrderSummary = {
        orderTotal: prevOrderSummary.orderTotal,
        discount: prevOrderSummary.discount,
        shipping: parseFloat(value) > 0 ? parseFloat(value) : 0,
        total:
          parseFloat(value) > 0
            ? prevOrderSummary.orderTotal - prevOrderSummary.discount + parseFloat(value)
            : prevOrderSummary.orderTotal - prevOrderSummary.discount,
      };
      dispatch(setManualOrderSummary(newOrderSummary));
      return newOrderSummary;
    });
  };

  const handleAddMedicine = () => {
    setIsNewMedicineAdded(true);
    const newId = medicines.length > 0 ? medicines.length + 1 : 1;
    const newMedicine = {
      id: newId,
      product_name: '',
      sku: '',
      quantity: 1,
      product: {
        id: null,
        product_name: '',
        medicine: {
          id: null,
          name: '',
          formulation: '',
        },
        category: '',
        specifications: null,
        image_url: null,
      },
      variations: [],
      mrp: 0,
      price: 0,
      discount: 15,
      manufacturer: {
        id: null,
        name: '',
      },
      oos: false,
      quantity_prescribed: null,
      quantity_available: 1,
      active: true,
      selectedVariation: null,
    };
    setMedicines([...medicines, newMedicine]);
    dispatch(setManualOrderItems([...medicines, newMedicine]));
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSelectNewMedicine = (newMedicine, id) => {
    if (newMedicine) {
      setMedicines((prevMedicines) => {
        let updatedMedicines = [...prevMedicines];
        const { variation, ...rest } = newMedicine;
        const labelKey = `${variation.name}, ${variation.variation}, ${variation.type}`;
        const existingMedicineIndex = updatedMedicines.findIndex((medicine) =>
          medicine.variations.some((v) => v.medicineId === newMedicine.id)
        );

        if (existingMedicineIndex !== -1) {
          // If a match is found, update the quantity of the matched medicine
          const updatedMedicine = {
            ...updatedMedicines[existingMedicineIndex],
            quantity: updatedMedicines[existingMedicineIndex].quantity + 1,
          };
          updatedMedicines[existingMedicineIndex] = updatedMedicine;
          updatedMedicines.pop();
        } else {
          let newMedicineIndex = updatedMedicines.findIndex((medicine) => medicine.id === id);
          const updatedMedicine = {
            ...rest,
            variations: [
              {
                ...variation,
                medicineId: newMedicine.id,
                mrp: newMedicine.mrp,
                price: newMedicine.mrp * 0.85,
                sku: newMedicine.sku,
                labelKey,
              },
            ],
            quantity: 1,
            mrp: parseFloat(newMedicine.mrp)?.toFixed(2),
            price: (parseFloat(newMedicine.mrp) * 0.85)?.toFixed(2),
            discount: 15,
            medicineId: newMedicine.id,
            sku: '',
            selectedVariation: null,
          };
          updatedMedicines[newMedicineIndex] = updatedMedicine;
        }
        dispatch(setManualOrderItems(updatedMedicines));
        return updatedMedicines;
      });
    }
  };

  const handleSetDiscount = (medicineId, value) => {
    const discountValue = value > 0 ? (value <= 100 ? value : 100) : 0;
    const updatedMedicines = medicines.map((medicine) =>
      medicine.id === medicineId
        ? {
            ...medicine,
            discount: discountValue,
            price: (medicine.mrp - (medicine.mrp * discountValue) / 100)?.toFixed(2),
          }
        : medicine
    );
    setMedicines(updatedMedicines);
    dispatch(setManualOrderItems(updatedMedicines));
    calculateOrderSummary(updatedMedicines);
  };

  const handleSelectVariation = (medicineId, id) => {
    const updatedMedicines = medicines.map((medicine) => {
      if (medicine.id === medicineId) {
        const selectedVariation = medicine.variations.find((variation) => variation.medicineId === id);
        if (selectedVariation) {
          return {
            ...medicine,
            id: id,
            medicineId: id,
            mrp: selectedVariation.mrp,
            price: selectedVariation.mrp * 0.85,
            discount: 15,
            sku: selectedVariation.sku,
            selectedVariation: id,
          };
        }
      } else {
        return medicine;
      }
    });
    setMedicines(updatedMedicines);
    dispatch(setManualOrderItems(updatedMedicines));
  };

  const handleClearVariation = (medicineId) => {
    const updatedMedicines = medicines.map((medicine) => {
      if (medicine.id === medicineId) {
        return {
          ...medicine,
          selectedVariation: null,
          sku: '',
        };
      } else {
        return medicine;
      }
    });
    setMedicines(updatedMedicines);
    dispatch(setManualOrderItems(updatedMedicines));
  };

  return (
    <div className='order-details'>
      <p
        className='status-text align-items-center capitalize active align-items-center d-flex'
        style={{
          position: 'absolute',
          background: '#EBEFFC',
          borderRadius: '0.5rem',
          lineHeight: '1rem',
          color: '#243B86',
          padding: '3px 8px 3px 8px',
        }}
      >
        Draft
      </p>
      <div className='order-header'>
        <div
          className='d-flex align-items-center'
          style={{
            background: '#F9FAFE',
            padding: '10px',
            borderRadius: '8px',
            width: '50%',
          }}
        >
          <div className='mr-2'>Source: Manual</div>
          <div className='vl'>.</div>

          <div className='mx-2 patient-name d-flex gap-2'>
            {userImage(patientApiDetails)}
            <div>{patientDetails.full_name || orderDetail?.user?.full_name}</div>
          </div>
          <div className='mx-2 patient-address d-flex'>
            <div className='mx-2'>
              <img src={location} alt='location' />
            </div>
            <div>
              {orderDetail?.shipping_address ? (
                <div>{orderDetail?.shipping_address}</div>
              ) : (
                <>
                  <div>
                    {patientAddress?.address_line1}, {patientAddress?.address_line2}
                  </div>
                  <div>
                    {patientAddress?.city}, {patientAddress?.state}, {patientAddress?.pincode}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setEditMode(true);
          }}
          className='reusableBtnActive  reusableBtn '
          style={{
            background: '#fff',
            border: '1px solid #133696',
            color: '#133696',
          }}
        >
          View Prescription
        </div>
        <Modal
          backdrop='static'
          size='md'
          open={isEditMode}
          onClose={() => {
            setEditMode(false);
          }}
        >
          <Modal.Header>
            <div className='settingFormOutline p-0'>
              <div className='formTitle mb-0'>Prescription</div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <PatientPrescription patientId={patientDetails?.id} />
          </Modal.Body>
        </Modal>
      </div>
      {errorMessage && <div className='inputMsg'>{errorMessage}</div>}
      <div className='mt-4 mb-4 table-responsive'>
        <table className='table order-details-table' style={{ marginBottom: '0' }}>
          <thead>
            <tr>
              <th>Sr.</th>
              <th>Medicine</th>
              <th>SKU</th>
              <th>Variation</th>
              <th>Quantity</th>
              <th>Discount</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {medicines.length > 0 &&
              medicines?.map((medicine, index) => (
                <tr key={medicine.id}>
                  <td>{index + 1}</td>
                  <td>
                    {medicine?.product?.product_name ? (
                      <div>{`${capitalizeFirstLetter(medicine?.product?.medicine?.formulation)} ${
                        medicine?.product?.product_name
                      }`}</div>
                    ) : (
                      <SearchBox
                        placeHolder='Search medicine'
                        searchMethod='PRODUCTLINEITEMSEARCH'
                        label='product.product_name'
                        searchId='Patients'
                        defaultInputValue={''}
                        nestedLabelKey={true}
                        handleItemClick={(item) => {
                          handleSelectNewMedicine(item, medicine.id);
                        }}
                        imagePath='profile_url'
                      />
                    )}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>{medicine?.sku && <div>{medicine?.sku}</div>}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <Select2DropDown
                      valueKey='medicineId'
                      labelKey='labelKey'
                      value={medicine.selectedVariation}
                      onSelect={(id) => {
                        handleSelectVariation(medicine.id, id);
                      }}
                      onClean={() => {
                        handleClearVariation(medicine.id);
                      }}
                      data={medicine.variations}
                      placeholder='Select'
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <button className='minus-btn' onClick={() => handleQuantityChange(medicine.id, -1)}>
                      -
                    </button>
                    <span className='medicine-quant'>{medicine.quantity.toString().padStart(2, '0')}</span>
                    <button className='add-btn' onClick={() => handleQuantityChange(medicine.id, 1)}>
                      +
                    </button>
                  </td>
                  <td style={{ whiteSpace: 'nowrap', width: '67px' }}>
                    <CoreInputBox
                      validateNumbers={true}
                      type='number'
                      placeholder='Discount %'
                      value={medicine.discount ? medicine.discount : ''}
                      setValue={(value) => {
                        handleSetDiscount(medicine.id, value);
                      }}
                      showUnits={medicine.discount ? '%' : ''}
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {parseInt(medicine.discount) > 0 ? (
                      <div>
                        <div>Rs. {Number(medicine.price)?.toFixed(2)}</div>
                        <div
                          style={{
                            fontSize: '12px',
                            textDecoration: 'line-through',
                          }}
                        >
                          Rs. {Number(medicine.mrp)?.toFixed(2)}
                        </div>
                      </div>
                    ) : (
                      <div>Rs. {Number(medicine.mrp)?.toFixed(2)}</div>
                    )}
                  </td>
                  {parseInt(medicine.discount) > 0 ? (
                    <td style={{ whiteSpace: 'nowrap' }}>Rs. {(medicine.price * medicine.quantity)?.toFixed(2)}</td>
                  ) : (
                    <td style={{ whiteSpace: 'nowrap' }}>Rs. {(medicine.mrp * medicine.quantity)?.toFixed(2)}</td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        <div>
          <button className='add-medicine' onClick={handleAddMedicine}>
            + Add Another Medicine
          </button>
        </div>
        <div className='inputMsg'>{medicineErrors.length > 0 ? medicineErrors : ''}</div>
      </div>

      <div className='row justify-content-end'>
        <div className='col-lg-3'>
          <div className='order-summary p-3 bg-light rounded'>
            <h6 className='fw-bold mb-3'>Order Summary</h6>
            <div className='d-flex justify-content-between mb-2'>
              <div>Order Total</div>
              <div>Rs. {orderSummaryShipping?.orderTotal?.toFixed(2) || orderSummary?.orderTotal?.toFixed(2)}</div>
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <div>Discount</div>
              <div>
                -Rs.{' '}
                {orderSummaryShipping?.discount
                  ? orderSummaryShipping?.discount.toFixed(2)
                  : 0 || orderSummary?.discount
                  ? orderSummary?.discount?.toFixed(2)
                  : 0}{' '}
                {manualOrderDetails?.discount_percentage && `(${manualOrderDetails?.discount_percentage}%)`}
              </div>
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <div>Shipping</div>
              <div style={{ width: '40%' }}>
                <CoreInputBox
                  value={orderSummaryShipping?.shipping || orderSummary?.shipping || ''}
                  setValue={(value) => handleShippingChange(value)}
                />
              </div>
            </div>
            <div className='hrLine mb-3' />
            <div className='d-flex justify-content-between fw-bold'>
              <div>Total</div>
              <div>Rs. {orderSummaryShipping?.total?.toFixed(2) || orderSummary?.total?.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='hrLine mb-3' style={{ background: '#D1DCFF' }} />
      {orderStatus === 'draft' && (
        <div className='row'>
          <div className='col-lg-12'>
            <div className='notes-section mt-3'>
              <label className='fw-bold'>Notes</label>
              <div className='textarea-container'>
                <textarea value={notes} onChange={handleNoteChange} className='form-control mb-3' />
                <button className='save-button' onClick={handleSaveNotes}>
                  <img src={noteSave} alt='Save Note' />
                </button>
              </div>

              {notesArr.length > 0 ? (
                notesArr
                  .slice()
                  .reverse()
                  .map((note) => (
                    <div key={note?.id} className='note-container mt-2'>
                      <div className='mr-2' style={{ marginRight: '10px' }}>
                        <img
                          src={profileImage(patientDetails.gender, userData?.profile_url)}
                          style={{ height: 40 }}
                          alt='Profile'
                        />
                      </div>
                      <div className='note'>
                        <div className='note-time'>{formatDate(note?.updated_at)}</div>
                        <div className='note-content'>{note?.content}</div>
                      </div>
                    </div>
                  ))
              ) : reduxNotesData.length > 0 ? (
                reduxNotesData
                  .slice()
                  .reverse()
                  .map((note) => (
                    <div key={note?.id} className='note-container mt-2'>
                      <div className='mr-2' style={{ marginRight: '10px' }}>
                        <img
                          src={profileImage(patientDetails.gender, userData?.profile_url)}
                          style={{ height: 40 }}
                          alt='Profile'
                        />
                      </div>
                      <div className='note'>
                        <div className='note-time'>{formatDate(note?.updated_at)}</div>
                        <div className='note-content'>{note?.content}</div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className='note-container'>
                  <div className='mr-2' style={{ marginRight: '10px' }}>
                    <img
                      src={profileImage(patientDetails.gender, userData?.profile_url)}
                      style={{ height: 40 }}
                      alt='Avatar'
                    />
                  </div>
                  <div className='note' style={{ height: '40px' }}>
                    <div className='note-time'></div>
                    <div className='note-content'></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;