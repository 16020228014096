import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

const DayPicker = ({ id, label, days, initialSelectedDays, onSelectionChange }) => {
  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);

  const { pid } = useParams();

  // useEffect(() => {

  //     setSelectedDays(initialSelectedDays);

  // }, [initialSelectedDays]);

  useEffect(() => {
    if (pid) {
      const timer = setTimeout(() => {
        setSelectedDays(initialSelectedDays);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [initialSelectedDays]);

  const toggleDay = (day) => {
    const updatedSelectedDays = selectedDays.includes(day)
      ? selectedDays.filter((d) => d !== day)
      : [...selectedDays, day];

    setSelectedDays(updatedSelectedDays);
    onSelectionChange(
      id,
      updatedSelectedDays.map((selectedDay) => days.indexOf(selectedDay))
    );
  };

  return (
    <div>
      {label && <label className='day-picker-label'>{label}</label>}
      <div className='day-picker'>
        {days.map((day, index) => (
          <button
            key={index}
            className={`day ${selectedDays.includes(day) ? 'selected' : ''}`}
            onClick={() => toggleDay(day)}
          >
            {day}
          </button>
        ))}
      </div>
    </div>
  );
};

DayPicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  days: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialSelectedDays: PropTypes.arrayOf(PropTypes.string),
  onSelectionChange: PropTypes.func,
};

DayPicker.defaultProps = {
  label: '',
  initialSelectedDays: [],
  onSelectionChange: () => {},
};

export default DayPicker;
