import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import Pagination from '../Pagination';
import CommonBorderBox from '../CommonBorderBox';
import useApiManager from 'networking/ApiManager';
import CommonAlert from '../CommonAlert';
import TaskTemplateList from './TaskTemplateList';
import TaskTemplateForm from './TaskTemplateForm';
import CoreInputBox from '../CoreInputBox';
import { search } from 'res/images';

function TaskTemplate(props) {
  const ApiClient = useApiManager();
  const { setIsLoading, setIsShowAlert, setAlertType } = props;
  const [isEditMode, setEditMode] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [taskTemplate, setTaskTemplate] = useState({
    template_name: '',
    title: '',
    content: '',
  });
  const [editTaskTemplate, setEditTaskTemplate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [regimeTemplateList, setRegimeTemplateList] = useState([]);
  const [searchRegime, setSearchRegime] = useState('');
  const [isUpdateAlert, setIsUpdateAlert] = useState(false);
  const [updateAlertType, setIsUpdateAlertType] = useState('alert-success');
  const [categoryListDrop, setCategoryListDrop] = useState();

  useEffect(() => {
    if (searchRegime?.length > 2) {
      searchTaskTemplate(searchRegime, currentPage);
    } else {
      getTaskTemplatesList();
    }
  }, [currentPage]);

  useEffect(() => {
    getCategoryList();
    searchTaskCategoryList();
  }, []);

  const getCategoryList = (index) => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getTaskCategoryList(params, index)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const searchTaskCategoryList = (index) => {
    setIsLoading(true);

    ApiClient.searchTaskCategoryList()
      .then((res) => {
        console.log('res.data', res.data);
        const categoryNames = res.data?.map((item) => ({ title: item?.category_name }));
        setCategoryListDrop(categoryNames);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const searchTaskTemplate = (searchData, pageNo) => {
    setIsLoading(true);
    setSearchRegime(searchData);
    if (searchData?.length > 2) {
      ApiClient.searchTaskTemplateList({ search_str: searchData, page: pageNo || 1 })
        .then((res) => {
          setTotalPages(res.headers['total-pages']);
          setRegimeTemplateList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      getTaskTemplatesList();
    }
  };

  const getTaskTemplatesList = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getTaskTemplateList(params)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setRegimeTemplateList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const deleteTaskTemplate = (id) => {
    setIsLoading(true);
    ApiClient.deleteTaskTemplate(id)
      .then((res) => {
        setIsLoading(false);
        getTaskTemplatesList();
        setAlertType('alert-success');
        setIsShowAlert('Task Template Deleted Successfully');
      })
      .catch((err) => {
        console.warn('fdf', err);
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.error);
      });
  };

  const getTaskTemplate = (item) => {
    setIsLoading(true);
    ApiClient.getTaskTemplate(item.id).then((res) => {
      setEditMode(true);
      let updateTaskData = res.data;
      setEditTaskTemplate({
        id: updateTaskData.id,
        template_name: updateTaskData.template_name,
        title: updateTaskData.title,
        content: updateTaskData.content,
      });
      setIsLoading(false);
    });
  };

  return (
    <div className='mb-3'>
      <div className='mb-4'>
        <CoreInputBox placeholder='Search Task Template' setValue={searchTaskTemplate} leftIcon={search} />
      </div>

      <CommonBorderBox className='settingFormOutline'>
        <div className='formTitle'>Add Task Template</div>
        <TaskTemplateForm
          questionsList={questionsList}
          taskTemplate={taskTemplate}
          setTaskTemplate={setTaskTemplate}
          getTaskTemplatesList={getTaskTemplatesList}
          setIsShowAlert={setIsShowAlert}
          setAlertType={setAlertType}
          setQuestionsList={setQuestionsList}
          categoryListDrop={categoryListDrop}
        />
      </CommonBorderBox>
      <div className='table-responsive'>
        <TaskTemplateList
          regimeTemplateList={regimeTemplateList}
          editTaskTemplate={getTaskTemplate}
          deleteTaskTemplate={deleteTaskTemplate}
          searchTaskTemplate={searchTaskTemplate}
        />
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Modal
        backdrop='static'
        size='md'
        open={isEditMode}
        onClose={() => {
          setEditMode(false);
        }}
      >
        <Modal.Header>
          <div className='settingFormOutline p-0'>
            <div className='formTitle mb-0'>Edit Task Template</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <CommonAlert
            isShowAlert={isUpdateAlert}
            alertType={updateAlertType}
            setIsShowAlert={setIsUpdateAlert}
            className='mt-0 mb-3'
          />

          <TaskTemplateForm
            key={taskTemplate.id}
            questionsList={questionsList}
            taskTemplate={editTaskTemplate}
            setTaskTemplate={setEditTaskTemplate}
            getTaskTemplatesList={getTaskTemplatesList}
            setIsShowAlert={setIsShowAlert}
            setAlertType={setAlertType}
            isEditMode
            setEditMode={setEditMode}
            setQuestionsList={setQuestionsList}
            setIsUpdateAlert={setIsUpdateAlert}
            setIsUpdateAlertType={setIsUpdateAlertType}
            categoryListDrop={categoryListDrop}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TaskTemplate;
