import React from 'react';
import './inputBox.css';

function CoreTextArea(props) {
  const {
    value,
    setValue,
    valueType,
    type,
    leftIcon,
    rightIcon,
    inputClass,
    inputStyle,
    placeholder,
    label,
    maxLength,
    style,
    msgStyle,
    labelStyle,
    showMSG,
    validateNumbers,
    retuired,
    disabled,
    rows,
  } = props;

  const onChangeValue = (data) => {
    let val = data.target.value;
    setValue(val);
  };

  return (
    <div>
      {label && (
        <label className='inputlabel' style={labelStyle}>
          {retuired && <span className='requiredInput'> * </span>} {label}
        </label>
      )}
      <textarea
        onChange={onChangeValue}
        value={value}
        className={`form-control coreInput textArea ${inputClass} ${inputStyle}`}
        aria-label='With textarea'
        rows={rows || 8}
        required={retuired}
        disabled={disabled}
        placeholder={placeholder}
        style={inputStyle}
      ></textarea>
      {showMSG && (
        <div className='inputMsg' style={msgStyle}>
          {showMSG}
        </div>
      )}
    </div>
  );
}

export default CoreTextArea;
