import React, { useState, useEffect } from 'react';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { format, isValid } from 'date-fns';
import 'rsuite/dist/rsuite.min.css';
import './dateRange.scss';
import { clickOnElmOnBodyClick, stoppropagation } from './Helper';

function DateRange(props) {
  const {
    filterData,
    prescriptionDate,
    setFilterData,
    handleClearDate,
    clearFiltersData,
    title,
    disableDate,
    pms,
    pmsConsultDate,
    pmsDate,
    useFormattedDate
  } = props;
  const [date, setDate] = useState([]);
  const [isDateSet, setIsDateSet] = useState(false)

  useEffect(() => {
    if (date?.length > 0 && isValid(date[0]) && isValid(date[1]) && !isDateSet) {
      const formattedDateRange = useFormattedDate 
        ? `${format(date[0], 'dd/MM/yyyy')}-${format(date[1], 'dd/MM/yyyy')}`//`${format(date[0], 'MM/dd/yyyy')}-${format(date[1], 'MM/dd/yyyy')}`
        : date;

        if (!pms) {
          setFilterData({ date: formattedDateRange });
        }
        if (pmsDate) {
          setFilterData({
            pmsDate: formattedDateRange,
          });
        }
        if (prescriptionDate) {
          setFilterData({
            prescriptionDate: formattedDateRange,
          });
        }
        if (pmsConsultDate) {
          setFilterData({
            pmsConsultDate: formattedDateRange,
          });
        }
      setIsDateSet(true)
    }
    if (date?.length > 0 && !useFormattedDate) {
     if (!pms) {
        setFilterData({ date: date });
      }
      if (pmsDate) {
        setFilterData({
          pmsDate: date,
        });
      }
      if (prescriptionDate) {
        setFilterData({
          prescriptionDate: date,
        });
      }
      if (pmsConsultDate) {
        setFilterData({
          pmsConsultDate: date,
        });
      }
    }
  }, [date, useFormattedDate]);  // [date]);

  useEffect(() => {
    setDate([]);
  }, [clearFiltersData]);

  useEffect(() => {
    if(!Array.isArray(filterData?.date)) {
      if (!pms && filterData?.date) {
        const parsedDate = useFormattedDate 
          ? filterData?.date.split('-').map(dateStr => new Date(dateStr))
          : filterData?.date;
        
        setDate([new Date(parsedDate[0]), new Date(parsedDate[1])]);
      } else if (pmsDate && filterData?.pmsDate) {
        const parsedDate = useFormattedDate 
          ? filterData?.pmsDate.split('-').map(dateStr => new Date(dateStr))
          : filterData?.pmsDate;
        
        setDate([new Date(parsedDate[0]), new Date(parsedDate[1])]);
      } else if (prescriptionDate && filterData?.prescriptionDate) {
        const parsedDate = useFormattedDate 
          ? filterData?.prescriptionDate.split('-').map(dateStr => new Date(dateStr))
          : filterData?.prescriptionDate;
        
        setDate([new Date(parsedDate[0]), new Date(parsedDate[1])]);
      } else if (pmsConsultDate && filterData?.pmsConsultDate) {
        const parsedDate = useFormattedDate 
          ? filterData?.pmsConsultDate.split('-').map(dateStr => new Date(dateStr))
          : filterData?.pmsConsultDate;
        
        setDate([new Date(parsedDate[0]), new Date(parsedDate[1])]);
      } else {
        setDate([]);
      }
    }
  }, [filterData, useFormattedDate]);

  const renderTitle = () => {
    const style = title === 'By Date' ? {
      fontWeight: '600',
      fontSize: '14px',
      color: '#243B86'
  } : {};
    return <div className='dateHeading' style={style}>{title || 'Date Range'}</div>;
  };

  const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left',
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left',
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left',
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left',
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left',
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left',
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left',
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left',
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left',
    },
  ];

  const datePicker = () => {
    return (
      <Stack direction='column' spacing={8} alignItems='auto'>
        <DateRangePicker
          //disabledDate={disableDate1}
          shouldDisableDate={disableDate}
          container={() => document.getElementById('datePickerContainer')}
          showOneCalendar
          onChange={(evt) => {
            setIsDateSet(false)
            setDate(evt)
          }}
          ranges={predefinedBottomRanges}
          placeholder='Select Date'
          style={{ width: '100%' }}
          value={date || []}
          onClean={() =>
            handleClearDate(
              pmsDate ? 'pmsDate' : pmsConsultDate ? 'pmsConsultDate' : prescriptionDate ? 'prescriptionDate' : 'date'
            )
          }
        />
      </Stack>
    );
  };

  return (
    <div className='dateRangeDiv'>
      {renderTitle()}
      <div id='datePickerContainer' className='position-relative'>
        {datePicker()}
      </div>
    </div>
  );
}

export default DateRange;