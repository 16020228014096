import React, { useState } from 'react';
import { dateFormat } from '../Helper';
import { CopyIcon } from 'res/images';
import CommonAlert from '../CommonAlert';

function Coupons({ activeCoupons, expiredCoupons }) {
  const [isCopyAlert, setIsCopyAlert] = useState(false);
  const [copyAlertType, setIsCopyAlertType] = useState('alert-success');
  const [alertMessage, setAlertMessage] = useState('');

  const handleCopy = (couponCode) => {
    navigator.clipboard
      .writeText(couponCode)
      .then(() => {
        setIsCopyAlert(`Copied to clipboard`);
        setAlertMessage('Copied to clipboard');
        setTimeout(() => setIsCopyAlert(false), 2000);
      })
      .catch((err) => console.error('Failed to copy: ', err));
  };

  const ListedCoupons = ({ coupons, type }) => {
    if (coupons.length === 0) {
      return (
        <tr>
          <td colSpan='5' className='requiredInput' style={{ textAlign: 'center', marginTop: '8px', font: 'caption' }}>
            *No {type === 'active' ? 'Active' : 'Expired'} Coupons Available
          </td>
        </tr>
      );
    }

    return coupons.map((coupon, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td style={{ color: type === 'active' ? '#193990' : 'black' }}>{coupon.coupon_code}</td>
        <td>
          {dateFormat(coupon.start_date)} to {dateFormat(coupon.end_date)}
        </td>
        <td>{coupon.description}</td>
        {type === 'active' && (
          <td onClick={() => handleCopy(coupon.coupon_code)} style={{ cursor: 'pointer' }}>
            <img src={CopyIcon} alt='Copy' />
          </td>
        )}
      </tr>
    ));
  };

  return (
    <>
      <CommonAlert
        isShowAlert={isCopyAlert}
        alertType={copyAlertType}
        setIsShowAlert={setIsCopyAlert}
        message={alertMessage}
        className='mt-1 mb-0'
      />
      <table
        className='table'
        cellSpacing='5'
        style={{
          borderTop: '0.5px solid #0000002d',
          borderRight: '0.5px solid #0000002d',
          borderLeft: '0.5px solid #0000002d',
          borderRadius: '8px',
          borderCollapse: 'separate',
        }}
      >
        <caption style={{ captionSide: 'top', fontWeight: 'bold', color: '#333333' }}>Active Coupons</caption>
        <thead style={{ borderRadius: '8px' }}>
          <tr style={{ borderRadius: '8px' }}>
            <th scope='col'>Sr.No.</th>
            <th scope='col'>Code</th>
            <th scope='col'>Validity</th>
            <th scope='col'>Description</th>
            <th scope='col' style={{ color: '#FFF' }}>
              Copy
            </th>
          </tr>
        </thead>
        <tbody style={{ borderRadius: '8px' }}>
          <ListedCoupons coupons={activeCoupons} type='active' />
        </tbody>
      </table>

      <table
        className='table'
        cellSpacing='5'
        style={{
          borderTop: '0.5px solid #0000002d',
          borderRight: '0.5px solid #0000002d',
          borderLeft: '0.5px solid #0000002d',
          borderRadius: '8px',
          borderCollapse: 'separate',
        }}
      >
        <caption style={{ captionSide: 'top', fontWeight: 'bold', color: '#333333' }}>Expired Coupons</caption>
        <thead style={{ borderRadius: '8px' }}>
          <tr style={{ borderRadius: '8px' }}>
            <th scope='col'>Sr.No.</th>
            <th scope='col'>Code</th>
            <th scope='col'>Validity</th>
            <th scope='col'>Description</th>
          </tr>
        </thead>
        <tbody style={{ borderRadius: '8px' }}>
          <ListedCoupons coupons={expiredCoupons} type='expired' />
        </tbody>
      </table>
    </>
  );
}

export default Coupons;
