import { AdminPanelHeader } from 'components';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import CoreDropDown from '../CoreDropDown';
import { filters } from 'res/images';
import { dateFormat, dateFormatString, prepareGraphData, profileImage, subtractMonths, timeFormat } from '../Helper';
import { useNavigate, useParams } from 'react-router-dom';
import useApiManager from 'networking/ApiManager';
//import { subDays } from 'rsuite/esm/utils/dateUtils';
import Loader from '../Loader';

function PatientGoalDetail() {
  const ApiClient = useApiManager();
  const navigate = useNavigate();

  const { id, gid, pageTitle } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState('');
  const [goalsData, setGoalsData] = useState([]);
  const [activeDateRange, setActiveDateRange] = useState(
    dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1))
  );

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  useEffect(() => {
    getGoalsData();
  }, []);

  useEffect(() => {
    getGraphData();
  }, [gid, activeDateRange]);

  const getGoalsData = () => {
    const query = {
      date_range: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)),
    };
    setIsLoading(true);
    ApiClient.getPatientgoals(id, false)
      .then((res) => {
        setIsLoading(false);
        setGoalsData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getGraphData = (dateData) => {
    const params = {
      date_range: activeDateRange,
      // query_str: 'months_6',
    };

    setIsLoading(true);
    ApiClient.getPatientgoals(id, gid, params)
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderHeader = () => {
    const showTree = [
      { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
      {
        icon: profileImage(userDetails.gender, userDetails.image),
        title: userDetails.name,
        link: `/patient/${id}/dashboard/`,
      },
      {
        title: pageTitle.replace('-', ' '),
        link: `/patient/${id}/dashboard/`,
      },
      {
        title: data?.goal?.title,
      },
    ];
    return (
      <div className='my-3'>
        <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
      </div>
    );
  };
  const renderPatientHeader = () => {
    return (
      <>
        <div className='articleHeader stickyHeader'>
          {renderHeader()}
          <div className='hrLine mb-3' />
        </div>
      </>
    );
  };

  const options = {
    legend: 'none',
    curveType: 'function',
    series: {
      0: { pointSize: 8 },
      1: { pointSize: 4 },
    },
    vAxis: {
      viewWindow: { min: 1 },
      baseline: 1,
      format: '#',
    },
    trendlines: {
      0: {
        type: 'exponential',
        visibleInLegend: true,
        color: 'red',
      },
    },
  };

  const predefinedBottomRanges = [
    { title: '1 Year', id: dateFormatString(new Date(subtractMonths(11, new Date())).setDate(1)) },
    { title: '6 Months', id: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)) },
    { title: '3 Months', id: dateFormatString(new Date(subtractMonths(2, new Date())).setDate(1)) },
    { title: '1 Month', id: dateFormatString(subtractMonths(1, new Date())) },
  ];

  const renderDetailGraph = () => {
    return (
      <div className='px-4 wrapperContent'>
        <div className='d-flex justify-content-end gap-10 mb-3'>
          {/* <CoreDropDown
            showKey='goal'
            nestedKey='title'
            setValue={(item) => {
              navigate(`/patient/${id}/${pageTitle}/${item}/show`);
            }}
            value={gid}
            data={goalsData}
            selectStyle={{ height: '40px' }}
            placeholder='Select Goal'
          /> */}
          <CoreDropDown
            data={predefinedBottomRanges}
            value={activeDateRange}
            setValue={(vlue) => {
              setActiveDateRange(vlue);
            }}
            placeholder='Select'
          />
        </div>
        <div className='d-flex w-100 chart-box mb-4'>
          <Chart
            chartType='LineChart'
            width='95%'
            height='100%'
            data={data?.graph_stats?.plot_date ? prepareGraphData(data?.graph_stats?.plot_date) : prepareGraphData([])}
            options={options}
          />
          <div className='chart-box-Detail'>
            <div className='d-flex adherence-text'>
              <small>
                <strong className='me-2'>{data?.adherence?.percentage}%</strong>
                <sub>Adherence</sub>
              </small>
            </div>
            <div className='d-flex'>
              {data?.activity?.value ? data?.activity?.value + '/' : null}
              <strong>
                {data?.activity?.target_value} {data?.goal?.measuring_event?.measuring_unit}
              </strong>
            </div>
            <div className='d-flex'>{data?.frequency}</div>
          </div>
        </div>
        <div className='d-flex justify-content-start mb-4'>
          <div className='goalLogDatail w-50'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='title'>Goal Log</div>
              {/* <div className='gap-10 d-flex'>
                <div className='goalfilterBtn ms-2'>
                  <div className={`d-flex align-items-center filterBtn btnParentDiv themeColor boldFont`}>
                    <img src={filters} className='btnImg' />
                    <div>Apply Filter</div>
                  </div>
                </div>
                <CoreDropDown placeholder='WATER'></CoreDropDown>
              </div> */}
            </div>
            <div className='table-responsive w-100 mt-4'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Logged On</th>
                    <th>Value</th>
                    <th>Source</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.activity_logs?.map((item) => {
                    return (
                      <tr>
                        <td>
                          {dateFormat(item.record_date)}, {timeFormat(item.record_date)}
                        </td>
                        <td>{item.value}</td>
                        <td>{item.channel}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className='wrapper'>
        {renderPatientHeader()}
        {renderDetailGraph()}
        <Loader show={isLoading} />
      </div>
    </>
  );
}

export default PatientGoalDetail;
