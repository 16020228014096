import React, { useEffect, useMemo, useRef, useState } from 'react';
import { checked, errorPhone, phone, sendIcon, redCrossIcon } from 'whealth-core-web/res/images';
import { OtpInput } from './index';
import './loginform.css';
import { CoreInputBox, CoreButton, AlertMessage, CommonAlert, Loader } from 'whealth-core-web/components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { acessTokens, setAdminData } from 'redux/Slices';
import useApiManager from 'networking/ApiManager';
import { loginpageLogo } from 'res/images';
import { s3Url } from 'whealth-core-web/components/Helper';
import ReCAPTCHA from 'react-google-recaptcha';
import mixpanel from 'mixpanel-browser';

function LoginForm(props) {
  const ApiClient = useApiManager();
  const { background, logo, nameLogo } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  let { REACT_APP_RECAPTCHA_KEY } = process.env;
  REACT_APP_RECAPTCHA_KEY = REACT_APP_RECAPTCHA_KEY ?? '6LcYQKkkAAAAAI8R3N8HbnQInjkr-xwmYDhteVY2';

  const [number, setNumber] = useState('');
  const [toggle, setToggle] = useState(false);
  const [showErrors, setShowErrors] = useState(true);
  const [validNumber, setValidNumber] = useState(number.length == 10);
  const [icons, setIcons] = useState({ errorMsg: '', phoneIcon: phone, inputStyle: {}, rightIcon: checked });
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [recaptcha, setRecaptcha] = useState('');

  useEffect(() => {
    let isValidNumber = number.length == 10;
    setValidNumber(isValidNumber);
    isActiveErrors(false, !isValidNumber);
  }, [number]);

  useEffect(() => {
    setRecaptcha('');
  }, [toggle]);

  const handleOtpClick = (code) => {
    !isLoading && verifyOTP(code);
  };

  const verifyOTP = (code) => {
    setIsLoading(true);
    const data = {
      code: code,
      phone: number,
    };

    ApiClient.verifyOTP(data)
      .then((res) => {
        setIsLoading(false);
        dispatch(acessTokens(res.data.access));
        localStorage.setItem('token', res.data.access);
        localStorage.setItem('refreshToken', res.data.refresh);
        setAlertType('alert-success');
        setIsShowAlert('OTP verified successfully');
        fetchUserProfile();
        window.webengage.user.login(res.data.uuid);
        console.log(res.data, 'data');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.message);
        console.log(err);
      });
  };

  const fetchUserProfile = () => {
    ApiClient.currentAdminProfile()
      .then((res) => {
        dispatch(setAdminData(res.data));
        mixpanel.identify(res.data.uuid);
        mixpanel.people.set({
          $uuid: res.data.uuid,
          $name: res.data.full_name,
          $email: res.data.email,
          $phone: res.data.phone,

          // Add anything else about the user here
        });
        if (res?.data?.role == 'ee') {
          navigate('/appointment');
        } else {
          navigate('/dashboard');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderRecaptcha = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      return (
        <div className='w-100 my-2 mt-4'>
          <ReCAPTCHA
            onExpired={() => {
              setRecaptcha('');
            }}
            onErrored={() => {
              setRecaptcha('');
            }}
            onChange={(captcha) => {
              setRecaptcha(captcha);
            }}
            ref={recaptchaRef}
            grecaptcha={window.grecaptcha}
            sitekey={REACT_APP_RECAPTCHA_KEY}
          />
        </div>
      );
    }
    return null;
  };

  const renderButton = (text, onClick, icon, className) => {
    let customClassName = recaptcha && !toggle ? '' : 'disabledOtpBtn';
    customClassName = !toggle ? customClassName : '';
    if (className != 'disabledOtpBtn') {
      customClassName = '';
    }

    return (
      <div>
        {!toggle && renderRecaptcha()}
        <CoreButton
          customClass={(btnState, customClassName, className)}
          icon={icon || sendIcon}
          title={text}
          onClick={onClick}
        />
      </div>
    );
  };

  const otpInput = useMemo(() => {
    return (
      <OtpInput
        resendOtp={() => setToggle(!toggle)}
        showBtn={renderButton}
        number={number}
        onClick={handleOtpClick}
        sendOtp={() => sendOtp()}
        recaptcha={recaptcha}
        setRecaptcha={setRecaptcha}
      />
    );
  }, [toggle]);

  const btnState = 'active';

  const isActiveErrors = (active, isHideIcon) => {
    if (active) {
      icons.inputStyle = { borderColor: 'var(--dustRed)' };
      icons.rightIcon = redCrossIcon;
      icons.errorMsg = 'Invalid Mobile Number, Please try again';
    } else {
      icons.inputStyle = {};
      icons.errorMsg = '';
      icons.rightIcon = checked;
    }
    if (isHideIcon) {
      icons.rightIcon = '';
    }
    setIcons({ ...icons });
  };

  const sendOtp = (toggleScreen) => {
    setIsLoading(true);
    const data = {
      phone: number,
    };
    ApiClient.sendOTP(data)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setIsShowAlert('OTP Sent Successfully');
        setAlertType('alert-success');
        toggleScreen && setToggle(!toggle);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsShowAlert(err.response?.data?.message);
        setAlertType('alert-danger');
        console.log(err);
      });
  };

  const handleBtnClick = () => {
    const isDevelopment = process.env.REACT_APP_ENVIRONMENT !== 'production';

    if (validNumber) {
      setShowErrors(false);
      isActiveErrors(false);

      if (!isLoading && (isDevelopment ? number.length === 10 : recaptcha)) {
        sendOtp(true);
      }
    } else {
      setShowErrors(true);
      isActiveErrors(true);
    }

    setIcons({ ...icons });
  };

  const loginInput = () => {
    const isDevelopment = process.env.REACT_APP_ENVIRONMENT !== 'production';
    const isButtonDisabled = isDevelopment ? number.length != 10 : number.length < 10 || !recaptcha;

    return (
      <>
        <CoreInputBox
          leftIcon={icons.phoneIcon}
          placeholder='Registered Mobile Number'
          rightIcon={(showErrors || validNumber) && icons.rightIcon}
          value={number}
          maxLength={10}
          label='Sign In'
          setValue={setNumber}
          valueType='number'
          inputStyle={icons.inputStyle}
          msgStyle={{ color: 'var(--dustRed)' }}
          showMSG={icons.errorMsg}
          validateNumbers
          showCode
        />
        {renderButton('Send OTP', handleBtnClick, false, isButtonDisabled && 'disabledOtpBtn')}
      </>
    );
  };

  const renderHeading = () => <div className='loginHeading'>Building the Future of Healthcare Delivery</div>;

  const showAlertMessage = useMemo(() => {
    return <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />;
  }, [isShowAlert]);

  const renderBody = () => {
    return (
      <div className='flexBgDiv loginFormPart'>
        <div className='cardCss border-light cardBody '>
          <div className='alertSpace'>{isShowAlert && showAlertMessage}</div>
          <img src={s3Url('logo.svg', loginpageLogo)} className='NamelogoImage' />
          {renderHeading()}
          {!toggle ? loginInput() : otpInput}

          {renderFooterText()}
        </div>
      </div>
    );
  };

  const renderFooterText = () => {
    const siteUrl = process.env.REACT_APP_SITE_URL || 'https://www.whealthventures.com/';
    return (
      <div className='mt-auto text-center footerText'>
        <div>to know more about us visit</div>
        <a href={siteUrl} target='_blank'>
          {siteUrl}
        </a>
      </div>
    );
  };

  return (
    <>
      <div className='d-flex'>
        <div className='loginFormImage'>
          <img src={s3Url('login-banner.jpg', background)} className='halfLoginImg' />
        </div>
        {renderBody()}
        <Loader show={isLoading} />
      </div>
    </>
  );
}

export default LoginForm;
