import useApiManager from 'networking/ApiManager';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setAlerts, updatePatientDetailsApi, updatePatinetTabs } from 'redux/Slices';
import { DatePicker } from 'rsuite';
import { dateFormat } from './Helper';

function RenewalDateCard(props) {
  const { data, title } = props;
  const { patientDetails, updatePatinetTab, updatePatientDetails, patientActiveTab } = useSelector(
    (state) => state.sliceReducer
  );
  const [isLoading, setIsLoading] = useState(false);

  const ApiClient = useApiManager();
  const inputRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();

  const createSubscription = (dateData) => {
    let end_date = new Date(dateData);
    end_date = end_date.toISOString();
    let date = { end_date };
    setIsLoading(true);
    ApiClient.createSubscription(id, date)
      .then((res) => {
        setIsLoading(false);
        dispatch(setAlerts({ patient: { title: res.data.message, type: 'alert-success' } }));
        dispatch(updatePatientDetailsApi(!updatePatientDetails));
        if (patientActiveTab == 'timeline') {
          dispatch(updatePatinetTabs({ title: 'timeline', update: !updatePatinetTab.update }));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        let errAlert = err?.response?.data?.errors?.non_field_errors;
        if (err.response.status == 500) {
          errAlert = 'Internal Server Error';
        }
        dispatch(setAlerts({ patient: { title: errAlert, type: 'alert-danger' } }));
      });
  };

  return (
    <div className='d-flex mb-3 pe-3 flex-column'>
      <div className='d-flex justify-content-between w-100'>
        <div>
          <div className=' detailsHeading'>{title}</div>
          <div>
            {patientDetails.subscription_expiry_date && (
              <div className=''>{dateFormat(patientDetails.subscription_expiry_date)} </div>
            )}
          </div>
        </div>
        <div className='renewalDate'>
          <DatePicker
            style={{ width: 140, padding: 0 }}
            placement='auto'
            ref={inputRef}
            format='yyyy-MM-dd HH:mm:ss'
            placeholder='YYYY-MM-DD HH:MM:SS'
            container={() => document.getElementById('renewaldateContainer')}
            disabledDate={(date) => {
              if (date < new Date(patientDetails.subscription_expiry_date)) {
                return true;
              } else {
                return false;
              }
            }}
            calendarDefaultDate={new Date(patientDetails.subscription_expiry_date ?? new Date())}
            onOk={(date) => {
              const windowConfirm = window.confirm('Are you sure you want to update?');
              if (windowConfirm) {
                createSubscription(date);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default RenewalDateCard;
