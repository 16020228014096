import React from 'react';
// import { subDays } from 'rsuite/esm/utils/dateUtils';
import { subDays } from 'rsuite/esm/internals/utils/date';
import { dateFormatString, subtractMonths, subtractWeeks } from 'whealth-core-web/components/Helper';

function DayWeekMonthView(props) {
  const { setActive, active } = props;

  const durationData = [
    { title: 'D', date: dateFormatString(new Date()) },
    { title: 'W', date: dateFormatString(subtractWeeks(6)) },
    { title: 'M', date: dateFormatString(subDays(new Date(), 29)) },
    { title: '6M', date: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)) },
  ];

  const renderDuration = () => {
    return durationData.map((item, index) => {
      const isactive = active.title == item.title;
      return (
        <div
          onClick={() => {
            setActive(item);
          }}
          key={index}
          className={`btn btn-light ${isactive ? 'activeDuration' : ''}`}
        >
          {item.title}
        </div>
      );
    });
  };

  return (
    <div className='btn-group w-100' role='group' aria-label='btn'>
      {renderDuration()}
    </div>
  );
}

export default DayWeekMonthView;
