import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { adherenceIcon, carePlan, editTheme, email, whatsapp } from 'res/images';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import DateCards from 'whealth-core-web/components/DateCards';
import {
  dateFormatString,
  handleChannel,
  profileImage,
  showArrayValues,
  titlizeString,
  getOverallPercentage,
} from 'whealth-core-web/components/Helper';
import StatusCard from 'whealth-core-web/components/StatusCard';
import StatusTag from 'whealth-core-web/components/StatusTag';
import RenewalDateCard from '../RenewalDateCard';
import CallLog from '../CallLog/CallLog';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { setMedicineActiveCard } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import CategoryCard from './CategoryCard';

function PatientProfile(props) {
  const { data, getPatientData, setCallLogNotes, dob } = props;
  const [editStatus, setEditStatus] = useState(false);
  const [editOnboardStatus, setEditOnboardStatus] = useState(false);
  const [status, setStatus] = useState([]);
  const [isCallLog, setIsCallLog] = useState(false);
  const [callLogData, setCallLogData] = useState();
  const [reportData, setReportData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const [behaviourQuetionData, setbehaviourQuetionData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [callLogApiCalled, setCallLogApiCalled] = useState(false);
  const dispatch = useDispatch();
  const { reduxReportData, reduxNotesData, reduxPrescriptionData, reduxGoalData, reduxRegimeData } = useSelector(
    (state) => state.sliceReducer
  );
  const [categories, setCategories] = useState([]);

  const ApiClient = useApiManager();
  const profileQuestionkeys = 'consultant_date,onboarding_call_date,blood_test_date';
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { id } = useParams();

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'call_log' && item.isActive) {
          setIsCallLog(item.isActive);
        }
      });
    }
  }, [configData]);

  useEffect(() => {
    if (data?.base_lining) {
      setCategories(data.base_lining);
    }
  }, [data]);

  const getCallLog = () => {
    // setIsLoading(true);
    ApiClient.getCallLog(id)
      .then((res) => {
        setCallLogData(res.data);
        // setIsLoading(false);
        // setTotalPages(res.headers['total-pages']);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const getPatientReport = () => {
    // setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getPatientReports(id, false, params)
      .then((res) => {
        setReportData(res.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const getNotes = () => {
    // setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getNotes(id, params)
      .then((res) => {
        // setIsLoading(false);
        setNotesData(res.data);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const getPatientGoals = () => {
    // setLoading(true);
    let params = { page: currentPage, date_range: dateFormatString(new Date()), category: 'default' };
    ApiClient.getPatientgoals(id, false, params)
      .then((res) => {
        setGoalData(res.data.filter((item) => item.goal.category === 'default'));
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const getRegimeData = () => {
    // setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.behaviourQuestionList(id, params)
      .then((res) => {
        // setIsLoading(false);
        setbehaviourQuetionData(res.data);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
      });
  };

  const getMedicalHistory = (page = currentPage) => {
    // setIsLoading(true);
    let params = { page };
    ApiClient.prescriptionList(id, params)
      .then((res) => {
        // setIsLoading(false);
        dispatch(setMedicineActiveCard(res.data.filter((item) => item.status === 'published')));
        setPrescriptionData(res.data.filter((item) => item.status === 'published'));
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
      });
  };

  const calculateAgeInMonths = (date) => {
    const currentDate = new Date();
    const birthDate = new Date(date);
    const months =
      (currentDate.getFullYear() - birthDate.getFullYear()) * 12 + (currentDate.getMonth() - birthDate.getMonth());
    return months;
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setEditStatus(false);
        setEditOnboardStatus(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
  }, []);

  useEffect(() => {
    if (status?.length == 0) {
      getPatientStatus();
    }
  }, []);

  const getPatientStatus = () => {
    const type = { type: 'patient' };
    ApiClient.allStatus(type)
      .then((res) => {
        setStatus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.warn('reduxNotesData?.length ', reduxNotesData?.length);
  const renderPatientInfo = () => {
    const ageInMonths = calculateAgeInMonths(data?.date_of_birth);

    return (
      <div className='card-body'>
        <div className='d-flex user-profile-card'>
          <div className='me-3 d-flex flex-column'>
            <img className='rounded float-start img-fluid me-3' src={profileImage(data.gender, data.profile_url)} />

            {isCallLog && (
              <div className=' m-2 ms-4 profile-call-log-btn'>
                <button
                  class='call-log-btn'
                  type='button'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasRight'
                  aria-controls='offcanvasRight'
                  onClick={() => {
                    reduxNotesData?.length == 0 && getNotes();
                    getPatientGoals();
                    reduxReportData?.length == 0 && getPatientReport();
                    reduxPrescriptionData?.length == 0 && getMedicalHistory();
                    getRegimeData();
                    getCallLog();
                  }}
                >
                  Call Log
                </button>
                <div
                  class='offcanvas offcanvas-end callLog-Part'
                  tabindex='-1'
                  id='offcanvasRight'
                  aria-labelledby='offcanvasRightLabel'
                >
                  <CallLog
                    setCallLogNotes={setCallLogNotes}
                    data={data}
                    goalData={goalData}
                    prescriptionData={reduxPrescriptionData?.length ? reduxPrescriptionData : prescriptionData}
                    notesData={reduxNotesData?.length ? reduxNotesData : notesData}
                    reportData={reduxReportData?.length ? reduxReportData : reportData}
                    callLogData={callLogData}
                    behaviourQuetionData={behaviourQuetionData}
                    setCallLogData={setCallLogData}
                    getRegimeData={getRegimeData}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='w-75'>
            <div className='border-bottom pb-3'>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h5 className='card-title capitalize'>{data?.full_name}</h5>

                <div className='pointer' onClick={() => navigate(`/patient/${data.id}/edit`)}>
                  <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                </div>
              </div>
              <p className='card-text capitalize'>
                {showArrayValues(
                  [
                    showArrayValues([dob ? data.date_of_birth && ` ${ageInMonths} M` : data.age, data.gender], ', '),
                    data.city,
                  ],
                  ' · '
                )}
              </p>

              <p>{data.patient_id}</p>
            </div>
            <div className='d-flex'>
              {data?.adherence?.adherence_range && (
                <StatusTag title={data?.adherence?.adherence_range} icon={adherenceIcon} />
              )}
              {data?.channel && (
                <StatusTag title={handleChannel(data.channel).title} icon={handleChannel(data.channel).icon} />
              )}
              {/* {data?.care_plan?.title && <StatusTag title={data.care_plan?.title} icon={carePlan} />} */}
            </div>
            <div className='pt-2 social-block'>
              <img src={whatsapp} className='icon' />
              {data?.phone}
            </div>
            {data?.email ? (
              <div className='pt-2 social-block'>
                <img src={email} className='icon' />
                <a href={'mailto:' + data?.email}>{data?.email}</a>
              </div>
            ) : null}
          </div>
        </div>
        <div className='hrLine' />
        <div className='baselining-edit '>
          <div className='mt-2 mb-3 d-flex justify-content-between'>
            <h6>
              {'Baselining '}
              <span style={{ color: 'gold' }}>( {getOverallPercentage(categories, data.profile_info)}% Complete)</span>
            </h6>
            <div className='pointer' onClick={() => navigate(`/patient/${data.id}/baselining`)}>
              <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
            </div>
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            {categories.map((category, index) => (
              <CategoryCard
                key={index}
                name={category.display_title}
                value={category.score}
                progress={category.completion}
                width={100}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='d-flex userProfilePart'>
      <div className='card w-60 m-2 userProfileDetails no-border' style={{ minWidth: '60%' }}>
        {renderPatientInfo()}
      </div>

      <div ref={containerRef} className='card memberShipDetails w-40 m-2  no-border' style={{ minWidth: '35%' }}>
        <div className='card-body memberShipcard-body pb-0'>
          <StatusCard
            patientUpdate
            setUpdateStatus={setEditStatus}
            onboardingStatus
            updateStatus={editStatus}
            status={status}
            setStatus={setStatus}
            getPatientData={getPatientData}
            badgeTitle={data?.status?.title}
          />
          <div className='memberinfo-list mt-3'>
            {data?.profile_info?.map((item, index) => {
              if (profileQuestionkeys.includes(item.key)) {
                return (
                  <DateCards
                    data={item}
                    title={item.question}
                    value={item.answer}
                    index={index}
                    getPatientData={getPatientData}
                    isTimeShow={item.type == 'date_time'}
                    isEditable
                    calendarAlign={'auto'}
                  />
                );
              }
            })}
            <RenewalDateCard data={data} title='Renewal On' />
            {data?.subscription_title && (
              <DateCards title={'Subscription'} value={data?.subscription_title} calendarAlign={'auto'} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientProfile;
