import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import { laptopLink, paymentLink } from 'whealth-core-web/assets/images';
import Select2DropDown from '../common/Select2DropDown';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, timeFormat } from '../Helper';
import useApiManager from 'networking/ApiManager';
import CommonAlert from '../CommonAlert';

function RecordPayment(props) {
  const {
    appointmentDetail,
    handleRecordPayment,
    open,
    setOpen,
    errors,
    isClinicEnable,
    setErrors,
    userData,
    isNivanCare,
    setIsLoading,
    isShowAlert,
    setIsShowAlert,
    alertType,
  } = props;
  const ApiClient = useApiManager();

  const [overflow, setOverflow] = useState(true);
  const [data, setData] = useState({});
  const [clinicalTeamList, setClinicalTeamList] = useState([]);

  useEffect(() => {
    setErrors({});
  }, [open]);

  useEffect(() => {
    if (appointmentDetail?.id) {
      setData({
        payment_type: appointmentDetail?.payment_type,
        payment_mode: appointmentDetail?.payment_mode,
        payment_from: appointmentDetail?.payment_from,
        amount: appointmentDetail?.amount,
        patient_attendance_status: appointmentDetail?.patient_attendance_status,
        payment_with_id: appointmentDetail?.payment_with?.id,
        payment_with_source: appointmentDetail?.payment_with_source,
      });
    } else {
      setData({});
    }
  }, [appointmentDetail]);

  useEffect(() => {
    if (isNivanCare && data?.payment_with_source == 'AdminProfile') {
      getDoctor();
    }
  }, [data?.payment_with_source]);

  const renderRecordHeader = () => {
    return (
      <div className='recordPayment-top-box d-flex justify-content-between align-items-start mb-3'>
        <div className=''>
          <div className='head'>Appointment Summary</div>
          <div className='tileName mt-2'>
            {appointmentDetail?.user?.full_name} (
            {appointmentDetail?.user?.gender && appointmentDetail?.user?.gender[0]?.toUpperCase()},{' '}
            {appointmentDetail?.user?.age})
          </div>
          <p>Ph: {appointmentDetail?.user?.phone}</p>
          <p className='mt-2'>
            <strong>
              {dateFormat(appointmentDetail?.consult_datetime)}, {timeFormat(appointmentDetail?.consult_datetime)} -{' '}
              {timeFormat(appointmentDetail?.consult_end_datetime)}
            </strong>{' '}
            <br />
            With {appointmentDetail?.consultant?.full_name} <br />
            {appointmentDetail?.service?.title} <br />
            At Clinic {appointmentDetail?.location?.name} <br />
            <div className='d-flex'>
              {' '}
              Total Fee :
              <span class='material-icons-outlined' style={{ fontSize: '13px', paddingTop: '4px', paddingLeft: '4px' }}>
                currency_rupee
              </span>
              {appointmentDetail?.appointment_fee}
            </div>
          </p>

          {appointmentDetail?.payment_mode && (
            <div className='d-flex flex-column justify-content-start mt-2 '>
              <span className='consultationTitle d-flex'>
                <img src={paymentLink} />
                <div className='d-flex'>
                  {appointmentDetail?.appointment_fee !== appointmentDetail?.amount
                    ? 'Partially Payment of'
                    : 'Payment of'}{' '}
                  <span
                    class='material-icons-outlined'
                    style={{ fontSize: '14px', paddingTop: '4px', paddingLeft: '4px' }}
                  >
                    currency_rupee
                  </span>{' '}
                  {appointmentDetail?.amount}
                </div>
              </span>
              <span className='consultationTitle d-flex'>
                <img src={laptopLink} />{' '}
                {appointmentDetail?.payment_mode?.charAt(0)?.toUpperCase() + appointmentDetail?.payment_mode?.slice(1)}
              </span>
            </div>
          )}
        </div>
        {/* <div className='d-flex justify-content-center align-items-center popover-btn'>
              <span class='material-icons-outlined'>mode_edit_outline</span> Edit
            </div> */}
      </div>
    );
  };

  const getDoctor = () => {
    setIsLoading(true);
    let params = { can_accept_payment: true, remove_inactive_admins: true };
    ApiClient.getSearchTeam(params)
      .then((res) => {
        setIsLoading(false);
        setClinicalTeamList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      className='recordPayment'
      backdrop='static'
      overflow={overflow}
      open={open}
      onClose={() => {
        // setData({});
        setOpen(false);
      }}
    >
      <Modal.Header>
        <Modal.Title className='text-center modalTitle'>Record Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`${isShowAlert ? 'mt-5' : ''}`}>
          <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
        </div>
        <div className='appontmentModle-body d-flex flex-column'>
          {renderRecordHeader()}
          <div className='mb-2'>
            <Select2DropDown
              retuired
              disabled={appointmentDetail?.payment_type == 'paid' || appointmentDetail?.payment_type == 'free'}
              label={'Patient Attendance'}
              data={
                userData?.role == 'ee'
                  ? [{ title: 'Booked', id: 'booked' }]
                  : [
                      { title: 'Show', id: 'show' },
                      { title: 'No Show', id: 'no_show' },
                    ]
              }
              showMSG={errors?.patient_attendance_status}
              value={data?.patient_attendance_status || ''}
              onSelect={(val) => setData({ patient_attendance_status: val })}
            />
          </div>
          {data?.patient_attendance_status && data?.patient_attendance_status != 'no_show' ? (
            <div className='mb-2'>
              <Select2DropDown
                disabled={appointmentDetail?.payment_type}
                retuired
                label={'Payment Type'}
                data={[
                  { title: 'Free', id: 'free' },
                  { title: 'Fully Paid', id: 'paid' },
                  { title: 'Partially Paid', id: 'partially_paid' },
                ]}
                showMSG={errors?.payment_type}
                value={data?.payment_type || ''}
                onSelect={(val) => {
                  setData({
                    payment_type: val,
                    patient_attendance_status: data?.patient_attendance_status,
                    amount: val == 'paid' ? appointmentDetail?.appointment_fee : null,
                  });
                }}
              />
            </div>
          ) : null}
          {data?.patient_attendance_status != 'no_show' &&
          (data?.payment_type == 'paid' || data?.payment_type == 'partially_paid') ? (
            <>
              <div className='mb-2'>
                <Select2DropDown
                  retuired
                  disabled={appointmentDetail?.payment_type == 'paid' || appointmentDetail?.payment_type == 'free'}
                  label={'Payment Mode'}
                  data={[
                    { title: 'Cash', id: 'cash' },
                    { title: 'Online', id: 'online' },
                  ]}
                  showMSG={errors?.payment_mode}
                  value={data?.payment_mode || ''}
                  onSelect={(val) => {
                    delete data?.payment_from;
                    setData({ ...data, payment_mode: val });
                  }}
                />
              </div>
              {data?.payment_mode == 'online' ? (
                <div className='mb-2'>
                  <Select2DropDown
                    retuired
                    disabled={appointmentDetail?.payment_type == 'paid' || appointmentDetail?.payment_type == 'free'}
                    showMSG={errors?.payment_from}
                    label={'Payment From'}
                    data={[
                      { title: 'UPI', id: 'upi' },
                      { title: 'PoS', id: 'pos' },
                      { title: 'Payment Link', id: 'payment_link' },
                      { title: 'Bank Transfer', id: 'bank_transfer' },
                    ]}
                    value={data?.payment_from || ''}
                    onSelect={(val) => setData({ ...data, payment_from: val })}
                  />
                </div>
              ) : null}

              <div className='mb-2'>
                <Select2DropDown
                  disabled={appointmentDetail?.payment_type == 'paid' || appointmentDetail?.payment_type == 'free'}
                  retuired
                  data={
                    userData?.role == 'ee'
                      ? [{ title: 'Nivaan Clinic', id: 'NivaanClinic' }]
                      : isNivanCare && isClinicEnable
                      ? [
                          { title: 'Clinical Team', id: 'AdminProfile' },
                          { title: 'Nivaan Clinic', id: 'NivaanClinic' },
                          { title: 'Clinic', id: 'Location' },
                        ]
                      : isClinicEnable
                      ? [
                          { title: 'Clinic', id: 'Location' },
                          { title: 'Doctor', id: 'AdminProfile' },
                        ]
                      : [{ title: 'Doctor', id: 'AdminProfile' }]
                  }
                  onSelect={(_id, _obj) => {
                    setData({
                      ...data,
                      payment_with_source: _obj?.id,
                      payment_with_id:
                        _obj?.id == 'AdminProfile' && !isNivanCare
                          ? appointmentDetail?.consultant?.id
                          : _obj?.id == 'Location'
                          ? appointmentDetail?.location?.id
                          : null,
                    });
                  }}
                  value={data?.payment_with_source || ''}
                  showMSG={errors?.payment_with_source}
                  searchable={false}
                  style={{ width: '100%' }}
                  placeholder='Payment With'
                  label='Payment With'
                />
              </div>
              {/* {data?.payment_with_source == 'AdminProfile' ? (
                <div className='mb-2'>
                  <Select2DropDown
                    retuired
                    data={[appointmentDetail?.consultant]}
                    onSelect={(_id, _obj) => {
                      setData({ ...data, payment_with_id: _obj?.id });
                    }}
                    value={data?.payment_with_id}
                    showMSG={errors?.payment_with_id}
                    labelKey={'full_name'}
                    searchable={false}
                    style={{ width: '100%' }}
                    placeholder='With'
                    label='With'
                  />
                </div>
              ) : null}
              {data?.payment_with_source == 'Location' && appointmentDetail?.location ? (
                <div className='mb-2'>
                  <Select2DropDown
                    retuired
                    data={[appointmentDetail?.location]}
                    onSelect={(_id, _obj) => {
                      setData({ ...data, payment_with_id: _obj?.id });
                    }}
                    value={data?.payment_with_id}
                    showMSG={errors?.payment_with_id}
                    labelKey={'name'}
                    searchable={false}
                    style={{ width: '100%' }}
                    placeholder='With'
                    label='With'
                  />
                </div>
              ) : null} */}
              {data?.payment_with_source == 'AdminProfile' && isNivanCare ? (
                <div className='mb-2'>
                  <Select2DropDown
                    retuired
                    disabled={appointmentDetail?.payment_type == 'paid' || appointmentDetail?.payment_type == 'free'}
                    data={clinicalTeamList}
                    onSelect={(_id, _obj) => {
                      setData({ ...data, payment_with_id: _obj?.id });
                    }}
                    value={data?.payment_with_id}
                    showMSG={errors?.payment_with_id}
                    labelKey={'full_name'}
                    searchable={true}
                    style={{ width: '100%' }}
                    placeholder='Clinical Team'
                    label='Clinical Team'
                  />
                </div>
              ) : null}

              <div className='mb-2'>
                <CoreInputBox
                  disabled={
                    data?.payment_type == 'paid' ||
                    appointmentDetail?.payment_type == 'paid' ||
                    appointmentDetail?.payment_type == 'free'
                  }
                  retuired
                  validateNumbers
                  validateZero
                  validatedecimal
                  label={'Amount'}
                  placeholder={'Amount'}
                  showMSG={errors?.amount}
                  value={data?.amount?.toString() || ''}
                  setValue={(val) => setData({ ...data, amount: val != '' ? Number(val) : null })}
                />
              </div>
            </>
          ) : null}
          {data?.payment_with_source ? (
            <div>
              <label>
                Billed To :{' '}
                {data?.payment_with_source == 'AdminProfile' || data?.payment_with_source == 'NivaanClinic'
                  ? appointmentDetail?.user?.full_name
                  : data?.payment_with_source == 'Location'
                  ? appointmentDetail?.location?.name
                  : null}
              </label>
            </div>
          ) : null}
          {data?.patient_attendance_status ? (
            <div className='mb-2 mt-3'>
              <div
                className='d-flex justify-content-between gap-10 schedulButton'
                onClick={() => handleRecordPayment(appointmentDetail?.id, data)}
              >
                <div className='reusableBtnActive  reusableBtn  w-100'>Record Payment</div>
              </div>
            </div>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RecordPayment;
