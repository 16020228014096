import { AdminPanelHeader, SimpleHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import {
  useCompleteAssigndTaskMutation,
  useGetDashBoardDataQuery,
  useShowAssigndTaskQuery,
  useToggleCompleteAssigndTaskMutation,
} from 'redux/dashboardApis';
import { infoIcon, actions, search } from 'res/images';
import {
  CommonAlert,
  CommonBorderBox,
  CoreDropDown,
  CoreInputBox,
  Loader,
  Pagination,
  ReadMoreReadLess,
} from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { dateFormat, timeFormat, CarePlanNotification, profileImage } from 'whealth-core-web/components/Helper';
import { ZohoCalendar } from 'whealth-core-web/components/ZohoCalendar/ZohoCalendar';
import { ZohoConsultation } from 'whealth-core-web/components/ZohoConsultation/ZohoConsultation';
import './Dashboard.css';
import TaskActionForm from './TaskActionForm';
import { Modal } from 'rsuite';
import { setUserData } from 'redux/Slices';
import { useDispatch } from 'react-redux';

function Dashboard() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [orderData, setOrderData] = useState({});
  const navigate = useNavigate();
  const ApiClient = useApiManager();
  const [taskData, setTaskData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [currentPageNotification, setCurrentPageNotification] = useState(1);
  const [taskCurrentPage, setTaskCurrentPage] = useState(1);
  const [TaskTotalPages, setTaskTotalPages] = useState(1);
  const [consultationCockpit, setConsultationCockpit] = useState('');
  const [businessConfig, setBusinessConfig] = useState({});
  const [isEditMode, setEditMode] = useState(false);
  const [searchTask, setSearchTask] = useState('');

  const [notificationData, setNotificationData] = useState([]);
  const [item, setItem] = useState();
  const [dayFilter, setDayFilter] = useState('today');
  const [selectedItemId, setSelectedItemId] = useState(null);

  const { id } = useParams();

  const navigateToUserProfile = (userId) => {
    window.location.reload(navigate(`/patient/${userId}/dashboard`));
  };

  let getDashboard = useGetDashBoardDataQuery();
  let taskApiData = useShowAssigndTaskQuery({ page: taskCurrentPage, page_size: 20, filter: dayFilter });
  let [completeTask, responseData] = useCompleteAssigndTaskMutation();
  let [toggleCompleteTask, response] = useToggleCompleteAssigndTaskMutation();

  let userData = useGetLoginUserDataQuery()?.data?.data || {};

  const getCockpitData = useGetCockpitDataQuery();
  useEffect(() => {
    getCarePlanNotification();
  }, [userData, currentPageNotification]);

  useEffect(() => {
    setIsLoading(!!getDashboard?.isFetching);
    getDashboard?.data?.data && setData(getDashboard?.data?.data);
  }, [getDashboard]);

  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    dispatch(setUserData(userData));
  }, []);

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'consultations' && item.isActive) {
          setConsultationCockpit(item.isActive);
        }
      });
    }
  }, [configData]);

  const getCarePlanNotification = (page = currentPageNotification) => {
    setIsLoading(true);
    const params = { page };
    if (userData?.id) {
      ApiClient.getCarePlanNotification(userData?.id, params)
        .then((res) => {
          setIsLoading(false);
          currentPageNotification === 1
            ? setNotificationData(res.data)
            : setNotificationData([...notificationData, ...res.data]);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const dayList = [
    { title: 'Today', id: 'today' },
    { title: 'Tomorrow', id: 'tomorrow' },
    { title: 'Day After', id: 'day_after' },
  ];

  const markNotification = (item, action_type) => {
    const params = { action_type: action_type };
    setIsLoading(true);
    ApiClient.getCarePlanMarkDoneNotification(userData?.id, item?.id, params)
      .then((res) => {
        setIsLoading(false);
        getCarePlanNotification();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchTask?.length > 1) {
      searchTasks(searchTask, taskCurrentPage, dayFilter);
    } else {
      taskApiData.refetch();
    }
  }, [taskCurrentPage, dayFilter]);

  useEffect(() => {
    const getBusinessConfig = async () => {
      const res = await ApiClient.getConfig();
      if (res.status == 200) setBusinessConfig(res.data.configuration);
    };
    getBusinessConfig();
  }, []);

  useEffect(() => {
    setIsLoading(taskApiData?.isFetching);
    setTaskTotalPages(taskApiData?.data?.headers['total-pages']);
    taskApiData?.data?.data && setTaskData(taskApiData?.data?.data);
  }, [taskApiData]);

  useEffect(() => {
    setIsLoading(responseData?.isLoading);
    responseData?.data?.data && setTaskData(taskApiData?.data?.data);

    if (responseData?.data?.status >= 400) {
      setIsShowAlert(responseData?.data?.data?.detail);
      setAlertType('alert-danger');
    } else if (responseData?.data?.status >= 200 && responseData?.data?.status < 300) {
      setIsShowAlert('Status marked completed');
      setAlertType('alert-success');
    }
  }, [responseData]);

  useEffect(() => {
    setIsLoading(response?.isLoading);
    response?.data?.data && setTaskData(taskApiData?.data?.data);

    if (response?.data?.status >= 400) {
      setIsShowAlert(response?.data?.data?.detail);
      setAlertType('alert-danger');
    } else if (response?.data?.status >= 200 && response?.data?.status < 300) {
      // setIsShowAlert('Status marked completed');
      setAlertType('alert-success');
    }
  }, [response]);

  const searchTasks = (searchData, pageNo) => {
    setIsLoading(true);
    setSearchTask(searchData);
    if (searchData?.length > 1) {
      ApiClient.getSearchTasks({ search_str: searchData, page: pageNo || 1, day_filter: dayFilter })
        .then((res) => {
          setTaskTotalPages(res.headers['total-pages']);
          setTaskData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      taskApiData.refetch();
    }
  };

  const handleCloseModal = () => {
    setEditMode(false);
  };

  const handleTaskRefetch = () => {
    taskApiData.refetch();
  };

  const showCompeleted = (item) => {
    completeTask({ id: item.user.id, taskId: item?.id });
  };

  const toggleShowCompeleted = (item) => {
    toggleCompleteTask({ id: item.user.id, taskId: item?.id });
  };

  let hiddenTotal_articles_count = data?.article?.total_articles_count?.hidden;
  let hiddenLive_articles_count = data?.article?.live_articles_count?.hidden;
  let articleTitle = hiddenLive_articles_count ? 'Total Articles' : 'Total Articles Live';

  const dashboardData = [
    {
      icon: infoIcon,
      title: 'Active Patients',
      count: data?.users?.active_users?.count,
      onClick: () => navigate('/patient'),
      hidden: data?.users?.active_users?.hidden,
    },
    {
      icon: infoIcon,
      title: 'Inactive Patients',
      count: data?.users?.inactive_users?.count,
      onClick: () => navigate('/patient'),
      hidden: data?.users?.inactive_users?.hidden,
    },
    {
      icon: infoIcon,
      title: userData.role == 'admin' || userData.role == 'health_coach_lead' ? 'Assigned Patients' : 'Total Patients',
      count: data?.users?.total_user?.count,
      onClick: () => navigate('/patient'),
      hidden: data?.users?.total_user?.hidden,
    },
    {
      hidden: data?.article?.total_articles_count?.hidden && data?.article?.live_articles_count?.hidden,
      hidden1: hiddenTotal_articles_count,
      hidden2: hiddenLive_articles_count,
      icon: infoIcon,
      title: articleTitle,
      count: data?.article?.total_articles_count.count,
      count1: data?.article?.live_articles_count.count,
      countStyle: { color: 'var(--lightBlack)' },
      onClick: () => navigate('/articles'),
    },
    {
      hidden: data?.team?.total_admins?.hidden,
      icon: infoIcon,
      title: 'Total Team Members',
      count: data?.team?.total_admins?.count,
      onClick: () => navigate('/team'),
    },
  ];

  const dashboardDataMap = () => {
    return dashboardData.map((item, index) => {
      if (item.hidden) {
        return;
      }
      return (
        <CommonBorderBox key={index} onClick={item.onClick} className='dashboardBox w-100 '>
          <div className='card w-100'>
            <div className='card-body'>
              <div className='d-flex justify-content-between dashBoardBoxTitle'>
                <div>
                  <span className='roundedDot' /> {item.title}
                </div>
                <div>
                  <img src={item.icon} />
                </div>
              </div>
              <div className='dashBoardCount'>
                {!item.hidden2 && (item.count1 || item.count1 == 0) && (
                  <span>
                    {item.count1} {!item.hidden1 && !item.hidden2 && '/'}{' '}
                  </span>
                )}
                {!item.hidden1 && item.count}
              </div>
            </div>
          </div>
        </CommonBorderBox>
      );
    });
  };

  const rescheduleHistoryElements = () => {
    const item = taskData?.find((obj) => obj.id === selectedItemId);
    return (
      <div>
        {item && item?.reschedule_history?.length > 0 && (
          <div>
            <h5>Rescheduling History</h5> <br />
            {item?.reschedule_history?.map((historyItem, historyIndex) => {
              return (
                <div key={historyIndex}>
                  <div>
                    Reason: {historyItem.reason}
                    <br />
                    Rescheduled to: {historyItem.new_date}
                    <br />
                    Old Date: {historyItem.old_date}
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const TaskContent = () => {
    return (
      <>
        <div className='mt-4 mb-4 table-responsive' style={{ borderRadius: '8px', overflow: 'hidden' }}>
          <table
            className='table '
            cellspacing='5'
            style={{
              borderRight: '0.5px solid #0000002d',
              borderLeft: '0.5px solid #0000002d',
              borderRadius: '8px',
              borderCollapse: 'separate',
            }}
          >
            <thead style={{ borderRadius: '8px', background: '#F2F2F2' }}>
              <tr style={{ borderRadius: '8px' }}>
                <th scope='col'>Task</th>
                <th scope='col' style={{ color: '#F2F2F2', background: '#F2F2F2' }}>
                  status
                </th>
                <th scope='col'>Patient Name</th>
                <th scope='col'>Due Date</th>
                <th scope='col'>Renewal Date</th>
                <th scope='col'>Category</th>
                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody style={{ borderRadius: '8px' }}>
              {typeof taskData == undefined ? null : taskData?.length > 0 ? (
                taskData?.map((item, index) => {
                  const isActive = item.status == 'completed' ? '#FE4B4B' : '';
                  const isbacklog = 'overdue' ? '#FE4B4B' : '';
                  const isColor = item.status === 'completed' ? '#979797' : 'black';
                  let today = new Date();
                  today.setHours(0, 0, 0, 0);
                  let completionDate = new Date(item.completion_date);
                  completionDate.setHours(23, 59, 59, 999);
                  const isOverdue = today > completionDate;
                  const categoryColor = item?.task_category?.category_color;
                  return (
                    <tr key={index}>
                      <td className='capitalize d-flex'>
                        <div className='form-check' style={{ paddingLeft: '0px' }}>
                          <label className={`container ${item.status === 'completed' ? 'completed-task' : ''}`}>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={`checkbox-${index}`}
                              checked={item.status === 'completed'}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                toggleShowCompeleted(item);
                                if (item?.status == 'assigned') {
                                  setIsShowAlert('Status marked completed');
                                  setAlertType('alert-success');
                                } else {
                                  setIsShowAlert('Status set back to assigned');
                                }
                              }}
                            />
                            <span className='form-check-label checkmark' htmlFor={`checkbox-${index}`}></span>
                          </label>
                        </div>

                        <div
                          style={{
                            textDecoration: item.status === 'completed' ? 'line-through' : 'none',
                            color: isColor,
                          }}
                        >
                          <ReadMoreReadLess limit={150} data={item.content} />
                        </div>
                      </td>
                      <td>
                        {isOverdue && item?.status !== 'completed' && (
                          <span
                            className='me-2'
                            style={{
                              color: isbacklog,
                              background: '#FEE5D7',
                              border: '1px solid #0000002d',
                              borderRadius: '30px',
                              padding: '3px 8px 3px 3px',
                            }}
                          >
                            {isOverdue ? 'Overdue' : ''}
                          </span>
                        )}
                      </td>

                      <td className='capitalize' style={{ color: isColor }}>
                        <div style={{ cursor: 'pointer' }} onClick={() => navigateToUserProfile(item.user.id)}>
                          <span
                            style={{
                              border: '1px solid #0000002d',
                              borderRadius: '30px',
                              padding: '3px 8px 3px 3px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <img
                              className='listUserImage'
                              style={{ width: '20px', height: '20px', marginRight: '5px' }}
                              src={profileImage(item.user.gender, item.user.profile_url)}
                            />
                            {item.user.full_name}
                          </span>
                        </div>
                      </td>

                      <td style={{ color: isColor, whiteSpace: 'nowrap' }}>
                        {item?.completion_date ? dateFormat(item?.completion_date) : 'N/A'}
                      </td>
                      <td style={{ color: isColor, whiteSpace: 'nowrap' }}>
                        {item?.user?.subscription_expiry_date
                          ? dateFormat(item?.user?.subscription_expiry_date)
                          : 'N/A'}
                      </td>
                      <td>
                        {item?.task_category && (
                          <span
                            style={{
                              border: '1px solid #0000002d',
                              borderRadius: '30px',
                              padding: '3px 8px 3px 3px',
                              background: categoryColor,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {item?.task_category?.category_name}
                          </span>
                        )}
                      </td>
                      <td>
                        <div
                          className={item.status === 'completed' ? 'disabled' : ''}
                          style={{ cursor: item.status === 'completed' ? 'not-allowed' : 'pointer' }}
                          onClick={() => {
                            if (item.status !== 'completed') {
                              setSelectedItemId(item.id);
                              setEditMode(true);
                            }
                          }}
                        >
                          <img src={actions} style={{ paddingLeft: '15px' }} alt='Edit' />
                        </div>

                        <Modal
                          backdrop='static'
                          size='md'
                          open={isEditMode}
                          onClose={() => {
                            setEditMode(false);
                          }}
                        >
                          <Modal.Header>
                            <div className='settingFormOutline p-0'>
                              <div className='formTitle mb-0'>Reschedule Task</div>
                            </div>
                          </Modal.Header>
                          <Modal.Body>
                            {isEditMode && (
                              <TaskActionForm
                                itemId={selectedItemId}
                                setIsLoading={setIsLoading}
                                setIsShowAlert={setIsShowAlert}
                                setAlertType={setAlertType}
                                onClose={handleCloseModal}
                                taskApiData={handleTaskRefetch}
                              />
                            )}
                            <div>
                              {/* <h5>Rescheduling History</h5> <br /> */}
                              {rescheduleHistoryElements()}
                            </div>
                          </Modal.Body>
                        </Modal>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan='7'
                    className='requiredInput'
                    style={{ textAlign: 'center', marginTop: '8px', font: 'caption' }}
                  >
                    *No Tasks Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            totalPages={TaskTotalPages}
            currentPage={taskCurrentPage}
            setCurrentPage={setTaskCurrentPage}
            dashboard
          />
        </div>
      </>
    );
  };

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a patient by name or mobile number'
        searchMethod='PATIENTSEARCH'
        label='full_name'
        searchId='Patients'
        renderPath='/patient/{source_id}/dashboard' //ShowPatientProfile
        imagePath='profile_url'
      />
    );
  }, []);

  const renderTodayDataCarePlan = (todayData) => {
    return (
      <>
        <div className='notification-title'>Today</div>
        {todayData &&
          todayData.map((item, index) => {
            return (
              <>
                <div className='notification-box'>
                  <div className='d-flex'>
                    <div className='notification-img'>
                      <img src='https://i0.wp.com/www.sfnwseries.com/wp-content/uploads/2017/11/team-1-4-person-circle-p2-200-1.png?ssl=1' />
                    </div>
                    <div className='notification-text'>
                      <span className='capitalize'>{item.message_title}</span>
                      <p>{item.message_body}</p>
                    </div>
                    <div className='notification-time'>{`${dateFormat(item.created_at)} · ${timeFormat(
                      item.created_at
                    )}`}</div>
                    {/* {dateFormatStringSingleDate(item.created_at)} */}
                  </div>
                  <div className='d-flex justify-content-end mt-2'>
                    <div
                      onClick={() => {
                        markNotification(item, 'dismiss');
                      }}
                      className='btn-dismiss'
                    >
                      Dismiss
                    </div>
                    <div
                      onClick={() => {
                        markNotification(item, 'read');
                      }}
                      className='btn-mark d-flex align-items-center'
                    >
                      <span class='material-icons'>done</span> Mark as Done
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </>
    );
  };

  const renderYesterDayDataCarePlan = (yesterdayData) => {
    return (
      <>
        <div className='notification-title'>yesterday</div>
        {yesterdayData &&
          yesterdayData.map((item, index) => {
            return (
              <>
                <div className='notification-box'>
                  <div className='d-flex'>
                    <div className='notification-img'>
                      <img src='https://i0.wp.com/www.sfnwseries.com/wp-content/uploads/2017/11/team-1-4-person-circle-p2-200-1.png?ssl=1' />
                    </div>
                    <div className='notification-text'>
                      <span className='capitalize'>{item.message_title}</span>
                      <p>{item.message_body}</p>
                    </div>
                    <div className='notification-time'>{`${dateFormat(item.created_at)} · ${timeFormat(
                      item.created_at
                    )}`}</div>
                    {/* {dateFormatStringSingleDate(item.created_at)} */}
                  </div>
                  <div className='d-flex justify-content-end mt-2'>
                    <div
                      onClick={() => {
                        markNotification(item, 'dismiss');
                      }}
                      className='btn-dismiss'
                    >
                      Dismiss
                    </div>
                    <div
                      onClick={() => {
                        markNotification(item, 'read');
                      }}
                      className='btn-mark d-flex align-items-center'
                    >
                      <span class='material-icons'>done</span> Mark as Done
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </>
    );
  };

  const renderAllNotificationDataCarePlan = (allNotifications) => {
    return (
      <>
        <div className='notification-title'>All Notification</div>
        {allNotifications &&
          allNotifications.map((item, index) => {
            return (
              <>
                <div className='notification-box'>
                  <div className='d-flex'>
                    <div className='notification-img'>
                      <img src='https://i0.wp.com/www.sfnwseries.com/wp-content/uploads/2017/11/team-1-4-person-circle-p2-200-1.png?ssl=1' />
                    </div>
                    <div className='notification-text'>
                      <span className='capitalize'>{item.message_title}</span>
                      <p>{item.message_body}</p>
                    </div>
                    <div className='notification-time'>{`${dateFormat(item.created_at)} · ${timeFormat(
                      item.created_at
                    )}`}</div>
                    {/* {dateFormatStringSingleDate(item.created_at)} */}
                  </div>
                  <div className='d-flex justify-content-end mt-2'>
                    <div
                      onClick={() => {
                        markNotification(item, 'dismiss');
                      }}
                      className='btn-dismiss'
                    >
                      Dismiss
                    </div>
                    <div
                      onClick={() => {
                        markNotification(item, 'read');
                      }}
                      className='btn-mark d-flex align-items-center'
                    >
                      <span class='material-icons'>done</span> Mark as Done
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </>
    );
  };

  const renderCarePlanNotificationData = ({ todayData, yesterdayData, allNotifications }) => {
    return (
      <>
        <div className='notification-body'>
          {todayData?.length ? renderTodayDataCarePlan(todayData) : null}
          {yesterdayData?.length ? renderYesterDayDataCarePlan(yesterdayData) : null}
          {allNotifications?.length ? renderAllNotificationDataCarePlan(allNotifications) : null}

          {notificationData.length % 10 === 0 && (
            <div
              className='view-all-btn d-flex justify-content-center'
              onClick={() => setCurrentPageNotification(currentPageNotification + 1)}
            >
              View More
            </div>
          )}
        </div>
      </>
    );
  };

  const renderDeshBoard = useMemo(() => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='grid_view' title='Dashboard' searchBox={renderSearchBox} />
          <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} />
          <SimpleHeader
            consultationCockpit={consultationCockpit}
            title='Overall Statistics'
            isZohoMeetingEnabled={businessConfig.enable_zoho_meeting ?? false}
            // zohoOauthLink={data?.connect_zoho_meeting_url}
            isZohoOauthExpired={data?.is_zoho_token_expired}
          />
        </div>
      </div>
      <div className='px-4 mb-4 pb-4 wrapperContent'>
        <div className='d-flex justify-content-between'>
          <div className='w-100'>
            {Object.keys(data)?.length ? <div className='dashboardBody'>{dashboardDataMap()}</div> : ''}
            <div className='d-flex justify-content-between'>
              <div className='mt-4'>
                <strong>Tasks List</strong>
              </div>

              <div className='mt-3 d-flex'>
                <div style={{ marginRight: '20px' }}>
                  <CoreDropDown
                    placeholder={'Select Filter'}
                    data={dayList}
                    value={dayFilter}
                    setValue={(data) => {
                      setDayFilter(data);
                    }}
                  />
                </div>
                <div style={{ width: '300px' }}>
                  <CoreInputBox
                    placeholder='Search task name / Patient name'
                    setValue={searchTasks}
                    leftIcon={search}
                  />
                </div>
              </div>
            </div>

            {/* {taskData?.length > 0 ? (
              TaskContent()
            ) : (
              <p style={{ textAlign: 'center', marginTop: '8px', font: 'caption', color: 'red' }}>
                *No Tasks Available
              </p>
            )} */}
            {TaskContent()}
          </div>
          {notificationData.length ? (
            <div className='Dashboardnotification w-100 '>
              <div className='head d-flex align-items-center'>
                <span class='material-icons'>notifications</span> Notification
              </div>
              <div className='d-flex justify-content-center'>
                {notificationData?.length ? '' : 'No Notification found'}
              </div>
              {renderCarePlanNotificationData(CarePlanNotification(notificationData))}
              {/* <div>view more</div> */}
            </div>
          ) : null}
        </div>
        {businessConfig?.enable_zoho_meeting && !data?.is_zoho_token_expired && !consultationCockpit && (
          <div className='mt-4 admin-zoho-calendar'>
            <ZohoCalendar />
          </div>
        )}
        {consultationCockpit && (
          <div className='mt-4 admin-zoho-calendar'>
            <ZohoConsultation configData={configData} />
          </div>
        )}
      </div>
    </>
  ));

  return (
    <>
      <div className='wrapper'>
        {/* {sideBar} */}
        {renderDeshBoard}
        <Loader show={isLoading} />
      </div>
    </>
  );
}

export default Dashboard;
