import React, { useState, useEffect } from 'react';
import { RangeSlider, Input } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './priceRangeFilter.scss';

const PriceRangeFilter = ({ filterData, setFilterData, clearFiltersData, setClearFilterData }) => {
  const defaultRange = [0, 150000]
  const [range, setRange] = useState(defaultRange);
  useEffect(() => {
    if(filterData?.priceRange?.length === 0) {
      setRange(defaultRange);
      //setFilterData(prevData => ({ ...prevData, priceRange: defaultRange }));
      setFilterData(prevData => {
        const updatedData = { ...prevData };
        delete updatedData.priceRange;
        return updatedData;
      });
      setClearFilterData(false);
    } else if (filterData?.priceRange) {
      setRange(filterData?.priceRange);
    }
  }, [filterData]);

  // useEffect(() => {
  //   if (clearFiltersData) {
  //     setRange([0, 150000]);
  //     setFilterData(prevData => ({ ...prevData, priceRange: [0, 150000] }));
  //   }
  // }, [clearFiltersData]);

  useEffect(() => {
    if (clearFiltersData) {
      setRange(defaultRange);
      setFilterData(prevData => ({ ...prevData, priceRange: defaultRange }));
      setClearFilterData(false);
    }
  }, [clearFiltersData, setFilterData]);

  const handleSliderChange = (value) => {
    setRange(typeof value === Object ? [value] : value);
    //setFilterData({ ...filterData, priceRange: value });
    let obj = { priceRange: typeof value === Object ? [value] : value};
    setFilterData(obj);
  };

  const handleInputChange = (value, index) => {
    const numericValue = Number(value);
    if (isNaN(numericValue)) return;
    const validatedValue = Math.max(0, Math.min(150000, numericValue));
    const newRange = [...range];
    newRange[index] = validatedValue;
    setRange(newRange);
    let obj = { priceRange: newRange };
    setFilterData(obj);
    //setFilterData({ ...filterData, priceRange: newRange });
  };

  const orderStyle = {
    fontWeight: '600',
    fontSize: '14px',
    color: '#243B86',
    marginBottom: '10px'
  };

  return (
    <div className='priceRangeFilter'>
      <div className='filterHeading' style={orderStyle}>By Price Range</div>
      <div className='rangeSliderContainer'>
        <RangeSlider
          defaultValue={range || []}
          value={range || []}
          min={0}
          max={150000}
          step={50}
          onChange={handleSliderChange}
        />
      </div>
      <div className='filterRangeSubHeading'>
        <div>₹ 0</div>
        <div>₹ 1.5 Lakh</div>
      </div>
      <div className='inputRow'>
        <div className='inputWrapper'>
          <label>Min</label>
          <Input
            value={range?.[0]}
            onChange={(value) => handleInputChange(value, 0)}
          />
        </div>
        <div className='inputWrapper'>
          <label>Max</label>
          <Input
            value={range?.[1]}
            onChange={(value) => handleInputChange(value, 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceRangeFilter;