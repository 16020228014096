import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AsyncTypeahead,
  Highlighter,
  Menu,
  MenuItem,
} from "react-bootstrap-typeahead";

import "react-bootstrap-typeahead/css/Typeahead.css";

import useApiManager from "networking/ApiManager";
import { profileImage } from "whealth-core-web/components/Helper";
import { searchIcon } from "res/images";

const getNestedValue = (obj, keyPath) => {
  if (!keyPath.includes(".")) {
    return obj[keyPath];
  }
  return keyPath.split(".").reduce((acc, key) => (acc ? acc[key] : null), obj);
};
function SearchBox(props) {
  const navigate = useNavigate();
  const ApiClient = useApiManager();

  const {
    allowTosearch,
    label,
    searchId,
    imagePath,
    stopNavigation,
    renderPath,
    onChange,
    placeHolder,
    searchMethod,
    defaultInputValue,
    suggestionList,
    hanldeSuggestions,
    minLength,
    handleItemClick,
    id,
    clearValue,
    newRef,
    disabled,
    searchOnlyEnable,
    nestedLabelKey = false,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const clearRef = useRef(null);

  useEffect(() => {
    setOptions([...suggestionList]);
  }, [suggestionList.length]);

  useEffect(() => {
    if (clearRef) {
      document.body.addEventListener("click", function (e) {
        clearRef?.current?.hideMenu();
      });
    }
  }, [document]);

  const handleSearch = (query) => {
    allowTosearch && onChange(query);
    if (query.length > 2) {
      setIsLoading(true);
      let params = {
        search_str: query,
      };
      if (searchOnlyEnable) {
        params.is_disabled = false;
      }
      ApiClient.search(searchMethod, params, id)
        .then((res) => {
          setOptions(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setOptions([...suggestionList]);
    }
  };
  const handleClick = (item) => {
    if (handleItemClick) {
      handleItemClick(item);
    } else {
      navigate(`${renderPath?.replace("{source_id}", item?.id)}`);
    }
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const thumbnailImage = (item) => {
    const url = userImage(item);
    if (url != null) {
      return imageTag(item[label], url);
    }
  };

  const userImage = (item) => {
    if (item.gender == undefined) {
      return stopNavigation ? item.measuring_event.image_url : item[imagePath];
    } else {
      return profileImage(
        item.gender,
        stopNavigation ? item.measuring_event.image_url : item[imagePath]
      );
    }
  };

  const imageTag = (alt, src, style) => {
    return (
      <img
        className="searchPic rounded-circle"
        alt={alt}
        src={src}
        style={style}
      />
    );
  };

  const asyncSearch = () => {
    return (
      <>
        <div
          className="searchBarInput"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AsyncTypeahead
            disabled={disabled}
            ref={!clearValue ? clearRef : newRef}
            defaultInputValue={defaultInputValue}
            className="w-100"
            filterBy={filterBy}
            id={searchId}
            isLoading={isLoading}
            labelKey={
              nestedLabelKey ? (option) => getNestedValue(option, label) : label
            }
            minLength={minLength}
            onSearch={handleSearch}
            options={options}
            onFocus={hanldeSuggestions}
            placeholder={placeHolder}
            onChange={(selected) => {
              !stopNavigation && selected[0] && handleClick(selected[0]);
              onChange && onChange(selected[0]);
            }}
            renderMenu={(results, menuProps, props) => (
              <Menu {...menuProps}>
                {results.map((result, idx) => {
                  const res = nestedLabelKey
                    ? getNestedValue(result, label) || result["phone"]
                    : result[label] || result["phone"];
                  if (res) {
                    return (
                      <MenuItem key={idx} option={result} position={idx}>
                        {thumbnailImage(result)}
                        <Highlighter search={props.text}>{res}</Highlighter>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            )}
          />
          {!disabled && (
            <div className="searchbarIcon">
              <span class="material-icons-outlined">search</span>
            </div>
          )}
        </div>
      </>
    );
  };

  return asyncSearch();
}
SearchBox.defaultProps = {
  minLength: 3,
  suggestionList: [],
  hanldeSuggestions: () => {},
};
export default SearchBox;
