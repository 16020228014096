import React, { useEffect, useState } from 'react';
import useApiManager from 'networking/ApiManager';

import {
  CoreButton,
  CoreCheckBox,
  CoreDropDown,
  CoreInputBox,
  CoreTextArea,
  Select2DropDown,
} from 'whealth-core-web/components';
import CommonDayPart from 'whealth-core-web/components/DayPart/CommonDayPart';

function GoalCreationForm(props) {
  const { valueObj, error, setError, isUpdate, setValueObj, onClick, data } = props;

  const [loggingData, setLoggingData] = useState([]);
  const [measuringData, setMeasuringData] = useState([]);
  const [measuringUnit, setMeasuringUnit] = useState({});

  useEffect(() => {
    if (valueObj.day_parts?.length === 0) {
      const defaultDayPart = {
        day_part: '',
        time: '',
      };
      valueObj.day_parts.push(defaultDayPart);
      setValueObj({ ...valueObj });
    }
  }, [valueObj]);

  useEffect(() => {
    if (valueObj?.measuring_event) {
      setMeasuringUnit(valueObj?.measuring_event);
    }
  }, [valueObj?.measuring_event]);

  useEffect(() => {
    if (data.length > 0) {
      dataFilter();
    }
  }, [data]);

  const dataFilter = () => {
    let loggingDataArr = [];
    let measuringDataArr = [];
    data.map((item) => {
      if (item.type == 'logging') {
        loggingDataArr.push(item);
        setLoggingData(loggingDataArr);
      } else {
        measuringDataArr.push(item);
        setMeasuringData(measuringDataArr);
      }
    });
  };

  const typeArr = [
    { title: 'Measuring', id: 'measuring' },
    { title: 'Logging', id: 'logging' },
  ];

  const defaultGoal = [
    { title: 'Exercise', id: 'exercise' },
    { title: 'Diet', id: 'diet' },
    { title: 'Sleep', id: 'sleep' },
    { title: 'Stress', id: 'stress' },
    { title: 'Medicine', id: 'medicine' },
    { title: 'Others', id: 'others' },
  ];

  const typeArray = [
    { title: 'Daily', id: 'daily' },
    { title: 'Weekly', id: 'weekly' },
    { title: 'Monthly', id: 'monthly' },
    { title: 'Yearly', id: 'yearly' },
  ];

  const renderIventsImages = () => {
    let allData = [];
    if (valueObj.type == 'measuring') {
      allData = measuringData;
    } else {
      allData = loggingData;
    }

    return allData.map((item, index) => {
      let activeImageStyle = {};
      if (valueObj.measuring_event_id == item.id || valueObj?.measuring_event?.id == item.id) {
        activeImageStyle = { border: '1px solid var(--themeDarkColor)' };
      }
      return (
        <div key={index}>
          <img
            onClick={() => {
              setMeasuringUnit(item);
              setValueObj({ ...valueObj, measuring_event_id: item.id });
            }}
            className='img-thumbnail'
            style={activeImageStyle}
            src={item.image_url}
          />
          <div className='text-center capitalize font-12px mt-2'>
            {item.title}
            <div>({item.measuring_unit})</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className='measuringForm'>
      <div className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.title || ''}
            placeholder='Goal Name'
            value={valueObj?.title || ''}
            label='Goal Name'
            setValue={(data) => {
              setValueObj({ ...valueObj, title: data });
            }}
            retuired
            msgStyle={{ color: 'red' }}
            maxLength={255}
          />
        </div>

        <div className='w-100'>
          <CoreDropDown
            data={typeArr}
            showMSG={error?.type || ''}
            placeholder='Measure'
            value={valueObj?.type || ''}
            label='Goal Type'
            setValue={(data) => {
              setMeasuringUnit({});
              valueObj.measuring_event_id = '';
              setValueObj({ ...valueObj, type: data, value: '' });
            }}
            retuired
            msgStyle={{ color: 'red' }}
          />
        </div>

        <div className='w-100'>
          <Select2DropDown
            data={defaultGoal}
            showMSG={error?.goal_category || ''}
            placeholder='Goals Category'
            defaultValue={valueObj?.goal_category}
            value={valueObj?.goal_category || ''}
            label='Goals Category'
            onSelect={(data) => {
              setValueObj({ ...valueObj, goal_category: data });
            }}
          />
        </div>
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreTextArea
          showMSG={error?.description}
          placeholder='Description'
          value={valueObj.description || ''}
          label='Description'
          setValue={(data) => {
            setValueObj({ ...valueObj, description: data });
          }}
          rows={3}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <span className='requiredInput'> * </span>Measure Event
      </div>
      <div className='thumbImagesIcons'>{renderIventsImages()}</div>
      {error?.measuring_event_id && (
        <div className='inputMsg' style={{ color: 'red' }}>
          {error?.measuring_event_id}
        </div>
      )}

      <div className='w-100 d-lg-flex' style={{ gap: '15px' }}>
        {valueObj.type == 'measuring' && (
          <div className='w-100 d-flex mt-3'>
            <div className='w-100'>
              <CoreInputBox
                showMSG={error?.value}
                validateNumbers
                // placeholder={measuringUnit.title || ''}
                placeholder='Value'
                value={valueObj?.value || ''}
                label='Target Value'
                setValue={(data) => {
                  setValueObj({ ...valueObj, value: data });
                }}
                maxLength={255}
                msgStyle={{ color: 'red' }}
                retuired
                inputStyle={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
            </div>
            <div className='unitBox mt-4 capitalize'>{measuringUnit.measuring_unit || 'Unit'}</div>
          </div>
        )}

        <div className='w-100 mt-3'>
          <CoreInputBox
            showMSG={error?.code || ''}
            placeholder='Code'
            value={valueObj?.code || ''}
            label='Code'
            setValue={(data) => {
              setValueObj({ ...valueObj, code: data });
            }}
            msgStyle={{ color: 'red' }}
            maxLength={255}
          />
        </div>

        <div className='w-100 mt-3'>
          <CoreDropDown
            data={typeArray}
            showMSG={error?.frequency}
            placeholder='Frequency'
            value={valueObj?.frequency || ''}
            label='Frequency'
            setValue={(data) => {
              setValueObj({ ...valueObj, frequency: data });
            }}
            retuired
            msgStyle={{ color: 'red' }}
          />
        </div>
      </div>
      <CommonDayPart valueObj={valueObj} setValueObj={setValueObj} error={error} setError={setError} />
      <div style={{ margin: '20px 0' }} className='checkboxlabel'>
        <div className='mt-1 d-flex flex-wrap '>
          <CoreCheckBox
            value={valueObj?.medicinal_goal}
            labelStyle={{ margin: '20px 0 8px 6px', fontWeight: 'var(--lightWeight)' }}
            onChange={(data) => {
              setValueObj({ ...valueObj, medicinal_goal: !valueObj.medicinal_goal });
            }}
            id={isUpdate ? 'flexCheckDefault' : 'update'}
            title='Consider as prescription goal'
            htmlFor={isUpdate ? 'flexCheckDefault' : 'update'}
            checked={valueObj?.medicinal_goal}
          />
        </div>
        {error?.medicinal_goal && <div style={{ color: 'red' }}>{error?.medicinal_goal}</div>}
      </div>

      <div style={{ margin: '20px 0' }} className='checkboxlabel'>
        <div className='mt-1 d-flex flex-wrap '>
          <CoreCheckBox
            value={valueObj?.multi_value_support}
            labelStyle={{ margin: '20px 0 8px 6px', fontWeight: 'var(--lightWeight)' }}
            onChange={(data) => {
              setValueObj({ ...valueObj, multi_value_support: !valueObj.multi_value_support });
            }}
            id={isUpdate ? 'flexCheckDefault1' : 'Multiupdate'}
            htmlFor={isUpdate ? 'flexCheckDefault1' : 'Multiupdate'}
            title='Multi Value Support'
            checked={valueObj?.multi_value_support}
          />
        </div>
        {error?.multi_value_support && <div style={{ color: 'red' }}>{error?.multi_value_support}</div>}
      </div>

      <div style={{ margin: '20px 0' }} className='checkboxlabel'>
        <div className='mt-1 d-flex flex-wrap '>
          <CoreCheckBox
            value={valueObj?.is_disabled}
            labelStyle={{ margin: '20px 0 8px 6px', fontWeight: 'var(--lightWeight)' }}
            onChange={(data) => {
              setValueObj({ ...valueObj, is_disabled: !valueObj.is_disabled });
            }}
            id={isUpdate ? 'is_disabled' : 'is_disabled_update'}
            htmlFor={isUpdate ? 'is_disabled' : 'is_disabled_update'}
            title='Disable'
            checked={valueObj?.is_disabled}
          />
        </div>
        {error?.is_disabled && <div style={{ color: 'red' }}>{error?.is_disabled}</div>}
      </div>

      <div style={{ margin: '20px 0' }} className='checkboxlabel'>
        <div className='mt-1 d-flex flex-wrap '>
          <CoreCheckBox
            value={valueObj?.category}
            labelStyle={{ margin: '20px 0 8px 6px', fontWeight: 'var(--lightWeight)' }}
            onChange={(data) => {
              setValueObj({ ...valueObj, category: valueObj?.category ? '' : 'default' });
            }}
            id={isUpdate ? 'set_as_default' : 'set_as_default_update'}
            htmlFor={isUpdate ? 'set_as_default' : 'set_as_default_update'}
            title='Set as Default'
            checked={valueObj?.category == 'default'}
          />
        </div>
        {error?.category && <div style={{ color: 'red' }}>{error?.category}</div>}
      </div>

      <div className='w-100' style={{ margin: '20px 0' }}>
        <Select2DropDown
          label='input Type'
          data={[{ id: 'radio', title: 'Radio' }]}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='id'
          showMSG={error?.input_type || ''}
          placeholder='Input Type'
          value={valueObj?.input_type || ''}
          defaultValue={valueObj?.input_type}
          onSelect={(data) => {
            valueObj.input_type = data;
            setValueObj({ ...valueObj });
          }}
          msgStyle={{ color: 'red' }}
        />
      </div>

      <div className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100'>
          <CoreInputBox
            placeholder='Title'
            value={valueObj?.extra_details?.value || ''}
            label='Input Title'
            setValue={(data) => {
              valueObj.extra_details.value = data;
              setValueObj({ ...valueObj });
            }}
          />
        </div>
        {valueObj.multi_value_support && (
          <div className='w-100'>
            <CoreInputBox
              placeholder='Title'
              value={valueObj?.extra_details?.value2 || ''}
              label='Input Title2'
              setValue={(data) => {
                valueObj.extra_details.value2 = data;
                setValueObj({ ...valueObj });
              }}
            />
          </div>
        )}
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.input_value_counter}
            validatedecimal
            placeholder='Input Value Counter'
            value={valueObj?.input_value_counter || ''}
            label='Input Value Counter'
            setValue={(data) => {
              valueObj.input_value_counter = data;
              setValueObj({ ...valueObj });
            }}
          />
        </div>
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreTextArea
          showMSG={error?.notification_template}
          value={valueObj?.notification_template || ''}
          label='Notification Template'
          placeholder='Message is sent after enabling the goal on user profile'
          setValue={(data) => {
            setValueObj({ ...valueObj, notification_template: data });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          rows={3}
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreTextArea
          showMSG={error?.reminder_notification_template}
          placeholder='Reminder is sent everyday at 5pm for all the goals assigned to patients'
          value={valueObj?.reminder_notification_template || ''}
          label='Reminder Notification '
          setValue={(data) => {
            setValueObj({ ...valueObj, reminder_notification_template: data });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          rows={3}
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.note}
          placeholder='Note'
          value={valueObj?.note || ''}
          label='Note'
          setValue={(data) => {
            setValueObj({ ...valueObj, note: data });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>

      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default GoalCreationForm;
