import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { editTheme, search, trashRed } from 'res/images';
import { Modal } from 'rsuite';
import { MedicineForm } from 'whealth-core-web/forms';
import CommonBorderBox from './CommonBorderBox';
import { dateFormat, timeFormat, teamRoles } from './Helper';
import Pagination from './Pagination';
import CoreInputBox from './CoreInputBox';
import EmptyView from './EmptyView';
import CommonToolTip from './CommonToolTip';
import CoreButton from './CoreButton';

function Medicine(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [data, setData] = useState({
    dosage: '000',
    is_optional: false,
    meal_duration_unit: 'hour',
    duration: 0,
    status: 'published',
  });
  const [updateData, setupdateData] = useState('');
  const [error, seterror] = useState('');
  const [listData, setListData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchInput, setSearchInput] = useState('');

  let timeOut;

  useEffect(() => {
    if (!searchInput || searchInput?.trim()?.length == 0) {
      getedicineList();
    }
  }, [currentPage, searchInput?.trim()?.length == 0]);

  useEffect(() => {
    timeOut = setTimeout(() => {
      if (searchInput?.trim()?.length > 2) {
        searchMedicines();
        setTotalPages(1);
      }
    }, 300);
  }, [searchInput]);

  const searchMedicines = () => {
    setIsLoading(true);
    ApiClient.searchMedicinesName(searchInput)
      .then((res) => {
        setIsLoading(false);
        setListData(res.data);
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const createMedicines = () => {
    seterror([]);
    setIsLoading(true);
    ApiClient.createMedicines(data)
      .then((res) => {
        setIsLoading(false);
        setCurrentPage(1);
        getedicineList();
        setData({});
        console.log(res);
        setAlertType('alert-success');
        setIsShowAlert('Medicine Created Successfully.');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        if (err.response.data.detail) {
          setIsShowAlert(err.response.data.detail);
        } else {
          seterror(err.response.data.errors);
        }
        console.log(err);
      });
  };

  const createMedicinesApprove = (item) => {
    seterror([]);
    setIsLoading(true);
    ApiClient.createMedicinesApprove(item.id)
      .then((res) => {
        setIsLoading(false);
        getedicineList();
        setAlertType('alert-success');
        setIsShowAlert('Medicine Approved Successfully.');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        if (err.response.data.detail) {
          setIsShowAlert(err.response.data.detail);
        } else {
          seterror(err.response.data.errors);
        }
        console.log(err);
      });
  };

  const createMedicinesUnApprove = (item) => {
    seterror([]);
    setIsLoading(true);
    ApiClient.createMedicinesUnApprove(item.id)
      .then((res) => {
        setIsLoading(false);
        getedicineList();
        setAlertType('alert-success');
        setIsShowAlert('Medicine Unapproved Successfully.');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        if (err.response.data.detail) {
          setIsShowAlert(err.response.data.detail);
        } else {
          seterror(err.response.data.errors);
        }
        console.log(err);
      });
  };

  const getedicineList = () => {
    setIsLoading(true);
    const pageQry = { page: currentPage };
    ApiClient.getMedicinesSetting(pageQry)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setListData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const updateMedicines = () => {
    ApiClient.updateMedicines(updateData.id, updateData)
      .then((res) => {
        setAlertType('alert-success');
        setIsShowAlert('Medicine Updated Successfully.');
        getedicineList();
        handleModalClose();
      })
      .catch((err) => {
        setAlertType('alert-danger');
        if (err.response?.data?.detail) {
          setIsShowAlert(err.response.data.detail);
          setOpen(false);
        } else {
          seterror(err.response.data.errors);
        }
        console.log(err.response.data);
      });
  };

  const deleteData = (id) => {
    seterror([]);
    const windowConfirm = window.confirm('Are you sure you want to delete ?');
    if (windowConfirm) {
      ApiClient.deleteMedicines(id)
        .then((res) => {
          setAlertType('alert-success');
          setIsShowAlert(res.data.message);
          getedicineList();
        })
        .catch((err) => {
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail || err.response.data.message);
          console.log(err);
        });
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setData({});
    setupdateData({});
    seterror('');
  };

  const updateDataModalOpen = (data) => {
    setOpen(true);
    data.medicine_category_title = data.medicine_category;
    setupdateData(data);
    seterror('');
  };

  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {renderMedicineForm(updateData, setupdateData, true)}
        </Modal>
      </div>
    );
  };

  const renderMedicineForm = (data, setData, isUpdate) => {
    return (
      <div className='commonBorderBox settingFormOutline mb-4'>
        <MedicineForm
          error={error}
          valueObj={data}
          setValueObj={setData}
          isUpdate={isUpdate}
          onClick={isUpdate ? updateMedicines : createMedicines}
        />
      </div>
    );
  };

  const listDataMap = () => {
    return listData.map((item, index) => {
      return (
        <tr key={index} className='my-3 font-12px'>
          <table className='table'>
            <tr>
              <td colSpan={2}>
                <div className='d-flex mb-1 align-items-center justify-content-between'>
                  <div className='align-items-flex-start'>
                    <div className='medicinename'> {item.medicine_title_repr}</div>
                    <div className='badge capitalize lightGrayBadge mt-2'> {item.status}</div>
                  </div>
                  <div
                    className='d-flex text-muted justify-content-between align-items-center medicineDataList'
                    style={{ gap: '10px' }}
                  >
                    <div className='capitalize'>
                      Created by · {item?.admin?.full_name} ({teamRoles(item?.admin?.role)})
                    </div>
                    <div>
                      Created at · {dateFormat(item.created_at)} . {timeFormat(item.created_at)}
                    </div>
                    <div className='action-images d-flex'>
                      {item.status == 'draft' && (
                        <div
                          className='pointer'
                          style={{ color: 'green' }}
                          onClick={() => createMedicinesApprove(item)}
                        >
                          <CommonToolTip
                            placement={'top'}
                            item={<span class='material-icons-outlined'>done</span>}
                            showValue={'Approve'}
                          />
                        </div>
                      )}
                      {item.status == 'draft' && (
                        <div
                          className='pointer'
                          style={{ color: 'red' }}
                          onClick={() => createMedicinesUnApprove(item)}
                        >
                          <CommonToolTip
                            placement={'top'}
                            item={<span class='material-icons-outlined'>close</span>}
                            showValue={'Disapprove'}
                          />
                        </div>
                      )}

                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              updateDataModalOpen(item);
                            }}
                            src={editTheme}
                          />
                        }
                        showValue={'Edit'}
                      />

                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              deleteData(item.id);
                            }}
                            src={trashRed}
                          />
                        }
                        showValue={'Delete'}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className='medicinesCategoryInfo'>
                {(item.medicine_category || item.info) && (
                  <div className='medicinesCategoryInfo d-flex'>
                    <div className='text-nowrap me-3'>
                      <strong>{item.medicine_category}</strong>
                    </div>
                    <div className='text-muted flex-wrap'>{item.info}</div>
                  </div>
                )}
              </td>
            </tr>
          </table>
        </tr>
      );
    });
  };

  const showMedicinesList = () => {
    return (
      <>
        {/* <div className='w-20'>
          <CoreButton title='Disapprove' customDivStyle={{ width: '10%' }} />
        </div> */}
        {listDataMap()}
      </>
    );
  };

  const patination = () => {
    return (
      <Pagination
        seterror={seterror}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };

  const handleChange = async (text) => {
    clearTimeout(timeOut);
    setSearchInput(text);
  };

  const renderSearchInput = () => {
    return (
      <div className='mb-3'>
        <CoreInputBox
          placeholder='Search Medicine By Category Or Name'
          value={searchInput}
          setValue={handleChange}
          leftIcon={search}
        />
      </div>
    );
  };

  const renderEmptyView = () => {
    return <EmptyView title='No data found' />;
  };

  return (
    <div className='medicine-form'>
      <CommonBorderBox>
        {renderSearchInput()}

        {renderMedicineForm(data, setData)}
      </CommonBorderBox>
      <div className='table-responsive'>
        <table className='w-100'>{listData?.length > 0 ? showMedicinesList() : renderEmptyView()}</table>
      </div>

      <div className='d-flex justify-content-end mt-3'>{patination()}</div>
      {updateModal()}
    </div>
  );
}

export default Medicine;
