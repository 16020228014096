import React from 'react';
import { bluemedicine } from 'res/images';
import { EmptyView } from 'whealth-core-web/components';

function MedicineActiveCard(props) {
  const { medicineData } = props;
  const renderMedicineName = (item) => {
    return (
      <div className='d-flex checkup-data-part'>
        {item?.medicine && (
          <div className='checkup-entry w-100 d-flex  align-items-center'>
            <div className='checkupIcon'>
              <img src={bluemedicine} />
            </div>
            <div className='ms-4 capitalize'>{item?.medicine?.medicine_title_repr}</div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className='d-md-flex mb-3 bodyCard'>
      <div className='card w-100 no-border'>
        <div className='card-body'>
          <div className='d-flex justify-content-between border-bottom pb-2 mb-2'>
            <div className='checkupHead'>Prescribed Medicine </div>
          </div>
          <div className='d-flex checkup-data-part'>
            <div className='checkup-data d-flex flex-column w-100'>
              {medicineData?.length > 0 ? (
                medicineData?.map((item) => {
                  return renderMedicineName(item);
                })
              ) : (
                <EmptyView title='No Published Prescription' />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicineActiveCard;
