import React, { useEffect, useState } from 'react';
import { closeIcon } from 'res/images';
import {
  CommonAlert,
  CoreCheckBox,
  CoreDropDown,
  CoreInputBox,
  Loader,
  Pagination,
  ReadMoreReadLess,
} from 'whealth-core-web/components';
import './filterModal.scss';
import { clickOnElmOnBodyClick } from './Helper';
import labels from '../../inputFields.json';
import './ManageInventoryActionModal.scss';
import { Select } from '@mui/material';
import { Checkbox } from 'rsuite';
import { binIcon } from 'res/images';
import useApiManager from 'networking/ApiManager';
import { is } from 'date-fns/locale';
import { set } from 'date-fns';
import { formulationData } from 'whealth-core-web/components/Helper';

function ManageInventoryActionModal(props) {
  const {
    getData,
    data,
    isAddAction,
    selectedRowDetails,
    setIsSuccess,
    setToastMsg,
    isItPostReq,
    setIsInventoryActionModalOpen,
    setIsError,
  } = props;
  const ApiClient = useApiManager();
  const [filterData, setFilterData] = useState({});
  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  const [primaryCheckbox, setPrimaryCheckbox] = useState(false);
  const [outOfStockCheckbox, setOutOfStockCheckbox] = useState(false);
  const [getFormulationData, setGetFormulationData] = useState([]);
  const [formulation, setFormulation] = useState(isItPostReq ? '' : selectedRowDetails?.formulation);
  const [selectedStatus, setSelectedStatus] = useState(isItPostReq ? '' : selectedRowDetails?.status);
  const [selectedVariation, setSelectedVariation] = useState(
    isItPostReq ? '' : selectedRowDetails?.product?.line_items[0]?.variation?.variation
  );
  const [mrp, setMrp] = useState(isItPostReq ? '' : selectedRowDetails?.product?.line_items[0]?.mrp);
  const [medicineName, setMedicineName] = useState(isItPostReq ? '' : selectedRowDetails?.name);
  const [manufacturerName, setManufacturerName] = useState(
    isItPostReq ? '' : selectedRowDetails?.product?.line_items[0]?.manufacturer?.name
  );
  const initialState = {
    name: '',
    formulation: '',
    status: 'Published',
    product: {
      line_items: [
        {
          id: '',
          sku: '',
          variation: { id: '', name: '', variation: '', type: '' },
          manufacturer: { id: 1, name: 'ABC' },
          quantity_available: 0,
          price: '',
          mrp: '',
          active: false,
          oos: false,
        },
      ],
    },
  };
  const [formData, setFormData] = useState(initialState);
  const [addNewMedicineErrorMsg, setAddNewMedicineErrorMsg] = useState({});
  const [errors, setErrors] = useState({});
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-danger');

  useEffect(() => {
    if (selectedRowDetails && selectedRowDetails?.formulation) {
      getFormulation(formData?.formulation || selectedRowDetails?.formulation);
    }
  }, []);

  useEffect(() => {
    if (formData?.formulation !== '') {
      getFormulation(formData?.formulation || selectedRowDetails?.formulation);
    }
  }, [formData?.formulation]);

  useEffect(() => {
    if (selectedRowDetails && !isItPostReq) {
      setFormData({
        id: selectedRowDetails.id,
        name: selectedRowDetails?.name || '',
        formulation: selectedRowDetails?.formulation || '',
        product: {
          line_items: [
            {
              ...selectedRowDetails.selectedLineItem,
              variation: selectedRowDetails.selectedLineItem?.variation || {
                id: '',
                name: '',
                variation: '',
                type: '',
              },
              manufacturer: selectedRowDetails.selectedLineItem?.manufacturer || { id: 1, name: 'ABC' },
              quantity_available: selectedRowDetails.selectedLineItem?.quantity_available || 0,
              price: selectedRowDetails.selectedLineItem?.price || '',
              mrp: selectedRowDetails.selectedLineItem?.mrp || '',
              active: selectedRowDetails.selectedLineItem?.active || false,
              oos: selectedRowDetails.selectedLineItem?.oos || false,
            },
          ],
        },
      });
    } else if (isItPostReq && !selectedRowDetails) {
      setFormData(initialState);
    }
  }, [selectedRowDetails, isItPostReq]);

  const getFilterData = (data) => {
    setFilterData({ ...filterData, ...data });
  };

  const getFormulation = (formulationType) => {
    ApiClient.getFormulation(formulationType)
      .then((res) => {
        const modifiedRes = res.data.map((item) => ({
          ...item,
          name: `${item.name}, ${item.variation}, ${item.type}`,
        }));
        setGetFormulationData(modifiedRes);
      })
      .catch((err) => {
        console.log('getFormulation error: ', err);
      });
  };

  const postInventoryMedicines = async (data, index) => {
    ApiClient.postInventoryMedicines(formData)
      .then(() => {
        setIsSuccess(true);
        setIsError(false);

        const firstVariation = formData?.product?.line_items[0]?.variation;
        const additionalVariations = formData?.product?.line_items.length - 1;

        let toastMessage = `${formData?.name} - ${firstVariation?.name}, ${firstVariation?.variation}, ${firstVariation?.type} added successfully.`;

        if (additionalVariations > 0) {
          toastMessage += ` +${additionalVariations} more variation${additionalVariations > 1 ? 's' : ''}`;
        }

        setToastMsg(toastMessage);
        setFormData();
        setIsInventoryActionModalOpen(false);
      })
      .catch((error) => {
        setIsSuccess(false);
        setIsError(true);
        console.error('Error adding medicine:', error);
        setIsInventoryActionModalOpen(false);
        if (error?.response?.data?.non_field_errors) {
        const errorMessage = error.response.data.non_field_errors[0];
        setIsShowAlert(true);
        setAlertType('alert-danger');
        setToastMsg(errorMessage);
      } else if (error?.response?.data?.errors) {
        setErrors(error.response.data.errors);
        setToastMsg('An error occurred. Please check the form for details.');
      } else if (error.response && (error.response.status === 400 || error.response.status === 422)) {
        setToastMsg('Medicine with same name and formulation already exists');
      } else {
          setToastMsg('An unexpected error occurred.');
        }
      });
  };

  const updateInventoryMedicines = async (id, data) => {
    ApiClient.updateInventoryMedicines(formData.id, formData)
      .then(() => {
        const lineItem = formData?.product?.line_items[0];
        setIsSuccess(true);
        setIsError(false);
        setToastMsg(
          `${formData?.name} ${lineItem?.variation?.name}, ${lineItem?.variation?.variation}, ${lineItem?.variation?.type} - ${lineItem?.sku} updated successfully`
        );
        setIsInventoryActionModalOpen(false);
      })
      .catch((error) => {
        setIsSuccess(false);
        setIsError(true);
        console.error('Error updating medicine:', error);
        setIsInventoryActionModalOpen(false);
        setToastMsg(`Error: ${error}`);
        if (error?.response?.data?.non_field_errors) {
          const errorMessage = error.response.data.non_field_errors[0];
          setIsShowAlert(true);
          setAlertType('alert-danger');
          setToastMsg(errorMessage);
        } else if (error?.response?.data?.errors) {
          setErrors(error.response.data.errors);
          setToastMsg('An error occurred. Please check the form for details.');
        } else {
          setToastMsg('An unexpected error occurred.');
        }
      });
  };

  const handleStatusChange = (value, index) => {
    setFormData((prevData) => ({
      ...prevData,
      product: {
        ...prevData.product,
        line_items: prevData.product.line_items.map((item, i) => (i === index ? { ...item, status: value } : item)),
      },
    }));
  };

  const handleInputChange = (field, value, index) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (field.includes('product.line_items')) {
        const [, , nestedField, subField] = field.split('.');
        if (nestedField === 'manufacturer' && subField === 'name') {
          newData.product.line_items[index].manufacturer = {
            ...newData.product.line_items[index].manufacturer,
            name: value,
          };
        } else if (nestedField === 'variation') {
          newData.product.line_items[index].variation = {
            ...newData.product.line_items[index].variation,
            [subField]: value,
          };
        } else {
          newData.product.line_items[index][nestedField] = value;
        }
      } else {
        newData[field] = value;
      }
      return newData;
    });
  };

  const handleVariationChange = (index, value) => {
    const selectedVariation = getFormulationData.find((item) => item.id == value);
    if (selectedVariation) {
      setFormData((prevData) => ({
        ...prevData,
        product: {
          ...prevData.product,
          line_items: prevData.product.line_items.map((item, i) =>
            i === index
              ? {
                  ...item,
                  variation: {
                    id: selectedVariation.id,
                    name: selectedVariation.name.split(',')[0],
                    variation: selectedVariation.variation,
                    type: selectedVariation.type,
                  },
                  mrp: selectedVariation.mrp,
                  active: selectedVariation.active,
                  oos: selectedVariation.oos,
                }
              : item
          ),
        },
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let hasError = false;

      formData.product.line_items.forEach((item, index) => {
        if (!item.variation.id) {
          setAddNewMedicineErrorMsg((prev) => ({ 
            ...prev, 
            [`variation_${index}`]: 'Select a Variation to continue.' 
          }));
          hasError = true;
        }

        if (!item.mrp || parseFloat(item.mrp) <= 0) {
          setAddNewMedicineErrorMsg((prev) => ({ 
            ...prev, 
            [`mrp_${index}`]: 'Please enter a valid MRP.' 
          }));
          hasError = true;
      }
    });

    if (!formData.name) {
      setAddNewMedicineErrorMsg((prev) => ({ ...prev, medicineName: 'Enter Medicine Name to continue.' }));
      hasError = true;
    }

    if (!formData.formulation) {
      setAddNewMedicineErrorMsg((prev) => ({ ...prev, formulation: 'Enter a Formulation to continue.' }));
      hasError = true;
    }

    if (!hasError) {
      if (isAddAction) {
        postInventoryMedicines(formData);
      } else {
        updateInventoryMedicines(formData?.id, formData);
      }
    }
  };

  const handleCheckboxChange = (field, index) => {
    setFormData((prevData) => ({
      ...prevData,
      product: {
        ...prevData.product,
        line_items: prevData.product.line_items.map((item, i) =>
          i === index ? { ...item, [field]: !item[field] } : item
        ),
      },
    }));
  };

  const filterHeader = () => {
    return (
      <div className='filterHeader' style={!isAddAction ? { marginTop: '-100px' } : {}}>
        {isShowAlert && (
          <div className='mt-2'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        <div>{isAddAction ? 'Add New Medicine' : 'Edit Medicine'}</div>
        <div
          className='closeIconStyle'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
          onClick={() => {
            setFormData(initialState);
            setAddNewMedicineErrorMsg({});
            props.onClose(); // Add this line
          }}
        >
          <img src={closeIcon} width={17.78} height={17.78} />
        </div>
      </div>
    );
  };

  const addVariation = () => {
    setFormData((prevData) => ({
      ...prevData,
      product: {
        ...prevData.product,
        line_items: [
          ...prevData.product.line_items,
          {
            sku: '',
            variation: { id: '', name: '', variation: '', type: '' },
            manufacturer: { id: 1, name: 'abc' },
            quantity_available: 0,
            price: '',
            mrp: '',
            status: '',
            active: false,
            oos: false,
          },
        ],
      },
    }));
  };

  const removeVariation = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      product: {
        ...prevData.product,
        line_items: prevData.product.line_items.filter((_, i) => i !== index),
      },
    }));
  };

  const VariationCard = (index) => {
    const variation = formData?.product?.line_items[index];
    const isPrimaryDisabled = formData?.product?.line_items?.some((item, i) => i !== index && item?.active);

    const selectedVariationIds = formData?.product?.line_items
      .filter((_, i) => i !== index)
      .map((item) => item.variation.id);

    const availableFormulations = getFormulationData.filter((item) => !selectedVariationIds.includes(item.id));

    return (
      <div className='variationCard'>
        <div className='variationCardHeader'>
          <div className='variationCardTitle'>Variation {index + 1}</div>
          {formData.product.line_items.length > 1 && (
            <img
              className='deleteIconStyle'
              src={binIcon}
              width={20}
              height={20}
              onClick={() => removeVariation(index)}
            />
          )}
        </div>
        <div className='labelStyle'>
          Variation <span style={{ color: '#AB1315' }}>*</span>
        </div>
        <CoreDropDown
          placeholder='Select Variation'
          selectStyle={{ width: '321px', height: '36px', borderRadius: '5.03px', border: '#D9D9D9 0.75px solid' }}
          retuired={false}
          showMSG={addNewMedicineErrorMsg?.variation}
          data={availableFormulations}
          showKey='name'
          setKey='id'
          value={variation.variation.id || ''}
          setValue={(value) => handleVariationChange(index, value)}
          labelStyle={{ fontWeight: 'var(--lightFont)' }}
          msgStyle={{ color: 'red' }}
        />

        <div className='labelStyle'>
          MRP <span style={{ color: '#AB1315' }}>*</span>
        </div>
        <CoreInputBox
          placeholder='₹ 0.00'
          type='number'
          value={variation?.mrp}
          setValue={(data) => {
            const numericValue = data
              .replace(/[^0-9.]/g, '')
              .replace(/(\..*)\./g, '$1')
              .replace(/^(\d*\.\d{0,2}).*$/, '$1');
            handleInputChange('product.line_items.mrp', numericValue, index);
          }}
          inputStyle={variationInputStyles}
          validateNumbers={variation?.mrp == 'int'}
          validatedecimal={variation?.mrp == 'float'}
          validateZero={variation?.mrp == 'int'}
          msgStyle={{ color: 'red' }}
          showMSG={addNewMedicineErrorMsg[`mrp_${index}`]}
        />
        <div className='d-flex'>
          <CoreCheckBox
            title={'Primary'}
            checked={variation.active}
            onChange={() => handleCheckboxChange('active', index)}
            className='manualCheckox'
            disabled={isPrimaryDisabled && !variation.active}
          />
          <CoreCheckBox
            title={'Out of Stock'}
            checked={variation.oos}
            onChange={() => handleCheckboxChange('oos', index)}
            className='manualCheckox'
          />
        </div>
        <div className='labelStyle'>Manufacturer</div>
        <CoreInputBox
          value={formData?.product?.line_items[0]?.manufacturer?.name}
          setValue={(data) => handleInputChange('manufacturer', data)}
          inputStyle={inputStyles}
          msgStyle={{ color: 'red' }}
          disabled={true}
        />
      </div>
    );
  };

  const AddNewMedicine = () => {
    return (
      <div style={{ marginTop: '10px' }}>
        <div className='labelStyle'>
          Name <span style={{ color: '#AB1315' }}>*</span>
        </div>
        {/* <div className='inputDetailsDivInventory'>{selectedRowDetails?.name}</div> */}
        <CoreInputBox
          retuired={true}
          value={selectedRowDetails?.name || formData?.name}
          setValue={(data) => handleInputChange('name', data)}
          inputStyle={inputStyles}
          msgStyle={{ color: 'red' }}
          showMSG={addNewMedicineErrorMsg?.medicineName}
          disabled={!isItPostReq}
        />
        <div className='labelStyle'>
          Formulation <span style={{ color: '#AB1315' }}>*</span>
        </div>
        <div className=' w-100 mb-4'>
          <CoreDropDown
            selectStyle={{ width: '345px', height: '36px', borderRadius: '5.03px', border: '#D9D9D9 0.75px solid' }}
            value={formData?.formulation || ''}
            data={formulationData()}
            placeholder='Select Formulation'
            valueKey='id'
            retuired
            setValue={(inputVal) => {
              if (inputVal && inputVal != '') {
                setFormData((prevData) => ({
                  ...prevData,
                  formulation: inputVal,
                }));
              } else {
                setFormData((prevData) => ({
                  ...prevData,
                  formulation: '',
                }));
              }
            }}
            msgStyle={{ color: 'red' }}
            showMSG={addNewMedicineErrorMsg?.formulation}
            disabled={!isItPostReq}
          />
        </div>
        {formData?.product?.line_items?.map((_, index) => VariationCard(index))}
        <div className='addAnotherVarDiv'>
          <span className='addAnotherVar' onClick={addVariation}>
            + Add Another Variation
          </span>
        </div>
      </div>
    );
  };

  const EditMedicine = (index) => {
    return (
      <div style={{ marginTop: '-100px' }}>
        <div className='skuDiv'>SKU: {formData?.product?.line_items[index]?.sku}</div>
        <div className='labelStyle mt-4'>
          Name <span style={{ color: '#AB1315' }}>*</span>
        </div>
        <div className='inputDetailsDivInventory disabled'>{formData?.name}</div>
        <div className='labelStyle mt-4'>
          Formulation <span style={{ color: '#AB1315' }}>*</span>
        </div>
        <div className='inputDetailsDivInventory disabled mb-4' style={{ textTransform: 'capitalize' }}>
          {formData?.formulation}
        </div>
        <div className='checkboxContainer ' style={{ marginBottom: '8px', marginTop: '10px', marginLeft: '2px' }}>
          <CoreCheckBox
            checked={formData?.product?.line_items[index]?.active}
            title={'Primary'}
            onChange={() => handleCheckboxChange('active', index)}
            className='manualCheckox'
            style={{ fontWeight: '500' }}
          />
        </div>
        <div className='labelStyle '>Manufacturer</div>
        <div className='inputDetailsDivInventory' disabled style={{ backgroundColor: '#e9ecef' }}>
          {formData?.product?.line_items[index]?.manufacturer?.name}
        </div>
        <div className='labelStyle mt-4'>
          Variation <span style={{ color: '#AB1315' }}>*</span>
        </div>
        <CoreDropDown
          placeholder='Select Variation'
          className='mt-4'
          selectStyle={{ width: '345px', height: '36px', borderRadius: '5.03px', border: '#D9D9D9 0.75px solid' }}
          //label='Department'
          retuired={true}
          showMSG={addNewMedicineErrorMsg?.variation}
          data={getFormulationData}
          showKey='name'
          setKey='id'
          value={formData?.product?.line_items[index]?.variation?.id}
          setValue={(value) => handleVariationChange(index, value)}
          labelStyle={{ fontWeight: 'var(--lightFont)' }}
          msgStyle={{ color: 'red' }}
          disabled={true}
        />
        <div className='labelStyle' style={{ marginTop: '20px' }}>
          MRP <span style={{ color: '#AB1315' }}>*</span>
        </div>
        <CoreInputBox
          placeholder='₹ 0.00'
          type='number'
          value={formData?.product?.line_items[index]?.mrp || ''}
          setValue={(data) => {
            const numericValue = data
              .replace(/[^0-9.]/g, '')
              .replace(/(\..*)\./g, '$1')
              .replace(/^(\d*\.\d{0,2}).*$/, '$1');
            handleInputChange('product.line_items.mrp', numericValue, index);
          }}
          inputStyle={inputStyles}
          validateNumbers={formData?.product?.line_items[index]?.mrp == 'int'}
          validatedecimal={formData?.product?.line_items[index]?.mrp == 'float'}
          msgStyle={{ color: 'red' }}
          showMSG={addNewMedicineErrorMsg[`mrp_${index}`]}
        />
        <div className='checkboxContainer mt-4' style={{ marginBottom: '8px', marginLeft: '2px' }}>
          <CoreCheckBox
            checked={formData?.product?.line_items[index]?.oos}
            title={'Out of Stock'}
            onChange={() => handleCheckboxChange('oos', index)}
            className='manualCheckox'
          />
        </div>
      </div>
    );
  };

  const filterButtons = () => {
    return (
      <div className='footerButtons'>
        <button type='submit' className='button applyBtn'>
          {isAddAction ? 'Add Medicine' : 'Update Medicine'}
        </button>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='renderFooter'>
        <div>{filterButtons()}</div>
      </div>
    );
  };

  const renderModal = (index) => {
    return (
      <div className='filterModal'>
        <div
          className='offcanvas offcanvas-end'
          tabIndex='-1'
          id='offcanvasRight'
          aria-labelledby='offcanvasRightLabel'
        >
          <div className='whiteBackground'>
            {filterHeader()}
            <form onSubmit={handleSubmit}>
              {isAddAction ? AddNewMedicine() : EditMedicine(0)}
              {renderFooter()}
            </form>
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default ManageInventoryActionModal;

const inputStyles = {
  color: '#333333',
  fontSize: '12px',
  border: '0.75px solid #D9D9D9',
  padding: '5px',
  width: '345px',
  height: '36px',
  borderRadius: '5.03px',
  // marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
};

const variationInputStyles = {
  color: '#333333',
  fontSize: '12px',
  border: '0.75px solid #D9D9D9',
  padding: '5px',
  width: '321px',
  height: '36px',
  borderRadius: '5.03px',
  marginBottom: '5px',
  display: 'flex',
  alignItems: 'center',
};
