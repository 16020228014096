import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';

function Deeplink() {
  const ApiClient = useApiManager();
  const [deeplinks, setDeepLink] = useState();

  useEffect(() => {
    deepLinkUrls();
  }, []);

  const deepLinkUrls = () => {
    ApiClient.getDeeplink()
      .then((res) => {
        setDeepLink(res.data);
      })
      .catch((err) => {
        if (err.response.data.detail == 'Not found.') {
          setDeepLink('');
        }
      });
  };

  return (
    <div className='mt-4 mb-4 table-responsive'>
      <h3>Deep Links</h3>
      <table
        className='table '
        cellspacing='5'
        style={{
          borderRight: '0.5px solid #0000002d',
          borderLeft: '0.5px solid #0000002d',
          borderTop: '0.5px solid #0000002d',
          borderRadius: '8px',
          borderCollapse: 'separate',
        }}
      >
        <thead>
          <tr>
            <th>Section</th>
            <th>Deep Link URL</th>
          </tr>
        </thead>
        <tbody>
          {deeplinks?.map((deeplink) => (
            <tr key={deeplink.id}>
              <td className='text-capitalize'>{deeplink.section}</td>
              <td>
                <a href={deeplink.deep_link_url}>{deeplink.deep_link_url}</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Deeplink;
