import React, { useEffect, useMemo, useRef, useState } from 'react';
import { closeIcon } from 'res/images';
import { useDigits } from 'whealth-core-web/hooks';
import CommonToolTip from './CommonToolTip';
import './inputBox.css';

function CoreInputBox(props) {
  const {
    Clear,
    setErrors,
    articleShow,
    disabled,
    value,
    setValue,
    valueType,
    articleVideoCross,
    type,
    leftIcon,
    rightIcon,
    inputClass,
    inputStyle,
    placeholder,
    label,
    maxLength,
    style,
    msgStyle,
    labelStyle,
    showMSG,
    validateZeroToTen,
    validateNumbers,
    validatedecimal,
    retuired,
    details,
    rightIconref,
    validateNumbersMaxLenth,
    allowStirngAndForwardSlash,
    showCode,
    accept,
    validatenumberAfterDecimal,
    handleOnFocus,
    checkMax,
    validateZero,
    allowStirngAndForwardSlashAndSpacialChar,
    showHint,
    min,
    validateString,
    validSpecialChar,
    validateStringSpace,
    showUnits,
  } = props;

  const divRef = useRef();
  const inputref = useRef();

  useEffect(() => {
    inputref.current.style.paddingLeft = `${divRef.current.offsetWidth + 12}px`;
  }, []);

  const onChangeValue = (data) => {
    let val = data.target.value;
    if (type == 'file') {
      val = data.target.files;
    }

    const validInputRegex = /^[A-Za-z0-9\s]*$/;

    if (validSpecialChar && !validInputRegex.test(val)) {
      return; // Invalid input, do nothing
    }

    if (maxLength) {
      val = val?.substring(0, maxLength);
    }
    if (validateZeroToTen) {
      const isValid = /^\d{0,2}$/.test(val) && Number(val) >= 0 && Number(val) <= 10;
      if (!isValid) {
        return;
      }
    }
    if (validateNumbersMaxLenth) {
      const isValid = /^\d{0,5}$/.test(val) && Number(val) >= 0 && Number(val) <= 65535;
      if (!isValid) {
        setErrors('Ensure this value is less than or equal to 65535.');
        return;
      } else {
        setErrors({});
      }
    }

    if (allowStirngAndForwardSlash) {
      const regexPattern = /^[a-zA-Z\/]*$/.test(val);
      if (!regexPattern) {
        return;
      }
    }

    if (validatenumberAfterDecimal) {
      const regex = /^\d*\.?\d{0,1}$/.test(val);
      if (!regex) {
        return;
      }
    }

    if (allowStirngAndForwardSlashAndSpacialChar) {
      const regexPattern = /^[a-zA-Z\-_, '' /]*$/.test(val);
      if (!regexPattern) {
        return;
      }
    }

    if (validateNumbers) {
      val = val.replace(/[^0-9]/g, '');
    }

    if (validatedecimal) {
      val = val.replace(/[^0-9.]/g, '');
      if (isNaN(val)) {
        val = val.replace(/[a-zA-Z,.{.}]*[a-zA-Z0-9,]*$/, '');
      }
    }

    if (validateString) {
      val = val.replace(/[^A-Za-z]/g, '');
    }

    if (validateStringSpace) {
      val = val.replace(/[^A-Za-z\s]/g, '');
    }

    if (validateNumbers || validatedecimal) {
      if (val > checkMax) {
        val = checkMax;
      }
    }

    if (validateZero) {
      val = val.toString();
      if (val.startsWith('0')) {
        val = val.replace('0', '');
      }
    }

    setValue(val);
  };

  const imputPaddingRight = rightIcon ? 'inputPaddingRight' : '';

  let checkinputType = type == 'number' ? '' : type;

  return (
    <>
      <div className='inputFieldHeader'>
        {label ? (
          <label className='inputlabel' style={labelStyle}>
            {retuired && <span className='requiredInput'> * </span>} {label}
          </label>
        ) : (
          <div />
        )}
        <div className='inputDetailsDiv'>{details}</div>
      </div>
      <div className='inputParent'>
        <div ref={divRef} className='d-flex leftIcon' id='leftIcon'>
          {leftIcon && (
            <div>
              <img src={leftIcon} />
            </div>
          )}

          {showCode && <div className='inputShowCountryCode'>+91</div>}
        </div>

        <input
          ref={inputref}
          disabled={disabled}
          onChange={onChangeValue}
          type={checkinputType}
          className={`form-control coreInput ${imputPaddingRight}  ${inputClass} `}
          value={value}
          placeholder={placeholder}
          style={inputStyle}
          accept={accept}
          required={retuired}
          onFocus={handleOnFocus}
          min={min}
        />

        {rightIcon && (
          <div ref={rightIconref} className='rightIcon' id='rightIcon'>
            {closeIcon && !articleShow ? (
              <>
                <CommonToolTip
                  placement={'top'}
                  item={<img style={{ cursor: 'pointer' }} onClick={articleVideoCross} src={rightIcon} />}
                  showValue={Clear ? 'Remove' : 'Clear'}
                />
              </>
            ) : (
              <img src={rightIcon} />
            )}
          </div>
        )}

        {showUnits && <div className='rightIcon'>{showUnits}</div>}
      </div>

      {showMSG && (
        <div className='inputMsg' style={msgStyle}>
          {showMSG}
        </div>
      )}
      {showHint && (
        <div className='mt-2' style={{ color: 'gray' }}>
          {showHint}
        </div>
      )}
    </>
  );
}

CoreInputBox.defaultProps = {
  handleOnFocus: () => {},
};

export default CoreInputBox;
