import { AdminPanelHeader, DashBoardHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { folder, linkIcon, whiteEyeIconClose, whiteEyeIcon } from 'res/images';
import { CommonAlert, CoreInputBox, Loader } from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { profileImage } from 'whealth-core-web/components/Helper';
import { NewArticle } from 'whealth-core-web/forms';

const CreateArticles = () => {
  const ApiClient = useApiManager();
  const [formData, setFormData] = useState({});
  const [apiFormData, setApiFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [status, setStatus] = useState('');
  const [alertError, setAlertError] = useState('');
  const [alertType, setAlertType] = useState('alert-success');

  const { id } = useParams();
  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);
  useEffect(() => {
    setErrors('');
  }, [id]);

  const getSingleData = async () => {
    setIsLoading(true);
    await ApiClient.getSignleArticle(id)
      .then((res) => {
        setStatus(res.data.status);
        let objData = res.data;
        let tagsId = [];
        getTagsId(objData.tags, tagsId);
        objData.tags_id = tagsId;
        setFormData({ ...objData });
        setApiFormData({ ...objData });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getTagsId = (data, setId) => {
    data &&
      data.map((item) => {
        setId.push(item.id);
      });
  };

  useEffect(() => {
    id && getSingleData();
  }, [id]);

  const navigation = useNavigate();

  const createArticles = (status, data) => {
    let articleData = data ?? formData;

    // if (articleData?.content?.replaceAll(/<\/?[^>]+(>|$)/g, "")?.replaceAll(" ", "")?.length == 0) {
    //   setErrors({ content: "This field is required." });
    //   return;
    // }

    let categoryID = articleData.category_id ?? articleData?.category?.id ?? null;
    setIsLoading(true);
    const newFormData = new FormData();
    newFormData.append('tags_id', articleData.tags_id);
    status && newFormData.append('status', status);
    categoryID && newFormData.append('category_id', categoryID);

    articleData.image_url &&
      typeof articleData.image_url != 'string' &&
      newFormData.append('image_url', articleData.image_url);
    articleData.video_url &&
      typeof articleData.video_url != 'string' &&
      newFormData.append('video_url', articleData.video_url);
    newFormData.append('content', articleData.content);
    newFormData.append('title', articleData.title);
    newFormData.append('short_description', articleData.Description);
    newFormData.append('segment_id', articleData.segment);
    newFormData.append('type', articleData.type);
    newFormData.append('youtube_url', articleData.youtubeUrl);
    newFormData.append('video_duration', articleData.time);
    newFormData.append('recommended', articleData.recommended);

    if (id) {
      ApiClient.updateArticles(newFormData, id)
        .then((res) => {
          setIsLoading(false);
          navigation('/articles', { state: { showAlert: 'Article updated successfully' } });
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.response?.status == 500) {
            setAlertError('Internal server Error');
          } else if (err?.response?.data?.detail) {
            setAlertError(err.response.data.detail);
          } else if (err?.response?.status == 413) {
            setAlertError('image size is too large');
          } else {
            if (err?.response?.data?.errors || err?.response?.data?.errors?.segment_id) {
              setErrors(err.response.data.errors || err?.response?.data?.errors.segment_id);
            } else {
              setAlertError(err.response.data);
            }
          }

          setAlertType('alert-danger');
        });
    } else {
      ApiClient.createArticles(newFormData)
        .then((res) => {
          setIsLoading(false);
          console.log(res);
          setAlertError(res.data.message);
          setAlertType('alert-success');
          navigation('/articles', { state: { showAlert: 'Article created successfully' } });
        })
        .catch((err) => {
          if (err?.response?.status == 500) {
            setAlertError('Internal server Error');
          } else if (err?.response?.status == 413) {
            setAlertError('image size is too large');
          } else {
            if (err?.response?.data?.errors) {
              setErrors(err.response.data.errors);
            } else {
              setAlertError(err.response.data);
            }
          }
          setAlertType('alert-danger');
          setIsLoading(false);
        });
    }
  };

  const updateStatus = (status) => {
    ApiClient.updateStatus(id, status)
      .then((res) => {
        console.log(res);
        setAlertError(res.data.message);
        setAlertType('alert-success');
        navigation('/articles', { state: { showAlert: `Article ${status}ed successfully` } });
      })
      .catch((err) => {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        } else {
          setAlertError(err.response.data);
        }
        setAlertType('alert-danger');
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const headerData = [
    {
      title: 'Save Draft',
      icon: folder,
      onClick: () => createArticles(status != 'unpublished' && 'draft'),
      outline: true,
      status: false,
      hide: status != 'draft' && !!id,
    },
    {
      title: 'Save',
      icon: folder,
      outline: true,
      hide: status !== 'live' && status != 'unpublished',
      onClick: () => createArticles(),
    },
    {
      title: 'Unpublish',
      icon: whiteEyeIconClose,
      onClick: () => updateStatus('unpublish'),
      outline: false,
      status: true,
      hide: status != 'live',
    },
    {
      title: 'Publish',
      icon: whiteEyeIcon,
      status: true,
      hide: status != 'unpublished',
      onClick: () => updateStatus('publish'),
    },
  ];

  const leftSideData = [
    { title: id ? 'Update Article' : 'Create Article', style: { color: 'var(--themeDarkColor)' } },
    {
      title: 'Live',
      status: true,
      hide: status != 'live',
      style: {
        color: 'var(--cardTitle)',
        background: '#65D83C',
        cursor: 'unset',
        padding: '4px 10px',
        fontSize: '12px',
        backgroundColor: '#d8f5ce',
        color: ' #336b1f',
        borderRadius: '8px',
      },
    },
  ];

  const showTree = [
    {
      iconClass: 'description',
      title: 'Article',
      link: '/articles',
    },
    {
      icon: profileImage(apiFormData?.gender, apiFormData?.image_url),
      title: apiFormData?.title,
      link: `/articles`,
    },
    {
      iconClass: 'edit',
      title: id ? 'Update Article' : 'New article',
    },
  ];

  const dateText = () => {
    const date = new Date(apiFormData?.updated_at || new Date());
    const dateTime = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    let datee = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    const stringDate = `${datee} ${month} ${year}`;
    const editText = apiFormData?.updated_at ? 'Last Edit . ' + stringDate : dateTime + ' . ' + stringDate;
    return editText;
  };

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a article by title'
        searchMethod='SEARCHARTICLE'
        label='title'
        searchId='Article'
        renderPath='/articles/{source_id}/edit'
        imagePath='image_url'
      />
    );
  }, []);

  const renderAdminPanelHeader = useMemo(() => {
    return <AdminPanelHeader showTree={showTree} iconTitle='description' title='Article' searchBox={renderSearchBox} />;
  }, []);

  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader showText={apiFormData?.updated_at && dateText} leftData={leftSideData} data={headerData} />;
  }, [dateText]);

  const renderNewArticleCreate = useMemo(() => {
    return (
      <NewArticle
        setAlertType={setAlertType}
        setIsShowAlert={setAlertError}
        errors={errors}
        id={id}
        alertError={alertError}
        createArticle={createArticles}
        data={apiFormData}
        getData={(data) => setFormData({ ...data })}
      />
    );
  }, [apiFormData, alertError, errors]);

  const linkField = useMemo(() => {
    let url = window.location.href;
    url = url.substring(0, url.lastIndexOf('/'));
    return <CoreInputBox disabled value={url} inputClass='linkUrlInput' articleShow rightIcon={linkIcon} />;
  }, []);

  const renderCreatePost = () => {
    return (
      <>
        <div className='articleHeader stickyHeader'>
          <div className='headerFiles'>
            {renderAdminPanelHeader}
            {renderDashBoardHeader}
            <CommonAlert
              isShowAlert={alertError}
              alertType={alertType}
              setIsShowAlert={setAlertError}
              className='mt-1'
            />
          </div>
          <div className=''>
            {id && linkField}
            <div className='hrLine linkUrlInputHrLine' />
          </div>
        </div>
        <div className='px-4 wrapperContent'>{renderNewArticleCreate}</div>
      </>
    );
  };

  return (
    <div>
      {/* {sideBar} */}
      {renderCreatePost()}
      <Loader show={isLoading} />
    </div>
  );
};

export default CreateArticles;
