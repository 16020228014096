import useApiManager from 'networking/ApiManager';
import React, { useState } from 'react';
import { CoreButton, CoreTextArea } from 'whealth-core-web/components';
import { TextField } from '@mui/material';
import CommonMaterialUiDatePicker from 'whealth-core-web/components/CommonMaterialUiDatePicker';
import { dateFormatStringSingleDate } from 'whealth-core-web/components/Helper';

function TaskActionForm(props) {
  const [date, setDate] = useState(null);
  const [taskReason, setTaskReason] = useState(null);
  const ApiClient = useApiManager();
  const { setIsShowAlert, setAlertType, itemId, taskApiData, onClose, oldDate, newDate, reason } = props;
  const [errors, setErrors] = useState({});

  const setAlert = (alertMsg, alertType) => {
    setAlertType(alertType);
    setIsShowAlert(alertMsg);
  };

  const TaskRescheduleDate = () => {
    if (!date || !taskReason) {
      setErrors({
        date: !date ? 'Date is required' : '',
        taskReason: !taskReason ? 'Reason is required' : '',
      });
      return;
    }

    const requestBody = {
      taskId: itemId,
      date: date,
      taskReason: taskReason,
    };
    ApiClient.TaskRescheduleDate(requestBody)
      .then((res) => {
        onClose();
        taskApiData();
        setAlert('Task Rescheduled Successfully', 'alert-success');
      })
      .catch((err) => {
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsShowAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        }
      });
  };

  return (
    <>
      <div className='mt-3  w-100'>
        <div>
          <label>
            <span className='requiredInput'> * </span>Select Date
          </label>
        </div>
        {/* <TextField
          disablePast
          className='w-100'
          id='date'
          type='date'
          value={date || ''}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: new Date().toISOString().split('T')[0],
          }}
        /> */}
        <CommonMaterialUiDatePicker
          disablePast
          value={date ? new Date(date) : null}
          onChange={(res) => {
            // dateFormatStringSingleDate(res);
            setDate(dateFormatStringSingleDate(res));
          }}
          placeholder={'yyyy-MM-dd'}
        />
        {errors.date && <div className='inputMsg'>{errors.date}</div>}
      </div>
      <div className='mt-3'>
        <CoreTextArea
          retuired
          labelStyle={{ fontWeight: 'var(--lightWeight)' }}
          label={'Reason for Rescheduling'}
          placeholder={'Add Reason'}
          rows={5}
          value={taskReason || ''}
          setValue={(res) => {
            setTaskReason(res);
          }}
        />
        {errors.taskReason && <div className='inputMsg'>{errors.taskReason}</div>}
      </div>
      <CoreButton onClick={TaskRescheduleDate} title={'Save'} />
    </>
  );
}

export default TaskActionForm;
