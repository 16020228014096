import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CategoryCard = ({ name, value, progress, isSelected, onPress, width }) => {
  const CustomToolTip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#D1DCFF',
        color: '#243B86',
      },
    })
  );

  return (
    <CustomToolTip title={`Completion ${progress}%`} arrow>
      <div
        onClick={onPress}
        style={{
          // border: '1px solid #ddd',
          height: '70px',
          borderRadius: '8px',
          position: 'relative',
          width: width || '150px',
          boxShadow: '0px 0px 4px 4px rgba(228, 231, 255, 0.5)',
          cursor: 'pointer',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            height: '89%',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            border: isSelected ? '1px solid #274396' : 0,
            padding: '10px',
            paddingLeft: '15px',
            backgroundColor: isSelected ? '#D1DCFF' : 'white',
          }}
        >
          <div style={{ fontWeight: '600', color: '#787C80' }}>{name}</div>
          <div>{value}</div>
        </div>

        <div
          style={{
            height: '8px',
            backgroundColor: '#D9D9D9',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: `100%`,
          }}
        >
          <div
            style={{
              height: '8px',
              backgroundColor: '#274396',
              width: `${progress}%`,
              transition: 'width 0.3s ease-in-out',
            }}
          ></div>
        </div>
      </div>
    </CustomToolTip>
  );
};

export default CategoryCard;
