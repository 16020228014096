import React, { useEffect, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { useNavigate, useParams } from 'react-router-dom';
import { CoreButton, Loader, QuestionsList } from '../index';
import { useDispatch } from 'react-redux';
import { setAlerts } from 'redux/Slices';
import { splitArrData, splitArrObjData } from '../Helper';

function AppCaptureTab(props) {
  const { data, selectedIndex } = props;
  const ApiClient = useApiManager();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputDataObj, setinputDataObj] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const addMissingFields = (source) => {
    // Create a shallow copy of the source object to ensure it's extensible
    const updatedSource = { ...source };
    updatedSource['answer'] = updatedSource['latest_answer'];
    updatedSource['profile_question_id'] = updatedSource['id'];
    return updatedSource;
  };

  const getRequestBody = (inputDataArr) => {
    return {
      behaviour_questionnaire_answers: inputDataArr.map((item) => ({
        behaviour_question: item.profile_question_id,
        answer: item.answer,
      })),
    };
  };

  useEffect(() => {
    if (data?.base_lining) {
      let updatedJsonArray = data?.base_lining[selectedIndex]?.questions.map((question) => addMissingFields(question));
      // Set the updated array to the state
      setinputDataObj(updatedJsonArray);
    }
  }, [data, selectedIndex]);

  const submitQuestionAnswers = () => {
    let inputDataArr = inputDataObj.filter((item) => !!item && item);
    setIsLoading(true);

    const promise =
      data?.base_lining[selectedIndex]?.questionnaire_id === null
        ? ApiClient.behaviorQuestionCreate(getRequestBody(inputDataArr), id)
        : ApiClient.updateBehaviorPrescription(
            id,
            data?.base_lining[selectedIndex]?.questionnaire_id,
            getRequestBody(inputDataArr)
          );

    promise
      .then((res) => {
        setIsLoading(false);
        setinputDataObj([]);
        navigate(`/patient/${id}/dashboard`);
        dispatch(
          setAlerts({
            patient: {
              title: 'Patient Questions updated successfully',
              type: 'alert-success',
            },
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setErrors(err.response.data.errors);
      });
  };

  //   const submitQuestionAnswers = (data) => {
  //     let params = modifyParams({ ...data });

  //     setIsLoading(true);
  //     ApiClient.updateBehaviorPrescription(id, pid, params)
  //       .then((res) => {
  //         setIsLoading(false);
  //         setAlertType("alert-success");
  //         setIsShowAlert("Regime Report updated successfully ");
  //         setTimeout(() => {
  //           navigation(`/patient/${id}/dashboard`);
  //         }, 2500);
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         setErrors(err.response.data.errors || err.response.data?.detail);
  //         if (
  //           err.response.data?.errors?.non_field_errors ||
  //           err.response.data?.errors?.details ||
  //           err.response.data?.detail
  //         ) {
  //           setAlertType("alert-danger");
  //           setIsShowAlert(
  //             err.response.data?.detail ||
  //               err.response.data?.errors?.non_field_errors ||
  //               Object.values(err.response.data.errors[0])
  //           );
  //         }
  //       });
  //   };

  const renderProfileQuestin = () => {
    if (data?.base_lining) {
      return (
        <QuestionsList
          data={data?.base_lining[selectedIndex]?.questions}
          inputDataObj={inputDataObj}
          setinputDataObj={setinputDataObj}
          errors={errors}
          hancleSave={submitQuestionAnswers}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className='w-100'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitQuestionAnswers();
        }}>
        {renderProfileQuestin()}
      </form>
      <Loader show={isLoading} />
    </div>
  );
}

export default AppCaptureTab;
