import React from 'react';
import { editTheme, trashRed } from 'res/images';
import { ReadMoreReadLess } from 'whealth-core-web/components';
import { getDateTime, getDate } from 'whealth-core-web/components/Helper';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';

function ShowTaskList(props) {
  const { data, deleteData, setUpdateData } = props;
  const getColorForStatus = (status) => {
    switch (status) {
      case "assigned":
        return { textColor: "#072679", bgColor: "#e9efff" }
      case "overdue":
        return { textColor: "#FE4B4B", bgColor: "#FEE5D7" }
      case "completed":
        return { textColor: "#336b1f", bgColor: "#d8f5ce" }
    }
  }
  const showData = () => {
    return (
      <div className='profileQuestion'>
        {data &&
          data.map((item, index) => {
            const isActive = item.status == 'completed' ? 'badge active' : '';
            let today = new Date()
            today.setHours(0, 0, 0, 0)
            let completionDate = new Date(item?.completion_date)
            completionDate.setHours(23, 59, 59, 999)
            const status = item.status == 'assigned' && completionDate < today ? 'overdue' : item?.status
            const color = getColorForStatus(status)
            console.log(status,color)
            return (
              <>
                <div key={index} className='d-flex justify-content-between boxContent'>
                  <div className='questionDetail' style={{ flexBasis: '48%' }}>
                    <div className='capitalize'>
                      <ReadMoreReadLess limit={150} data={item.content} />
                      <div className='capitalize font-12px light-black'>{item.assigned_to.full_name}</div>
                      <span className={`me-2 badge`} style={{
                        color: color.textColor,
                        background: color.bgColor,
                        border: '1px solid #0000002d',
                        borderRadius: '30px',
                        padding: '3px 8px 3px 3px',
                      }}>{status}</span>
                    </div>
                  </div>

                  <div className='d-flex' style={{ flexBasis: '42%' }}>
                    <div className='userDetails'>
                      <div className='metaDetail'>Created by · {item?.admin.full_name}</div>
                      <div className='metaDetail'>
                        Due <span className='lowercase'> date </span>· {getDateTime(item?.completion_date)}
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setUpdateData(item);
                      }}
                    >
                      {item.status !== 'completed' && (
                        <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                      )}
                    </div>
                    <div
                      onClick={() => {
                        const windowConfirm = window.confirm('Are you sure you want to delete ?');
                        if (windowConfirm) {
                          deleteData && deleteData(item.id);
                        }
                      }}
                    >
                      {item.status !== 'completed' && (
                        <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                      )}
                    </div>
                  </div>
                </div>
                <div className='hrLine' />
              </>
            );
          })}
      </div>
    );
  };

  return <div>{showData()} </div>;
}

export default ShowTaskList;
