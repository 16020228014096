import React, { useState, useRef, useEffect } from 'react';
import { arrowLeft } from 'res/images';

const styles = {
  collapsible: {
    overflow: 'hidden',
    transition: 'height 0.3s ease',
  },
  toggleButton: {
    marginBottom: '10px',
    cursor: 'pointer',
  },
  content: {
    overflow: 'hidden',
    transition: 'height 0.3s ease',
    height: '0',
  },
};

const DietPlanCollapse = ({ headerComponent, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rotation, setRotation] = useState(270);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setHeight(`${contentRef.current.scrollHeight}px`);
      setShouldAnimate(true);
    } else {
      setHeight('0px');
      setShouldAnimate(true);
    }
  }, [isOpen]);

  const containerStyle = {
    height: height,
    overflow: 'hidden',
    transition: shouldAnimate ? 'height 0.3s ease' : 'none',
    borderColor: '#D1DCFF',
    borderWidth: 1,
    borderStyle: 'solid',
  };

  const toggle = () => {
    if (isOpen) {
      setRotation(270);
    } else {
      setRotation(90);
    }
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {headerComponent}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginRight: 5,
            alignItems: 'center',
            alignSelf: 'flex-end',
            marginBottom: 20,
          }}
          onClick={toggle}
        >
          <div style={{ marginRight: 5, color: '#333333', fontSize: 12 }}>
            {isOpen ? 'Hide Details' : 'View Details'}
          </div>
          <div
            style={{
              backgroundColor: '#D1DCFF',
              borderRadius: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              className='cursor'
              src={arrowLeft}
              style={{
                transform: `rotate(${rotation}deg)`,
                transition: 'transform 0.3s ease',
                height: 18,
                width: 18,
              }}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <div style={containerStyle} ref={contentRef}>
          {children}
        </div>
      )}
    </div>
  );
};

export default DietPlanCollapse;
