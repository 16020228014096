import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { add } from 'res/images';
import { CommonAlert } from 'whealth-core-web/components';
import { dateFormat, timeFormat } from 'whealth-core-web/components/Helper';

function RenderTeleConsultation(props) {
  const { newQuestions } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [teleConsultationData, setTeleConsultationData] = useState([]);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-danger');
  const [totalPages, setTotalPages] = useState(1);
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    getConsultationData();
  }, [currentPage]);

  const getConsultationData = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.teleConsultationList(params, id)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setTeleConsultationData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.warn('err', err.response);
        setIsLoading(false);
      });
  };

  return (
    <div>
      {/* <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} /> */}
      <div className='d-flex'>
        <div className='pt-3'>
          <button onClick={newQuestions} type='button' className='btn mt-3 btn-outline-primary'>
            <img src={add} className='icon' /> Add Tele Consultation
          </button>
        </div>
      </div>
      {/* <Loader show={isLoading} /> */}
      {/* <DietplanList
        getPdf={getPdf}
        statusPublish={statusPublish}
        dietplanList={dietplanList}
        dietPlanDelete={dietPlanDelete}
      /> */}
      {teleConsultationData.map((item, index) => {
        return (
          <div class='consultationtabPart' key={index}>
            <div class='d-flex w-100 justify-content-between'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/patient/${item.id}/teleconsultation/detail`, { state: { teleId: id } })}
                class='consultationTital'
              >
                {item?.consultation?.title}
                <span class='badge capitalize enable ms-3'>
                  <label class='form-check-label'>{item?.role}</label>
                </span>
                <div class='consultationDate mt-2'>
                  {dateFormat(item?.consultation?.consult_datetime)} {timeFormat(item?.consultation?.consult_datetime)}
                </div>
                <div class='d-flex w-100 justify-content-between align-items-end'></div>
                <div class='consultationDate mt-2'>
                  Join Room Link : https://critika-videoconf-1200.app.100ms.live/meeting/{item.code}
                </div>
              </div>
              <div className=''>
                <a
                  href={`/patient/${id}/teleconsultation/${item.id}/joinRoom/${item.code}`}
                  type='button'
                  target='_blank'
                  className='btn  btn-outline-primary'
                >
                  Join Room
                </a>
              </div>
            </div>
          </div>
        );
      })}
      <div className='d-flex justify-content-end'>
        {/* <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div> */}
      </div>
    </div>
  );
}

export default RenderTeleConsultation;
