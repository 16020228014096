import React from 'react';
import { DatePicker } from 'rsuite';
import Select2DropDown from '../common/Select2DropDown';
import TagSelectPicker from '../common/TagSelectPicker';
import { dateFormatStringSingleDate, splitArrData, splitArrObjData, titlizeString } from '../Helper';
import { CoreButton, CoreDatePicker, CoreInputBox, CoreTextArea } from '../index';
import inputFields from '../../../inputFields.json';

function QuestionsList(props) {
  let { data, inputDataObj, setinputDataObj, errors, hancleSave } = props;
  let subSourceKeysObj = inputFields?.subSourceQuestion?.keys || {};
  let subSourceKeys = Object.keys(subSourceKeysObj).map((key) => {
    return subSourceKeysObj[key];
  });

  const handleItemSelect = (value, index) => {
    let newObj = { ...inputDataObj[index] };
    newObj.answer = value || null;
    inputDataObj[index] = newObj;
    setinputDataObj([...inputDataObj]);
  };

  const handleMultiSelect = (value, index) => {
    if (value) {
      value = value.join('|');
      let newObj = { ...inputDataObj[index] };
      newObj.answer = value;
      inputDataObj[index] = newObj;
      setinputDataObj([...inputDataObj]);
    }
  };

  const renderSelectOptions = (item, index1) => {
    let type = item.type;
    let { options, question, key } = item;
    let arrData = splitArrObjData(options, '|', 'title');

    if (item && type == 'single_select') {
      return (
        <div className='mb-3 capitalize'>
          <Select2DropDown
            retuired={item?.is_mandatory}
            showMSG={errors[index1]?.answer}
            label={titlizeString(question)}
            data={arrData}
            style={{ width: '100%' }}
            labelKey='title'
            valueKey='value'
            placeholder={titlizeString(key)}
            defaultValue={inputDataObj[index1]?.answer?.toLowerCase() || ''}
            onSelect={(val, dataObj) => {
              handleItemSelect(dataObj.title, index1);
            }}
            msgStyle={{ color: 'red' }}
            value={inputDataObj[index1]?.answer?.toLowerCase() || ''}
          />
        </div>
      );
    } else if (type && type == 'multi_select') {
      let activeData = splitArrData(inputDataObj[index1]?.answer?.toLowerCase(), '|');
      return (
        <div className='mb-3 capitalize'>
          <TagSelectPicker
            showMSG={errors[index1]?.answer}
            label={titlizeString(question)}
            data={arrData}
            style={{ width: '100%' }}
            labelKey='title'
            valueKey='value'
            placeholder={key}
            defaultValue={activeData}
            onSelect={(val, titleVal) => {
              handleMultiSelect(titleVal, index1);
            }}
            msgStyle={{ color: 'red' }}
            value={activeData}
          />
        </div>
      );
    } else {
      return;
    }
  };

  const renderQuestions = (item, index) => {
    if (subSourceKeys.includes(item.key)) {
      return;
    }
    let unitInputstyle = item.unit ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {};
    const today = new Date().toISOString().substr(0, 10);
    if (item.type == 'text') {
      return (
        <div key={index} className='mb-3 capitalize w-100'>
          <CoreTextArea
            retuired={item?.is_mandatory}
            inputStyle={{ height: '36px' }}
            showMSG={errors[index]?.answer}
            setValue={(data) => {
              let questionObj = { profile_question_id: item.profile_question_id, answer: data };
              inputDataObj[index] = questionObj;
              setinputDataObj([...inputDataObj]);
            }}
            value={inputDataObj[index]?.answer}
            label={titlizeString(item.question)}
            placeholder={titlizeString(item.key)}
            rows={2}
          />
        </div>
      );
    } else if (item.type == 'date_time') {
      return (
        <div key={index} className='mb-3 capitalize w-100'>
          <div>
            <label class='inputlabel'>
              {item?.is_mandatory && <span className='requiredInput'> * </span>}
              {titlizeString(item.question)}
            </label>
          </div>
          <div className='w-100'>
            <DatePicker
              format='yyyy-MM-dd HH:mm'
              placeholder='YYYY-MM-DD HH:MM'
              defaultValue={item.answer ? new Date(item.answer) : null}
              onChange={(data) => {
                let questionObj = { profile_question_id: item.profile_question_id, answer: data };
                inputDataObj[index] = questionObj;
                setinputDataObj([...inputDataObj]);
              }}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      );
    } else if (item.type == 'multi_select' || item.type == 'single_select') {
      return <div>{renderSelectOptions(item, index)}</div>;
    } else {
      if (item.type == 'date') {
        let date = new Date(inputDataObj[index]?.answer);

        if (date == 'Invalid Date' || !inputDataObj[index]?.answer) {
          date = null;
        }

        return (
          <div key={index} className='mb-3 capitalize w-100'>
            <CoreDatePicker
              retuired={item?.is_mandatory}
              label={titlizeString(item.question)}
              showMSG={errors[index]?.answer}
              calanderProps={{
                ranges: [
                  {
                    label: 'today',
                    value: new Date(),
                  },
                ],
                value: date,
                onClean: () => {
                  let questionObj = { profile_question_id: item.profile_question_id, answer: null };
                  inputDataObj[index] = questionObj;
                  setinputDataObj([...inputDataObj]);
                },
                preventOverflow: true,
                placeholder: titlizeString(item.key),
                onChange: (data) => {
                  let date = dateFormatStringSingleDate(data);
                  let questionObj = { profile_question_id: item.profile_question_id, answer: date };
                  inputDataObj[index] = questionObj;
                  setinputDataObj([...inputDataObj]);
                },
                placement: 'autoVerticalStart',
                calendarDefaultDate: new Date(),
                onOk: (data) => {
                  let date = dateFormatStringSingleDate(data);
                  let questionObj = { profile_question_id: item.profile_question_id, answer: date };
                  inputDataObj[index] = questionObj;
                  setinputDataObj([...inputDataObj]);
                },
                style: { width: '100%', padding: 0, margin: 0 },
              }}
            />
          </div>
        );
      } else {
        return (
          <div className='d-flex '>
            <div key={index} className='mb-3 capitalize w-100'>
              <CoreInputBox
                retuired={item?.is_mandatory}
                validatenumberAfterDecimal={item.type == 'integer' || item.type == 'float'}
                label={titlizeString(item.question)}
                showMSG={errors && errors[index]?.answer}
                inputStyle={{ height: '36px', textTransform: 'capitalize', ...unitInputstyle }}
                setValue={(data) => {
                  let questionObj = { profile_question_id: inputDataObj[index].profile_question_id, answer: data };
                  inputDataObj[index] = questionObj;
                  setinputDataObj([...inputDataObj]);
                }}
                value={inputDataObj[index]?.answer || ''}
                aria_expanded={false}
                validateNumbers={item.type == 'int'}
                validatedecimal={item.type == 'float'}
                placeholder={titlizeString(item.key)}
                min={today}
              />
            </div>
            {item.unit && (
              <div className='unitBox mt-4' style={{ height: '36px' }}>
                {item.unit}
              </div>
            )}
          </div>
        );
      }
    }
  };

  return (
    <>
      {data.map((item, index) => {
        let unitInputstyle = item.unit ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {};
        return renderQuestions(item, index);
      })}
      {hancleSave ? (
        <>
          <button type='submit' className='d-none' />
          <CoreButton onClick={hancleSave} type='submit' title='Save Details' customDivStyle={{ width: '100%' }} />
        </>
      ) : null}
    </>
  );
}

export default QuestionsList;
