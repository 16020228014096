import React, { useState, useEffect } from 'react';
import { Checkbox } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './orderTypeFilter.scss';

const OrderTypeFilter = ({ filterData, setFilterData, clearFiltersData, setClearFilterData }) => {
  const [selectedOrderType, setSelectedOrderType] = useState('');
  useEffect(() => {
    if(filterData.orderTypes === '') {
      setSelectedOrderType('');
      setFilterData(prevData => ({ ...prevData, orderTypes: '' }));
      setClearFilterData(false);
    }
    if (filterData.orderTypes) {
      setSelectedOrderType(filterData.orderTypes);
    }
  }, [filterData]);

  useEffect(() => {
    if (clearFiltersData) {
      setSelectedOrderType('');
      setFilterData(prevData => ({ ...prevData, orderTypes: '' }));
      setClearFilterData(false);
    }
  }, [clearFiltersData]);

  const handleOrderTypeChange = (value) => {
    const newValue = selectedOrderType === value ? '' : value;
    setSelectedOrderType(newValue);
    setFilterData({ ...filterData, orderTypes: newValue });
  };

  const orderStyle = {
    fontWeight: '600',
    fontSize: '14px',
    color: '#243B86'
  };

  return (
    <div className='orderTypeFilter'>
      <div className='filterHeading' style={orderStyle}>By Order Type</div>
      <div className='checkboxContainer'>
        <Checkbox
          checked={selectedOrderType === 'Manual'}
          onChange={() => handleOrderTypeChange('Manual')}
          className='manualCheckox'
        >
          Manual
        </Checkbox>
        <Checkbox
          checked={selectedOrderType === 'App'}
          onChange={() => handleOrderTypeChange('App')}
        >
          App
        </Checkbox>
      </div>
    </div>
  );
};

export default OrderTypeFilter;
