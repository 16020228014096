import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import Pagination from '../Pagination';
import CommonBorderBox from '../CommonBorderBox';
import useApiManager from 'networking/ApiManager';
import CommonAlert from '../CommonAlert';
import TaskCategoryForm from './TaskCategoryForm';
import TaskCategoryList from './TaskCategoryList';
import CoreInputBox from '../CoreInputBox';
import { search } from 'res/images';

function TaskCategory(props) {
  const ApiClient = useApiManager();
  const { setIsLoading, setIsShowAlert, setAlertType } = props;
  const [isEditMode, setEditMode] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [taskCategory, setTaskCategory] = useState({
    category_name: '',
    category_color: '',
    task_priority: '',
  });
  const [editTaskCategory, setEditTaskCategory] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [regimeTemplateList, setRegimeTemplateList] = useState([]);
  const [searchRegime, setSearchRegime] = useState('');
  const [isUpdateAlert, setIsUpdateAlert] = useState(false);
  const [updateAlertType, setIsUpdateAlertType] = useState('alert-success');

  useEffect(() => {
    if (searchRegime?.length > 2) {
      searchTaskCategory(searchRegime, currentPage);
    } else {
      getTaskCategoryList();
    }
  }, [currentPage]);

  const searchTaskCategory = (searchData, pageNo) => {
    setIsLoading(true);
    setSearchRegime(searchData);
    if (searchData?.length > 2) {
      ApiClient.searchTaskCategoryList({ search_str: searchData, page: pageNo || 1 })
        .then((res) => {
          setTotalPages(res.headers['total-pages']);
          setRegimeTemplateList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      getTaskCategoryList();
    }
  };

  const getTaskCategoryList = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getTaskCategoryList(params)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setRegimeTemplateList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const deleteTaskCategory = (id) => {
    setIsLoading(true);
    ApiClient.deleteTaskCategory(id)
      .then((res) => {
        setIsLoading(false);
        getTaskCategoryList();
        setAlertType('alert-success');
        setIsShowAlert('Task Category Deleted Successfully');
      })
      .catch((err) => {
        console.warn('fdf', err);
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.error);
      });
  };

  const getTaskCategory = (item) => {
    setIsLoading(true);
    ApiClient.getTaskCategory(item.id).then((res) => {
      setEditMode(true);
      let updateTaskData = res.data;
      setEditTaskCategory({
        id: updateTaskData.id,
        category_name: updateTaskData.category_name,
        category_color: updateTaskData.category_color,
        task_priority: updateTaskData.task_priority,
      });
      setIsLoading(false);
    });
  };

  return (
    <div className='mb-3'>
      <div className='mb-4'>
        <CoreInputBox placeholder='Search Task Categories' setValue={searchTaskCategory} leftIcon={search} />
      </div>

      <CommonBorderBox className='settingFormOutline'>
        <div className='formTitle'>Add Task Categories</div>
        <TaskCategoryForm
          questionsList={questionsList}
          taskCategory={taskCategory}
          setTaskCategory={setTaskCategory}
          getTaskCategoryList={getTaskCategoryList}
          setIsShowAlert={setIsShowAlert}
          setAlertType={setAlertType}
          setQuestionsList={setQuestionsList}
        />
      </CommonBorderBox>
      <div className='table-responsive'>
        <TaskCategoryList
          regimeTemplateList={regimeTemplateList}
          editTaskCategory={getTaskCategory}
          deleteTaskCategory={deleteTaskCategory}
          searchTaskCategory={searchTaskCategory}
        />
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Modal
        backdrop='static'
        size='md'
        open={isEditMode}
        onClose={() => {
          setEditMode(false);
        }}
      >
        <Modal.Header>
          <div className='settingFormOutline p-0'>
            <div className='formTitle mb-0'>Edit Task Category</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <CommonAlert
            isShowAlert={isUpdateAlert}
            alertType={updateAlertType}
            setIsShowAlert={setIsUpdateAlert}
            className='mt-0 mb-3'
          />

          <TaskCategoryForm
            key={taskCategory.id}
            questionsList={questionsList}
            taskCategory={editTaskCategory}
            setTaskCategory={setEditTaskCategory}
            getTaskCategoryList={getTaskCategoryList}
            setIsShowAlert={setIsShowAlert}
            setAlertType={setAlertType}
            isEditMode
            setEditMode={setEditMode}
            setQuestionsList={setQuestionsList}
            setIsUpdateAlert={setIsUpdateAlert}
            setIsUpdateAlertType={setIsUpdateAlertType}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TaskCategory;
