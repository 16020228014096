import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  acessToken: '',
  userData: {},
  patientActiveTab: 'timeline',
  updatePatinetTab: { title: 'timeline', update: true },
  patientDetails: {},
  updatePatientDetails: true,
  medicineActiveCard: [],
  doctorData: [],
  alerts: {},
  patientStatus: [],
  medicineActiveCard: [],
  adminStatus: [],
  summaries: [],
  departments: [],
  locations: [],
  reduxReportData: [],
  reduxNotesData: [],
  reduxPrescriptionData: [],
  reduxGoalData: [],
  reduxRegimeData: [],
  allDepartments: [],
  activeTabTeamForm: '',
  selectedRole: '',
  currentConsultationStatus: '',
  currentPaymentStatus: '',
  patientAddressStep1: '',
  orderDetailsStep2: '',
  viewOrderStep3: '',
  patientName: '',
  patientManualDetails: {},
  patientAddress: {},
  manualOrderItems: {},
  manualOrderSummary: {},
  manualOrderId: {},
  manualOrderIdStr: '',
  manualOrder: {},
  latestPrescription: {},
};

export const slice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    acessTokens(state, action) {
      state.acessToken = action.payload;
    },

    updatePatinetTabs(state, action) {
      state.updatePatinetTab = action.payload;
    },

    setAlerts(state, action) {
      state.alerts = action.payload;
    },

    setReduxReportData(state, action) {
      state.reduxReportData = action.payload;
    },
    setReduxNotesData(state, action) {
      state.reduxNotesData = action.payload;
    },

    setReduxPrescriptionData(state, action) {
      state.reduxPrescriptionData = action.payload;
    },
    setReduxGoalData(state, action) {
      state.reduxGoalData = action.payload;
    },

    setReduxRegimeData(state, action) {
      state.reduxRegimeData = action.payload;
    },

    setReduxDietPlanData(state, action) {
      state.reduxRegimeData = action.payload;
    },

    setDoctorData(state, action) {
      state.doctorData = action.payload;
    },
    setMedicineActiveCard(state, action) {
      state.medicineActiveCard = action.payload;
    },

    setPatientActiveTab(state, action) {
      state.patientActiveTab = action.payload;
    },

    setPatientDetails(state, action) {
      state.patientDetails = action.payload;
    },

    updatePatientDetailsApi(state, action) {
      state.updatePatientDetails = !state.updatePatientDetails;
    },

    setUserData(state, action) {
      state.userData = action.payload;
    },

    setPatientStatus(state, action) {
      state.patientStatus = action.payload;
    },
    setMedicineActiveCard(state, action) {
      state.medicineActiveCard = action.payload;
    },
    setAdminStatus(state, action) {
      state.adminStatus = action.payload;
    },
    addSummary(state, action) {
      state.summaries = [...state.summaries, action.payload];
    },
    updateSummaries(state, action) {
      state.summaries = action.payload;
    },
    addDepartment(state, action) {
      state.departments = [...state.departments, action.payload];
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    setAllDepartments(state, action) {
      state.allDepartments = action.payload;
    },
    setActiveTabTeamForm(state, action) {
      state.activeTabTeamForm = action.payload;
    },
    setSelectedRole(state, action) {
      state.selectedRole = action.payload;
    },
    setCurrentConsultationStatus(state, action) {
      state.currentConsultationStatus = action.payload;
    },
    setCurrentPaymentStatus(state, action) {
      state.currentPaymentStatus = action.payload;
    },
    setPatientAddressStep1(state, action) {
      state.patientAddressStep1 = action.payload;
    },
    setOrderDetailsStep2(state, action) {
      state.orderDetailsStep2 = action.payload;
    },
    setViewOrderStep3(state, action) {
      state.viewOrderStep3 = action.payload;
    },
    setManualPatient(state, action) {
      state.patientManualDetails = action.payload;
    },
    setPatientAddress(state, action) {
      state.patientAddress = action.payload;
    },
    setPatientName(state, action) {
      state.patientName = action.payload;
    },
    // setPatientName(state, action) {
    //   state.patientName = action.payload;
    // },
    setManualOrderItems(state, action) {
      state.manualOrderItems = action.payload;
    },

    setManualOrderSummary(state, action) {
      state.manualOrderSummary = action.payload;
    },
    setManualOrderId(state, action) {
      state.manualOrderId = action.payload;
    },
    setManualOrderIdStr(state, action) {
      state.manualOrderIdStr = action.payload;
    },
    setManualOrder(state, action) {
      state.manualOrder = action.payload;
    },
    setLatestPrescription(state, action) {
      state.latestPrescription = action.payload;
    },
    setAdminData(state, action) {
      state.adminData = action.payload;
    },
  },
});

export default slice.reducer;
export const {
  setAdminData,
  setLatestPrescription,
  setPatientAddress,
  setManualPatient,
  setPatientName,
  setPatientAddressStep1,
  setOrderDetailsStep2,
  setViewOrderStep3,
  setAllDepartments,
  setCurrentPaymentStatus,
  acessTokens,
  setLocations,
  updatePatinetTabs,
  setUserData,
  setPatientActiveTab,
  updatePatientDetailsApi,
  setPatientDetails,
  setAlerts,
  setDoctorData,
  setPatientStatus,
  setAdminStatus,
  addSummary,
  setMedicineActiveCard,
  updateSummaries,
  addDepartment,
  setReduxReportData,
  setReduxNotesData,
  setReduxGoalData,
  setReduxPrescriptionData,
  setReduxRegimeData,
  setReduxDietPlanData,
  setActiveTabTeamForm,
  setSelectedRole,
  setCurrentConsultationStatus,
  setManualOrderItems,
  setManualOrderSummary,
  setManualOrderId,
  setManualOrderIdStr,
  setManualOrder,
} = slice.actions;
