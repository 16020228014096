import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { setAlerts } from 'redux/Slices';
import { Button, Modal } from 'rsuite';
import { CoreDropDown, Select2DropDown } from 'whealth-core-web/components';
import labels from '../inputFields.json';

export const EditDepartmentModal = ({ open, handleClose, addDepartment, data }) => {
  const [departments, setDepartments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const ApiClient = useApiManager();
  const patientDetails = useSelector((state) => state?.sliceReducer?.patientDetails);

  const [selectedDept, setSelectedDept] = useState(data?.id);
  const [selectedDoctor, setSelectedDoctor] = useState(data?.admin?.id);
  const [statusData, setStatusData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(data?.status?.id);
  const [selectedMode, setSelectedMode] = useState();
  let modes = labels?.consultantMode?.options || [];

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedDept(data?.department?.id);
    setSelectedMode(data?.consultation_mode);
  }, [data]);

  useEffect(() => {
    if (statusData.find((item) => item?.id == data?.status?.id)) {
      setSelectedStatus(data?.status?.id);
    } else {
      setSelectedStatus();
    }
  }, [statusData]);

  useEffect(() => {
    if (doctors.find((item) => item?.id == data?.admin?.id)) {
      setSelectedDoctor(data?.admin?.id);
    } else {
      setSelectedDoctor();
    }
  }, [doctors]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await ApiClient.getDepartment();
        if (res.status == 200) {
          let updatedDepartments = [];
          res.data?.map((item) => updatedDepartments.push({ ...item, title: item.name }));
          setDepartments(updatedDepartments);
        }
      } catch (e) {
        console.log(e);
        toast.error('Unable to load departments!');
      }
    };
    open && getData();
  }, [open]);

  useEffect(() => {
    departments &&
      departments.map((item) => {
        if (item.id == selectedDept) {
          setStatusData(item?.department_status);
        }
      });
  }, [selectedDept, departments]);

  const handleModeChange = (value) => {
    setSelectedMode(value);
  };

  const handleDeptChange = (value) => {
    departments.map((item) => {
      if (item.id == value) {
        setStatusData(item.department_status);
      }
    });
    setSelectedDept(value);
  };

  useEffect(() => {
    const getDoctors = async () => {
      try {
        const res = await ApiClient.getDoctors('', selectedDept);
        if (res.status == 200) {
          let doctors = [];
          res.data?.map((item) => doctors.push({ ...item, title: item.full_name }));
          setDoctors(doctors);
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
    };
    open && getDoctors();
  }, [selectedDept]);

  const handleDoctorChange = (value) => {
    setSelectedDoctor(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleSave = async () => {
    if (!selectedDept || !selectedDoctor || !selectedStatus) {
      toast.error('Please select all the fields!');
    } else {
      try {
        const formData = new FormData();
        formData.append('selected_department_id', data?.id);
        formData.append('department', selectedDept);
        formData.append('status', selectedStatus);
        formData.append('admin', selectedDoctor);
        formData.append('consultation_mode', selectedMode);
        const res = await ApiClient.updateDepartment(patientDetails?.id, formData);
        if (res.status == 200) {
          dispatch(setAlerts({ patient: { title: 'Department updated successfully.', type: 'alert-success' } }));
          addDepartment();
          handleClose();
        }
      } catch (e) {
        // console.warn(e.response.data.message);
        toast.error(e?.response?.data?.message || e.message);
      }
    }
  };

  const handleCancelClick = () => {
    setSelectedDept(null);
    setSelectedDoctor();
    setSelectedStatus();
    setSelectedMode();
    handleClose();
  };

  return (
    <>
      <ToastContainer />
      <Modal backdrop='static' keyboard={false} open={open} onClose={handleCancelClick}>
        <Modal.Header>
          <Modal.Title>Edit Department</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='d-flex flex-column'>
            <CoreDropDown
              placeholder='Select Department'
              label='Department'
              retuired={true}
              data={departments}
              labelStyle={{ fontWeight: 'var(--lightFont)' }}
              setValue={(data) => {
                handleDeptChange(data);
              }}
              value={selectedDept}
              msgStyle={{ color: 'red' }}
              disabled
            />
            <Select2DropDown
              placement='auto'
              value={selectedDoctor}
              data={doctors}
              labelKey='title'
              label={'Doctor'}
              placeholder={'Doctor'}
              valueKey='id'
              getObjData
              retuired
              onSelect={(_, selectedValue) => {
                handleDoctorChange(selectedValue?.id || '');
              }}
              showMSG={''}
            />
            <Select2DropDown
              placement='auto'
              value={selectedStatus}
              data={statusData}
              labelKey='title'
              label={'Status'}
              placeholder={'Status'}
              valueKey='id'
              getObjData
              retuired
              onSelect={(_, selectedValue) => {
                handleStatusChange(selectedValue?.id || '');
              }}
              showMSG={''}
            />
            <CoreDropDown
              placeholder={labels?.consultantMode?.label || 'Consultant Mode'}
              label={labels?.consultantMode?.label || 'Consultant Mode'}
              className='mt-2'
              retuired={true}
              data={modes}
              labelStyle={{ fontWeight: 'var(--lightFont)' }}
              setValue={(data) => {
                handleModeChange(data);
              }}
              value={selectedMode}
              msgStyle={{ color: 'red' }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSave()} appearance='primary'>
            Save
          </Button>
          <Button onClick={() => handleCancelClick()} appearance='default'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
