import React from 'react';

const TextWithFormatting = ({ text }) => {
  const formattedText = text?.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line?.split('\t').map((segment, i) => (
        <React.Fragment key={i}>
          {segment}
          {i < line?.split('\t').length - 1 && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
        </React.Fragment>
      ))}
      <br />
    </React.Fragment>
  ));

  return <div>{formattedText}</div>;
};

export default TextWithFormatting;
