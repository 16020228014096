import React, { useEffect, useState } from 'react';
import CoreButton from '../CoreButton';
import CoreInputBox from '../CoreInputBox';
import useApiManager from 'networking/ApiManager';
import { useParams } from 'react-router-dom';
import CoreDropDown from '../CoreDropDown';
import Select, { StylesConfig } from 'react-select';
import chroma from 'chroma-js';

function TaskCategoryForm(props) {
  const ApiClient = useApiManager();
  const {
    isEditMode,
    setEditMode,
    getTaskCategoryList,
    taskCategory,
    setTaskCategory,
    setIsUpdateAlert,
    setIsUpdateAlertType,
    setIsShowAlert,
    setAlertType,
    error,
  } = props;

  const [errors, setErrors] = useState({});

  const categoryColor = [
    { title: 'Brown', value: '#C08B5C', id: 'brown' },
    { title: 'Light Blue', value: '#ADD8E6', id: 'light_blue' },
    { title: 'Pink', value: '#FFC0CB', id: 'pink' },
    { title: 'Light Green', value: '#71E36E', id: 'light_green' },
    { title: 'Light Yellow', value: '#DDD745', id: 'light_yellow' },
    { title: 'Purple', value: '#BE82BF', id: 'purple' },
  ];

  const priorityList = [
    { title: 'P-0', id: 'P_0' },
    { title: 'P-1', id: 'P_1' },
    { title: 'P-2', id: 'P_2' },
    { title: 'P-3', id: 'P_3' },
    { title: 'P-4', id: 'P_4' },
    { title: 'P-5', id: 'P_5' },
  ];

  const onsucccess = () => {
    getTaskCategoryList();
    setTaskCategory({
      category_name: '',
      category_color: '',
      task_priority: '',
    });
    setErrors({});
  };

  const setAlert = (alertMsg, alertType) => {
    setAlertType(alertType);
    setIsShowAlert(alertMsg);
  };

  const createTaskCategory = () => {
    const requestBody = {
      id: taskCategory.id,
      category_name: taskCategory.category_name,
      category_color: taskCategory.category_color,
      task_priority: taskCategory.task_priority,
    };
    setErrors({});
    ApiClient.createTaskCategory(requestBody)
      .then((res) => {
        console.log('res', res);
        onsucccess();
        setAlert('Task Category Added Successfully', 'alert-success');
      })
      .catch((err) => {
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsShowAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        }
      });
  };

  const updateTaskCategory = () => {
    const modalBody = document.querySelector('.rs-modal-body');
    if (modalBody) {
      modalBody.scrollTop = 0;
    }
    const requestBody = {
      id: taskCategory.id,
      category_name: taskCategory.category_name,
      category_color: taskCategory.category_color,
      task_priority: taskCategory.task_priority,
    };
    ApiClient.updateTaskCategory(taskCategory.id, requestBody)
      .then((res) => {
        onsucccess();
        setEditMode(false);
        setAlert('Task Category Updated Successfully', 'alert-success');
      })
      .catch((err) => {
        console.warn('err?.response', err?.response);
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsUpdateAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setIsUpdateAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        } else {
          setErrors({});
        }
      });
  };

  const dotWithTitle = (color = 'transparent', title = '') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: `" "`,
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
    ':after': {
      content: `"${title}"`,
      marginLeft: 4,
      color: 'black',
      fontWeight: 'normal',
    },
  });

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.value);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.value
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.value,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? (isSelected ? data.value : color.alpha(0.3).css()) : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles, ...dotWithTitle() }),
    placeholder: (styles) => ({ ...styles, ...dotWithTitle('#ccc') }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...dotWithTitle(data.value, data.title),
    }),
  };

  return (
    <div className='measuringForm'>
      <div className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100'>
          <CoreInputBox
            retuired
            showMSG={errors?.category_name}
            placeholder={'Category Name'}
            value={taskCategory?.category_name || ''}
            label={'Task Category Name'}
            setValue={(data) => setTaskCategory({ ...taskCategory, category_name: data })}
            msgStyle={{ color: 'red' }}
            maxLength={255}
          />
        </div>
        <div className='w-100'>
          <div class='capitalize'>
            <label class='form-check-label mb-1'>
              <span className='requiredInput'>* </span>
              Task Category Color
            </label>
          </div>
          <Select
            placeholder='Select Categories Color'
            options={categoryColor.map((color) => ({
              ...color,
              label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ width: '24%', color: 'black' }}>{color.title}</span>
                  {/* <div
                    style={{
                      backgroundColor: color.value,
                      borderRadius: 2,
                      marginLeft: 8,
                      height: 16,
                      width: 16,
                    }}
                  ></div> */}
                </div>
              ),
              // value: color.value,
            }))}
            styles={colourStyles}
            isSearchable={false}
            onChange={(data) => setTaskCategory({ ...taskCategory, category_color: data.value })}
            value={
              taskCategory?.category_color
                ? categoryColor.find((color) => color.value === taskCategory.category_color)
                : null
            }
          />
          <div>
            <span className='requiredInput'>{errors?.category_color} </span>
          </div>
        </div>
      </div>

      <div className='mt-3'>
        <CoreDropDown
          retuired
          showMSG={errors?.task_priority || ''}
          labelStyle={{ fontWeight: 'var(--lightWeight)' }}
          label={'Task Priority'}
          placeholder={'Priority'}
          // rows={5}
          data={priorityList}
          value={taskCategory?.task_priority || ''}
          setValue={(data) => {
            setTaskCategory((prevTaskCategory) => ({
              ...prevTaskCategory,
              task_priority: data,
            }));
          }}
        />
      </div>

      <CoreButton
        onClick={isEditMode ? updateTaskCategory : createTaskCategory}
        title={isEditMode ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default TaskCategoryForm;
