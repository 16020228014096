import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAllDepartments, setLocations } from 'redux/Slices';
import {
  useAllUserStatusQuery,
  useCreateUserTeamProfileMutation,
  useGetLoginUserDataQuery,
  useUpdateUserTeamProfileMutation,
  useUpdateWorkingHourMutation,
} from 'redux/apiSlice';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { CommonAlert, Loader } from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { TeamForm } from 'whealth-core-web/forms';
import { profileImage } from 'whealth-core-web/components/Helper';

function CreateTeam() {
  const { id } = useParams();
  const navigation = useNavigate();
  const ApiClient = useApiManager();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, serErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [teamUpdateData, setUpdateTamData] = useState('');
  const [alertType, setAlertType] = useState('alert-danger');
  const activeTabTeamForm = useSelector((state) => state?.sliceReducer?.activeTabTeamForm);
  const setSelectedRole = useSelector((state) => state?.sliceReducer?.selectedRole);

  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const [updateUserTeamProfile, result] = useUpdateUserTeamProfileMutation();
  const [createUserTeamProfile, createResult] = useCreateUserTeamProfileMutation();
  const [updateWorkingHour, updateResult] = useUpdateWorkingHourMutation();

  const [isNivanCare, setIsNivanCare] = useState(false);
  const [isClinic, setIsClinic] = useState(false);
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);
  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
        if (item.key == 'departments_clinics' && item.isActive) {
          setIsClinic(item.isActive);
        }
      });
    }
  }, [configData]);

  useEffect(() => {
    if (id) {
      getTeamData();
    }
  }, [id]);

  const getTeamData = () => {
    ApiClient.teamGet(id)
      .then((res) => {
        setUpdateTamData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.getLocations();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: item.id, title: item.name }));
        dispatch(setLocations(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.getDepartment();
      if (res.status == 200) {
        let departmentsData = [];
        res.data.map((item) =>
          departmentsData.push({ id: item.id, title: item.name, statuses: item.department_status })
        );
        dispatch(setAllDepartments(departmentsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const adminStatus = useAllUserStatusQuery({ type: 'team' })?.data?.data;

  useEffect(() => {
    handleResult(result, 'Profile updated successfully');
  }, [result]);

  useEffect(() => {
    handleResult(updateResult, 'Working Hours updated successfully');
  }, [updateResult]);

  useEffect(() => {
    serErrors({});
  }, [id]);

  useEffect(() => {
    handleResult(createResult, 'Profile created successfully');
  }, [createResult]);

  const handleResult = (result, msg) => {
    if (result?.data?.statusText == '' || result?.data?.statusText == 'OK') {
      navigation('/team', { state: { showAlert: msg } });
    } else if (result?.data?.statusText != 'OK') {
      let alertmsg = result?.data?.data?.detail;
      if (result?.data?.status == 413) {
        alertmsg = 'image size is too large';
      } else if (result?.data?.status == 500) {
        alertmsg = 'Internal server Error';
      } else if (result?.data?.status == 403) {
        alertmsg = 'You do not have permission to perform this action.';
      } else if (result?.data?.data?.errors?.locations_id?.length) {
        alertmsg = result?.data?.data?.errors?.locations_id[0];
      } else {
        if (result?.data?.data?.errors) {
          serErrors(result?.data?.data?.errors);
        }
      }
      setAlertType('alert-danger');
      setShowAlert(alertmsg);
    }
    setIsLoading(result.isLoading);
  };

  const createProfile = (formData) => {
    const newFormData = new FormData();
    formData.profile_url && newFormData.append('profile_url', formData.profile_url);
    newFormData.append('email', formData.email || '');
    newFormData.append('full_name', formData.full_name || '');
    newFormData.append('gender', formData.gender || '');
    newFormData.append('intro', formData.intro || '');
    newFormData.append('language', formData.language || '');
    newFormData.append('phone', formData.phone || '');
    newFormData.append('role', formData.role || '');
    newFormData.append('status_uuid', formData.status_uuid || '');
    newFormData.append('specialization', formData.specialization || '');
    newFormData.append('team_lead_id', formData.team_lead?.id || '');
    newFormData.append('meeting_url', formData.meeting_url || '');
    newFormData.append('degree', formData.degree || '');
    newFormData.append('license_number', formData.license_number || '');
    isNivanCare && newFormData.append('department_id', formData.department || '');
    newFormData.append('staff_id', formData.staff_id || '');
    newFormData.append('service_id', formData.service_id || '');
    if (isNivanCare && formData.locations)
      formData.locations?.map((item) => {
        newFormData.append('locations_id', item);
      });
    formData.signature &&
      typeof formData.signature == 'object' &&
      newFormData.append('signature', formData.signature || '');

    if (id) {
      let data = {
        data: newFormData,
        id: id,
        self: userData.id == id,
      };
      updateUserTeamProfile(data);
    } else {
      createUserTeamProfile(newFormData);
    }
  };

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a team member by name or mobile number'
        searchMethod='SEARCHTEAM'
        label='full_name'
        searchId='Team'
        renderPath='/team/{source_id}/edit'
        imagePath='profile_url'
      />
    );
  }, []);

  const showTree = [
    { iconClass: 'people_alt', title: 'Team', link: '/team' },
    {
      icon: profileImage(teamUpdateData?.gender, userDetails?.image),
      title: teamUpdateData?.full_name,
      link: `/team`,
    },
    { iconClass: 'people_alt', title: id ? 'Update Team Member' : 'Add Team Member' },
  ];

  const leftSideData = [{ title: id ? 'Update Profile' : 'Create Profile', style: { color: 'var(--themeDarkColor)' } }];

  const showArticles = useMemo(() => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='people_alt' showTree={showTree} searchBox={renderSearchBox} />
          <DashBoardHeader leftData={leftSideData} />
        </div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        <div className='createTeamBody'>
          {showAlert && (
            <div className='mt-4'>
              <CommonAlert isShowAlert={showAlert} alertType={alertType} setIsShowAlert={setShowAlert} />
            </div>
          )}
          <TeamForm status={adminStatus} errors={errors} onClick={createProfile} id={id} />
        </div>
      </div>
    </>
  ));

  const articleBody = useMemo(() => {
    return showArticles;
  });

  return (
    <div className='wrapper'>
      {articleBody}
      <Loader show={isLoading} />
    </div>
  );
}

export default CreateTeam;
