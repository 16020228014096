import React, { useEffect, useState } from "react";
import { ChipsTagsView } from "./index";

function OrderStatusFilter(props) {
  const { filterData, setFilterData } = props;
  const [activeData, setActiveData] = useState([]);

  useEffect(() => {
    let obj = { order_status: activeData };
    setFilterData(obj);
  }, [activeData, setActiveData]);

  useEffect(() => {
    setActiveData(new Array());
  }, []);

  useEffect(() => {
    const tempActiveData = filterData?.order_status;
    setActiveData(tempActiveData || []);
  }, [filterData]);

  const orderStatus = [
    { title: 'Draft', id: 'draft' },
    { title: 'Pending', id: 'in_progress' },
    { title: 'Partially Ordered', id: 'partially_confirmed' },
    { title: 'Order Placed', id: 'confirmed' },
    // { title: 'Order Delivered', id: 'orderDelivered' },
    { title: 'Cancelled', id: 'cancelled' },
    // { title: 'Refunded', id: 'refunded' },
  ];

  const renderHeading = () => {
    const orderStyle = {
      fontWeight: '600',
      fontSize: '14px',
      color: '#243B86'
    };
    return <div className="filterHeadings mt-2" style={orderStyle}>By Status</div>;
  };

  const renderChips = () => {
    return (
      <div>
        <div className="mt-0" />
        <ChipsTagsView singleChips={false} setActiveTags={setActiveData} activeTags={activeData} data={orderStatus} />
      </div>
    );
  };

  return (
    <div className="mb-3">
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default OrderStatusFilter;
