import React, { useState } from 'react';
import { editTheme, search, trashRed } from 'res/images';

// import { dateFormat, teamRoles, timeFormat } from './Helper';
import '../listView.css';
import CommonToolTip from '../CommonToolTip';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, teamRoles } from '../Helper';
import EmptyView from '../EmptyView';

function RegimeTemplateList(props) {
  const { showKey, regimeTemplateList, editRegimeTemplate, deleteRegimeTemplate, searchRegimeTemplate } = props;

  const showData = () => {
    return (
      <>
        <div className='d-flex mt-4 justify-content-end'>
          <CoreInputBox placeholder='All Templates Search' setValue={searchRegimeTemplate} leftIcon={search} />
        </div>
        {regimeTemplateList?.length ? (
          <div className={!showKey ? 'tagsView' : 'notesList'}>
            <div className='table-responsive'>
              <table className='w-100 table'>
                <tbody>
                  {regimeTemplateList?.length > 0
                    ? regimeTemplateList.map((item, index) => {
                        return (
                          <tr>
                            <td className='w-100'>
                              <div className=''>
                                <span class=' capitalize lightGrayBadge'>{item.title}</span>
                              </div>
                              <div>
                                {item.questions_count ? (
                                  <span class='badge capitalize lightGrayBadge my-2'>
                                    {item.questions_count} {item.questions_count > 1 ? 'questions' : 'question'} in
                                    Template
                                  </span>
                                ) : null}
                                {item.default ? (
                                  <span class='badge capitalize lightGrayBadge m-2'>Default Template</span>
                                ) : null}
                              </div>
                            </td>
                            <td>
                              <div className='listNotsright'>
                                <div className='metaDetails mb-4 me-3'>
                                  <div>
                                    Created by · {item.admin.full_name} ({teamRoles(item.admin.role)})
                                    <div>
                                      Created <span className='lowercase'> at </span> · {dateFormat(item.created_at)}
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex tagsViewIcons  mb-4'>
                                  <div
                                    onClick={() => {
                                      editRegimeTemplate(item);
                                    }}
                                  >
                                    <CommonToolTip
                                      placement={'top'}
                                      item={<img src={editTheme} />}
                                      showValue={'Edit'}
                                    />
                                  </div>
                                  <div
                                    onClick={() => {
                                      const windowConfirm = window.confirm('Are you sure you want to delete ?');
                                      if (windowConfirm) {
                                        deleteRegimeTemplate(item.id);
                                      }
                                    }}
                                  >
                                    <CommonToolTip
                                      placement={'top'}
                                      item={<img src={trashRed} />}
                                      showValue={'Delete'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}

                  {/* );
                })} */}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <EmptyView />
        )}
      </>
    );
  };

  return <div>{showData()} </div>;
}

export default RegimeTemplateList;
