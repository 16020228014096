import React, { useState } from 'react';

const CoreToggleText = ({ text, wordLimit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const words = text.split(' ');
  const shouldTrim = words.length > wordLimit;

  const displayText = isExpanded || !shouldTrim ? text : words.slice(0, wordLimit).join(' ') + '...';

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className='card-font'>{displayText}</div>
      {shouldTrim && (
        <button onClick={toggleText} className='toggle-button' style={{ fontSize: '12px' }}>
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

export default CoreToggleText;
