import React, { useEffect } from 'react';
import { SelectPicker } from 'rsuite';
import '../inputBox.css';

function Select2DropDown(props) {
  const {
    placeholder,
    label,
    searchBy,
    selectStyle,
    msgStyle,
    labelStyle,
    showMSG,
    retuired,
    data,
    onSelect,
    valueKey,
    onSearch,
    labelKey,
    defaultValue,
    style,
    defaultData,
    value,
    getObjData,
    isValidation,
    placement,
    container,
    readOnly,
    searchable,
    disabled,
    className,
    onClean,
  } = props;

  let timeout = '';

  useEffect(() => {
    if (defaultData && typeof defaultData == 'object') {
      let verifyData = data.find((item) => item[valueKey] == defaultData[valueKey]);
      if (!verifyData) {
        data.unshift(defaultData);
      }
    }
  }, [defaultData]);

  return (
    <div className={className}>
      {label && (
        <label className='inputlabel' style={labelStyle}>
          {retuired && <span className='requiredInput'> * </span>} {label}
        </label>
      )}
      <div className='text-capitalize'>
        <SelectPicker
          searchable={searchable}
          disabled={disabled}
          container={container}
          data={data}
          onClean={() => {
            if (onClean) {
              onClean();
            } else if (onSelect) {
              onSelect('', {});
            }
          }}
          readOnly={readOnly}
          value={value}
          placement={placement || 'bottomStart'}
          onSelect={onSelect}
          valueKey={valueKey}
          labelKey={labelKey}
          defaultValue={defaultValue}
          searchBy={searchBy}
          onSearch={(str) => {
            if (isValidation) {
              onSearch(str);
            } else if (onSearch && str?.trim()?.length > 2) {
              if (timeout) {
                clearTimeout(timeout);
              }

              timeout = setTimeout(() => {
                onSearch(str);
              }, 300);
            }
          }}
          style={{ ...style, ...selectStyle }}
          placeholder={placeholder}
        />
      </div>
      {showMSG && (
        <div className='inputMsg' style={msgStyle}>
          {showMSG}
        </div>
      )}
    </div>
  );
}
Select2DropDown.defaultProps = {
  data: [],
  valueKey: 'id',
  labelKey: 'title',
  style: { width: '100%' },
  msgStyle: { color: 'red' },
};

export default Select2DropDown;
